import { ImCalendar } from 'react-icons/im'
import { GrStatusInfo } from 'react-icons/gr'
import { Card, Row, Col, Button, Badge, ButtonGroup } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { getProgettoColor, } from "../../Utilities/getBorderColor";
import { BsPersonCircle } from 'react-icons/bs'
import { MdOutlineCategory } from 'react-icons/md'
import { FiChevronLeft } from 'react-icons/fi'
import dayjs from "dayjs";
import { BsFileEarmarkRichtext } from "react-icons/bs"
import ListGroup from 'react-bootstrap/ListGroup';
import { Navigate } from 'react-router-dom';
import iniziativaServiceInstance from "../../../services/IniziativaService";
import progettoServiceInstance from '../../../services/ProgettoService';
var fileDownload = require('js-file-download');


export default function PaginaProgetto(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [progetto, setProgetto] = useState({})
    const [ricaricaProgetto, setRicaricaProgetto] = useState(true)
    const [iniziativa, setIniziativa] = useState()

    const [faseCorrente, setFaseCorrente] = useState({})


    async function download(arrayBuffer, name) {
        //FIXME: controllare il tipo del file, "data:file" deve essere parametrico rispetto al vero formato del file
        const base64Response = await fetch(`data:file;base64,${arrayBuffer}`);
        const blob = await base64Response.blob();
        fileDownload(blob, name);
    }

    //fetch proposta
    useEffect(() => {
        if (location.state && location.state.progId) {
            progettoServiceInstance.getProgetto(location.state.progId)
                .then((res) => {
                    setProgetto(res.data)
                })
                .catch((err) => {
                    setProgetto({})
                })
            setRicaricaProgetto(false)
        }
        if (location.state?.iniId) {
            iniziativaServiceInstance.getIniziativa(location.state.iniId)
                .then((res) => {
                    setIniziativa(res.data)
                    setFaseCorrente(res.data.fasiIniziativa && res.data.fasiIniziativa[res.data.fasiIniziativa.length - 1])
                    res.data.fasiiniziativa?.forEach((fase) => {
                        let inizioFase = dayjs(fase.finiDataInizio)
                        let fineFase = dayjs(fase.finiDataFine)
                        if (dayjs().isBetween(inizioFase, fineFase, null, [])) {
                            setFaseCorrente(fase)
                        }
                    })
                })
                .catch((err) => {
                    setIniziativa({})
                    //TODO gestione dell'errore
                })
        }
    }, [location.state, ricaricaProgetto, props])


    if (props.comune && props.comune.impostazioniComune) {
        if (
            props.comune.impostazioniComune.imcModuloIniziativeAttivo &&
            location.state &&
            location.state.iniId && location.state.progId
        ) {
            return (
                <>
                    <div className="pt-4 px-md-5 pb-2 px-2 bg-secondary">
                        <Row className="w-100 p-0 m-0">
                            <Col xs={7} className="p-0 align-self-center">
                                <span className="smallcaps dark">Progetto</span>
                                <h1 className="title dark">{progetto?.progTitolo}</h1>
                                <h5 className="d-inline me-2"><Badge bg="primary">{props.comune.impostazioniComune.imcLabelModuloIniziative}: {iniziativa?.iniEdizione}</Badge></h5>
                                <h5 className="d-inline me-2"><Badge bg="primary">
                                    {
                                        progetto?.progCategorie && progetto?.progCategorie.categorie.map((el, i) => (
                                            <span key={i}>
                                                <span key={i}>{el}</span>
                                                {i !== progetto?.progCategorie.categorie.length - 1 &&
                                                    <span>, </span>}
                                            </span>

                                        ))
                                    }
                                </Badge></h5>
                            </Col>
                            <Col xs={12} md={5} className="p-0">
                                <Card className="text-center cartaSommario"
                                    border={getProgettoColor(progetto)}
                                >
                                    <Card.Body>
                                        {faseCorrente &&
                                            <>
                                                <span className="smallcaps smallText text-start">Fase {faseCorrente.finiOrdine} di {iniziativa?.fasiIniziativa && iniziativa?.fasiiniziativa?.length}</span>
                                                <h3 className="smallcaps title m-0">{faseCorrente.finiTitolo}</h3>
                                                <Row>
                                                    <span>{dayjs(faseCorrente.finiDataInizio).format('DD/MM/YYYY')} - {dayjs(faseCorrente.finiDataFine).format('DD/MM/YYYY')}</span>
                                                </Row>
                                            </>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "50vh" }}>
                        <Card.Header />
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={8} className="">
                                    <p>
                                        <strong>Chi propone: </strong>
                                        <span>
                                            <BsPersonCircle className="me-2 m-auto" />
                                            {progetto?.partecipante?.datiPartecipante.dparNome} {' '}
                                            {progetto?.partecipante?.datiPartecipante.dparCognome} {' '}
                                        </span>
                                    </p>
                                    {progetto?.progAssociazione &&
                                        <p>
                                            <strong>Ente o Organizzazione rappresentata: </strong>
                                            <span>
                                                {progetto?.progAssociazione}
                                            </span>
                                        </p>
                                    }
                                    {progetto?.progCopresentatori &&
                                        <p>
                                            <strong>Altri enti, soggetti o persone che presentano la proposta: </strong>
                                            <span>
                                                {progetto?.progCopresentatori}
                                            </span>
                                        </p>
                                    }


                                    <Card>
                                        <Card.Header><h3>{progetto?.progTitolo}</h3></Card.Header>
                                        <Card.Body>



                                            {progetto?.progDescrizione1 &&
                                                <p>
                                                    <h6 className='title'>Quali sono i problemi e i bisogni che la proposta vuole affrontare e che cosa vuole migliorare?</h6>
                                                    {progetto?.progDescrizione1.split("\n").map((val, index) => (
                                                        <span key={index}>{val} <br /></span>
                                                    ))}
                                                </p>
                                            }

                                            {progetto?.progDescrizione2 &&
                                                <p>
                                                    <h6 className='title'>Quali sono i luoghi, gli spazi e i servizi sui quali si concentra la proposta?</h6>
                                                    {progetto?.progDescrizione2.split("\n").map((val, index) => (
                                                        <span key={index}>{val} <br /></span>
                                                    ))}
                                                </p>
                                            }
                                            {progetto?.progDescrizione3 &&
                                                <p>
                                                    <h6 className='title'>A chi si rivolge la proposta?</h6>
                                                    {progetto?.progDescrizione3.split("\n").map((val, index) => (
                                                        <span key={index}>{val} <br /></span>
                                                    ))}
                                                </p>
                                            }
                                            {progetto?.progDescrizione4 &&
                                                <p>
                                                    <h6 className='title'>In breve, quali sono i punti di forza della proposta?</h6>
                                                    {progetto?.progDescrizione4.split("\n").map((val, index) => (
                                                        <span key={index}>{val} <br /></span>
                                                    ))}
                                                </p>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={{ span: 9, offset: 1 }} md={{ span: 4, offset: 0 }} className="text-center sticky-col">
                                    {progetto?.posizione &&
                                        <MapContainer className="mx-1 mt-1" style={{ height: "40vh" }}
                                            center={[progetto?.posizione && progetto?.posizione.posLatitudine, progetto?.posizione && progetto?.posizione.posLongitudine]} zoom={15} scrollWheelZoom={false}>
                                            <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Marker

                                                position={[progetto?.posizione && progetto?.posizione.posLatitudine, progetto?.posizione && progetto?.posizione.posLongitudine]}
                                            >
                                                <Popup minWidth={90}>
                                                    <span>
                                                        {progetto?.posizione && progetto?.posizione.posNome}
                                                    </span>
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    }
                                    <ListGroup className="mt-2">
                                        <ListGroup.Item >
                                            <strong> <ImCalendar /> Inviata il: </strong>
                                            {dayjs(progetto?.progDataInvio).format("DD/MM/YYYY")}
                                        </ListGroup.Item>
                                        <ListGroup.Item >
                                            <strong> <GrStatusInfo /> Indirizzo: </strong>
                                            {progetto?.posizione && progetto?.posizione.posNome}
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            <strong> <MdOutlineCategory /> Categorie: </strong>
                                            {progetto?.progCategorie && progetto?.progCategorie.categorie.map((el, i) => (
                                                <span key={i}>
                                                    <span>{el}</span>
                                                    {i !== progetto?.progCategorie.categorie.length - 1 &&
                                                        <span>, </span>
                                                    }
                                                </span>
                                            ))
                                            }
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <div className=" mt-3 divider" />
                            <h2 className="title2 text-start">Allegati</h2>
                            <div>
                                {progetto.allegatiProgetto && progetto.allegatiProgetto.map((file, i) => (
                                    <ButtonGroup key={i} className="me-3 mb-3 ms-3" onClick={() => { download(file.allProgFile, file.allProgNome) }}>
                                        <Button variant="outline-primary" >
                                            <BsFileEarmarkRichtext size="1.5em" />
                                            {file.allProgNome}</Button>
                                    </ButtonGroup>
                                ))}
                            </div>

                            {progetto?.progRisposta &&
                                <>
                                    <div className="divider" />
                                    <h2 className="title2 text-start mb-3">Risposta dal comune</h2>
                                    <p className="text-start">Pubblicata in data {dayjs(progetto?.progDataRisposta).format('DD/MM/YYYY')}</p>
                                    <Card>
                                        <Card.Body className="text-justify">
                                            {progetto?.progRisposta && progetto?.progRisposta.split("\n").map((val, index) => (
                                                <span key={index}>{val} <br /></span>
                                            ))}
                                        </Card.Body>
                                    </Card>
                                </>
                            }
                        </Card.Body>
                        <Card.Footer />
                    </Card>
                    <Button variant="outline-dark" className="ms-5 mb-3"
                        onClick={() => {
                            navigate(-1)
                        }}>
                        <FiChevronLeft />
                        Torna a tutti i progetti</Button>


                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
} 