import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import { FiChevronRight as Scopri } from 'react-icons/fi'
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getSegnalazioneColor } from "../../Utilities/getBorderColor";
import ModalPermessi from "../../Utilities/ModalPermessi";

export default function CartaSegnalazione(props) {

    const navigate = useNavigate()

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    
    //stati
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    const IsLogged = () => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            navigate('/segnalazione', { state: { segId: props.segnalazione.segId } })
        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare la segnalazione. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    const formattaIndirizzo = (indirizzo) => {
        let indirizzo_arr = indirizzo.split(',', 2)
        return indirizzo_arr[1] + ", " + indirizzo_arr[0]
    }

    return (
        <Card className="cartaSommario w-100 mt-4 mb-2"
            border={getSegnalazioneColor(props.segnalazione)}>

            <Card.Body>
                <h5><strong>{props.segnalazione.segOggetto}</strong></h5>
                <p>{props.segnalazione.segDescrizione.length > 200 ? `${props.segnalazione.segDescrizione.slice(0, 200)}...` : props.segnalazione.segDescrizione}</p>
                <Row className="w-100 m-0 mt-3 p-0">
                    <Col xs={6} lg={2}>
                        <strong>Inviata il:</strong>
                        <p>{dayjs(props.segnalazione.segDataInvio).format("DD/MM/YYYY")}</p>
                    </Col>

                    <Col xs={6} lg={2} className="text-end text-lg-start">
                        <strong>Categoria:</strong>
                        <p>{props.segnalazione.segCategoria}</p>
                    </Col>

                    <Col xs={7} lg={4}>
                        <strong>Indirizzo:</strong>
                        <p>{formattaIndirizzo(props.segnalazione.posizione.posNome)}</p>
                    </Col>

                    <Col xs={5} md={3}  className="d-flex align-self-center text-end">
                        <Badge pill
                            bg={getSegnalazioneColor(props.segnalazione)}
                            className="mt-auto ms-auto py-2 text-end"
                            style={{ fontWeight: '100', fontSize: '0.80em' }}>
                           {props.segnalazione.segStato}
                        </Badge>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className='text-center pb-3 pe-3'>
                <Row className='w-100 p-0 m-0'>
                    <Col className="text-md-end">
                        <Button variant="outline-primary" size="sm" className="mt-auto mx-auto"
                            onClick={IsLogged}>
                            Vai alle info
                            < Scopri />
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </Card>
    )
}