import { Button, Card, Col, Row, Collapse, Form } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import { BsPlusLg } from "react-icons/bs";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiFilter3Line } from "react-icons/ri";
import Multiselect from 'multiselect-react-dropdown';
import dayjs from "dayjs";
import ModalPermessi from "../../Utilities/ModalPermessi";
import { FaRegCalendarAlt } from "react-icons/fa";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { statiProgetto } from "../../Utilities/statiElementEnum";
import { getStatusOrderProgetto } from "../../Utilities/getStatusOrder";
import CartaProgetto from "./CartaProgetto";
import PaginationBasic from "../Homepage/Pagination"


export default function EsploraProgetti(props) {

    /* mappa */
    const zoom = 12;
    const ResizeMap = () => {
        const map = useMap();
        map._onResize();
        return null;
    };
    //gestione mappa

    const navigate = useNavigate();

    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [titolo, setTitolo] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [mode, setMode] = useState("");

    let [listaProgetti, setListaProgetti] = useState([]);


    /* STATI PER LA PAGINAZIONE */
    const [paginaNumero, setPaginaNumero] = useState(0)
    const elementiPerPagina = 50
    const paginaVisitata = paginaNumero * elementiPerPagina
    /* ************************ */

    // filtri
    const [dateRange, setDateRange] = useState([null, null]);
    let [categoriaFilter, setCategoriaFilter] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [areaSelezionata, setAreaSelezionata] = useState(-1)

    const areeUrbanePresenti = (props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcAreeUrbane && props.comune.impostazioniComune.imcAreeUrbane.aree_urbane) || ["Caricamento..."]

    useEffect(() => {
        let opt = props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategorieProgetti.categorie_progetti;
        opt = [...opt, ...listaProgetti.flatMap((p) => p.progCategorie.categorie)];
        let categoriePresenti = [...new Set(opt)].sort().map((el, index) => ({ name: el, id: index }));
        setOptions(categoriePresenti);

        if (props.iniziativa.progetti && props.iniziativa.progetti.length > 0) {
            setListaProgetti(props.iniziativa.progetti);
        }
        if (props.iniziativa.fasiIniziativa) {
            let faseCorrente = props.iniziativa.fasiIniziativa.filter((fase) =>
                dayjs().isBetween(fase.finiDataInizio, fase.finiDataFine, null, [])
            )[0];
            setDisableButton(faseCorrente? !faseCorrente.finiFlInvioProgetti : true );
        }

    }, [props]);

    const pulisciFiltri = () => {
        setDateRange([null, null]);
        setSelectedOptions([]);
        setCategoriaFilter([]);
        setAreaSelezionata(-1)
    };

    const handleDateRangeChange = (update) => {
        if (update === null) {
            setDateRange([null, null]);
        } else {
            setDateRange(update);
        }
    };

    const handleAreaSelezionata = (value) => {
        setAreaSelezionata(value)
    }

    const getProgetti = () => {
        let ret = listaProgetti;

        ret= ret.filter(progetto => progetto.progStato !== statiProgetto.InValidazione && progetto.progStato !== statiProgetto.Rifiutato)


        if (categoriaFilter.length > 0) {
            ret = ret.filter((prog) => prog.progCategorie.categorie.some((cat) => categoriaFilter.includes(cat)));
        }

        if (dateRange[0]) {
            let startDay = dayjs(dateRange[0]);
            ret = ret.filter((prog) =>
                dayjs(prog.progDataPubblicazione).isAfter(startDay) || dayjs(prog.progDataPubblicazione).isSame(startDay)
            );
        }

        if (dateRange[1]) {
            let endDay = dayjs(dateRange[1]);
            ret = ret.filter((prog) =>
                dayjs(prog.progDataPubblicazione).isBefore(endDay) || dayjs(prog.progDataPubblicazione).isSame(endDay)
            );
        }

        return ret;
    };
    const listaPosizioni = getProgetti().map(prog => prog.posizione)


    const handleSelect = (_, selectedItem) => {
        setCategoriaFilter((old) => [...old, selectedItem.name]);
        setSelectedOptions((old) => [...old, selectedItem]);
    };

    const handleRemove = (_, removedItem) => {
        setSelectedOptions((old) => old.filter((el) => el.id !== removedItem.id));
        setCategoriaFilter(categoriaFilter.filter((el) => el !== removedItem.name));
    };

    const IsLogged = () => {
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlVisualizzatore && props.partecipante.ruolo.ruoFlPubblicatoreProgetti) {
                navigate('/crea-progetto', { state: { iniziativa: props.iniziativa?.iniId } });
            } else {
                setTitolo("Attenzione: operazione illecita");
                setMessaggio("Attualmente non possiedi i permessi per creare una proposta. L'operazione è bloccata!");
                setMode("danger");
            }
        } else {
            setTitolo("Attenzione");
            setMessaggio("Per procedere con la creazione di una nuova proposta devi prima effettuare il login!");
            setMode("warning");
        }
        setMostraModalPermessi(true);
    };

    return (
        <>
            <Card style={{ minHeight: "100vh", borderTop: "none", borderRadius: "0px" }}>
                <Card.Body className="p-0 m-0 pb-4">
                    <Row className="d-md-none w-100 p-0 m-0">
                        <Col xs={12} sm={6} className="p-1">
                            <Button className="w-100 mb-2" disabled={disableButton} onClick={IsLogged}>
                                Crea il tuo progetto
                                <BsPlusLg className="ms-2" />
                            </Button>
                        </Col>
                        <Col xs={12} sm={6} className="p-1">
                            <Button className="w-100" onClick={() => setOpen(!open)}>
                                <RiFilter3Line className="me-2" />
                                Mostra filtri
                            </Button>
                        </Col>
                        <Collapse in={open} className="border-bottom pb-3">
                            <div>
                                <FilterForm
                                    options={options}
                                    selectedOptions={selectedOptions}
                                    handleSelect={handleSelect}
                                    handleRemove={handleRemove}
                                    dateRange={dateRange}
                                    handleDateRangeChange={handleDateRangeChange}
                                    pulisciFiltri={pulisciFiltri}
                                    areaSelezionata={areaSelezionata}
                                    handleAreaSelezionata={handleAreaSelezionata}
                                    areeUrbanePresenti={areeUrbanePresenti}
                                />
                            </div>
                        </Collapse>
                    </Row>
                    <Row className="w-100 m-0 p-0" style={{ minHeight: "100vh" }}>
                        <Col md={3} className="bg-light d-none d-md-block">
                            <div className="d-flex justify-content-center">
                                <Button className="my-3" disabled={disableButton} onClick={IsLogged}>
                                    Crea il tuo progetto
                                    <BsPlusLg className="ms-2" />
                                </Button>
                            </div>
                            <FilterForm
                                options={options}
                                selectedOptions={selectedOptions}
                                handleSelect={handleSelect}
                                handleRemove={handleRemove}
                                dateRange={dateRange}
                                handleDateRangeChange={handleDateRangeChange}
                                pulisciFiltri={pulisciFiltri}
                                areaSelezionata={areaSelezionata}
                                handleAreaSelezionata={handleAreaSelezionata}
                                areeUrbanePresenti={areeUrbanePresenti}
                            />
                        </Col>
                        {/* TUTTI I PROGETTI */}
                        <Col md={9} xs>

                            {/* MAPPA */}
                            <MapContainer className="mx-1 mt-1" style={{ height: "30vh" }}
                                center={props.comune.impostazioniComune && props.comune.impostazioniComune.imcCentroMappa.centro_mappa}
                                zoom={zoom} scrollWheelZoom={false}>
                                <ResizeMap />
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {listaPosizioni.length > 0 &&
                                    listaPosizioni.map((posizione, index) => (
                                        <Marker
                                            position={[posizione.posLatitudine, posizione.posLongitudine]}
                                            key={index}
                                        >
                                            <Popup minWidth={90}>
                                                <span>
                                                    {posizione.posNome}
                                                </span>
                                            </Popup>
                                        </Marker>
                                    ))}
                            </MapContainer>

                            { getProgetti().length > 0 &&(
                                <>
                                {
                                getProgetti()
                                    .sort(function (a, b) {
                                        return getStatusOrderProgetto(a.progStato) - getStatusOrderProgetto(b.progStato)
                                    })
                                    .slice(paginaVisitata, paginaVisitata + elementiPerPagina)
                                    .map((progetto, index) => (
                                        <CartaProgetto key={index}
                                            progetto={progetto}
                                            iniziativa={props.iniziativa}
                                            partecipante={props.partecipante} />
                                    ))
                                }
                                < PaginationBasic
                                activepage={paginaNumero}
                            setActivePage={setPaginaNumero}
                            numItems={listaProgetti.length}
                            itemsPerPage={elementiPerPagina}
                            />
                            </>)
                                }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </>
    );
}

const FilterForm = ({ options, selectedOptions, handleSelect, handleRemove, dateRange, handleDateRangeChange, pulisciFiltri,
    areaSelezionata, handleAreaSelezionata, areeUrbanePresenti
}) => (
    <>
        <h4 className="mt-4">Filtra per:</h4>
        <div className="divider my-4" />
        <Row>
            <Col>
                <p>Categorie</p>
                <Multiselect
                    options={options}
                    selectedValues={selectedOptions}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    displayValue="name"
                />
            </Col>
        </Row>
        <div className="divider my-4" />
        <Row>
            <Col>
                <p>Area Urbana</p>
                <Form.Select
                    size="sm"
                    className="mb-4"
                    value={areaSelezionata}
                    onChange={(ev) => { handleAreaSelezionata(ev.target.value) }}
                >
                    <option value="-1" disabled >Seleziona l'area urbana</option>
                    {areeUrbanePresenti.map((el, index) => (
                        <option value={el} key={index}>{el}</option>
                    ))}
                </Form.Select>
            </Col>
        </Row>
        <div className="divider my-4" />
        <Row>
            <Col>
                <p>Data pubblicazione &nbsp;
                    <span className="small text-muted">(Da ... a)</span>
                </p>
                <DateRangePicker
                    className="w-100"
                    id="datePickerPetizioni"
                    onChange={handleDateRangeChange}
                    value={dateRange}
                    returnValue="range"
                    calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                    dayPlaceholder="gg"
                    monthPlaceholder="mm"
                    yearPlaceholder="aaaa"
                    format="dd/MM/y"
                />
            </Col>
        </Row>
        <div className="d-flex justify-content-center mt-4">
            <Button size="sm" variant="outline-primary" onClick={pulisciFiltri}>
                Pulisci filtri di ricerca
            </Button>
        </div>
    </>
);
