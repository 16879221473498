import { Modal, Button } from "react-bootstrap";
import { CgCheckO, CgCloseO } from 'react-icons/cg'
export default function ModalConferma(props) {

    const handleClose = () => {
        props.setMostra(false)
    };

    const handleConferma = () => {
        props.azioneConferma()
    }

    return (
        <Modal
            show={props.mostra}
            onHide={handleClose}
            centered
            backdrop={props.mode === "success" ? "static" : "true"}
        >
            <Modal.Header>
                <Modal.Title className="w-100 text-center">
                    {props.mode === 'danger' || props.mode === 'error' ?
                        <CgCloseO className="me-2 text-danger" />
                        :
                        <CgCheckO className="me-2 text-success" />
                    }
                    {props.titolo}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.bodyAlign}>
                {props.messaggio}
            </Modal.Body>
            <Modal.Footer className={props.mode !== "success" && props.mode !== "error" ? "d-flex justify-content-between" : "d-flex justify-content-center"}>
                {
                    props.mode !== "success" &&
                    props.mode !== "error" &&
                    <Button variant="outline-secondary"
                        onClick={() => {
                            handleClose()
                            props.azioneAnnulla &&
                                props.azioneAnnulla()
                        }}>
                        {props.btnAnnulla}
                    </Button>
                }
                {
                    props.btnConferma &&
                    <Button variant="primary" onClick={handleConferma}>{props.btnConferma}</Button>
                }
            </Modal.Footer>
        </Modal >
    )
}