import { useState, useEffect } from "react"
import { Row, Col, Table, Dropdown, NavDropdown, FormControl, InputGroup, Form, Badge, CloseButton, Button } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import NumberFormat from "react-number-format"
import AzioniTabella from "./AzioniTabella";
import { getReferendumColor } from '../../Utilities/getBorderColor'
import { getStatusOrderReferendum } from '../../Utilities/getStatusOrder'
import { getStatusReferendum } from "../../Utilities/getStatus"
import { statiReferendum, tipiReferendum } from "../../Utilities/statiElementEnum";
import { convertReferendumToCSV } from "../../Utilities/covertToCSV";
import { PiFileCsv } from "react-icons/pi";

const dayjs = require('dayjs');

export default function TabellaReferenda(props) {

    let [referenda, setReferenda] = useState([])
    const [titolo, setTitolo] = useState("")

    const getReferenda = () => {
        let ret = referenda

        if (props.statoFilter.length > 0)
            ret = ret.filter((ref) => ref.refStato === props.statoFilter)

        if (props.tipoFilter.length > 0)
            ret = ret.filter((ref) => ref.refTipo === props.tipoFilter)

        if (titolo)
            ret = ret.filter((ref) => ref.refTitolo.toLowerCase().includes(titolo.toLowerCase()))

        return ret
    }

    let filterByStato = (stato) => {
        props.setStatoFilter(stato)
    }

    let resetStato = () => {
        props.setStatoFilter("")
    }

    let filterByTipo = (tipo) => {
        props.setTipoFilter(tipo)
    }

    let resetTipo = () => {
        props.setTipoFilter("")
    }

 // Funzione per scaricare il CSV
 const handleDownloadReports = () => {
    const csvContent = convertReferendumToCSV(props.referenda, props.comune); // Converti i dati in CSV
    const url = window.URL.createObjectURL(new Blob([csvContent])); // Crea l'URL
    const link = document.createElement('a');
    const fileName = `Referendum_${dayjs(new Date()).format("DDMMYYHHmm")}.csv`;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

    useEffect(() => {
        let referenda = []
        props.referenda.forEach(ref => {
            let referendum = {
                refId: ref.refId,
                refTitolo: ref.refTitolo,
                refPartecipante: ref.partecipante.datiPartecipante.dparNome + " " + ref.partecipante.datiPartecipante.dparCognome,
                refStato: getStatusReferendum(ref, props.comune),
                refFirme: ref.refFirmeOffline + ref.firmeReferendum.length,
                refTipo: ref.refTipo,
                refDataPubblicazione: ref.refDataPubblicazione ? dayjs(ref.refDataPubblicazione).format('DD/MM/YYYY') : "---",
                refFineRaccoltaFirme: ref.refFineRaccoltaFirme
            }
            referenda.push(referendum)
        })

        setReferenda(referenda)
        // eslint-disable-next-line
    }, [props.referenda])

    return (
        <>
            <Row className="d-flex justify-content-between my-4">
                <Col>
                    {
                        <Dropdown className="d-inline" autoClose="inside">
                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                <span className="px-5">Filtra</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStato}>
                                {Object.values(statiReferendum)
                                    .map((stato, index) => (
                                        <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                    )
                                    )}
                            </NavDropdown>
                                <NavDropdown title="Tipo" className="noChangeOnClick" drop="end" onSelect={filterByTipo}>
                                {Object.values(tipiReferendum)
                                    .map((stato, index) => (
                                        <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                    )
                                    )}
                                </NavDropdown>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {
                        props.statoFilter.length > 0 &&
                        <Form.Label className="mx-4 mt-2 mt-md-0 d-block">
                            Stato: <strong> {props.statoFilter}</strong><Badge bg="outline-danger"><CloseButton onClick={resetStato} /></Badge>
                        </Form.Label>
                    }
                    {
                        props.tipoFilter.length > 0 &&
                        <Form.Label className="mx-4  mt-2 mt-md-0 d-block">
                            Tipo: <strong> {props.tipoFilter}</strong><Badge bg="outline-danger"><CloseButton onClick={resetTipo} /></Badge>
                        </Form.Label>
                    }
                </Col>

                <Col xs={12} md={5} className="d-flex justify-content-end">
                    <InputGroup className="mt-2 mt-md-0" style={{maxHeight:"1rem"}}>
                        <InputGroup.Text id="basic-addon1">
                            <GoSearch />
                        </InputGroup.Text>
                        <FormControl placeholder="Cerca titolo..."
                            onChange={(ev) => { setTitolo(ev.target.value) }} />
                    </InputGroup>
                </Col>

                <Col className="text-end" xs={{span:12, order:4}} md={{span:3, order:4}}>  
                        <Button onClick={handleDownloadReports} variant="primary" >
                            <PiFileCsv className="me-1" />
                            Esporta tabella in formato csv
                        </Button>
                    </Col>
            </Row>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Titolo</th>
                        <th>Autore</th>
                        <th className="text-center">Tipo</th>
                        <th className="text-center">Stato</th>
                        <th className="text-center">Firme</th>
                        <th className="text-center nowrap">Pubblicata il</th>
                        <th className="text-center">Azioni</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        getReferenda().length > 0 ? (
                            getReferenda()
                                .sort((a, b) => { return getStatusOrderReferendum(a.refStato) - getStatusOrderReferendum(b.refStato) })
                                .map(referendum => (
                                    <tr key={referendum.refId}>
                                        <td>{referendum.refTitolo}</td>
                                        <td>{referendum.refPartecipante}</td>
                                        <td className="text-center">{referendum.refTipo}</td>
                                        <td className="text-center">
                                            <Badge pill bg={getReferendumColor(referendum)} className="pillText" style={{ minWidth: "90%" }}>
                                                {referendum.refStato}
                                            </Badge>
                                        </td>
                                        <td className="text-center">
                                            <NumberFormat
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                value={referendum.refFirme}
                                            />
                                        </td>
                                        <td className="text-center">{referendum.refDataPubblicazione}</td>
                                        <td>
                                            <AzioniTabella
                                                refId={referendum.refId}
                                                setRefId={props.setRefId}
                                                stato={referendum.refStato}
                                                setMostraTabella={props.setMostraTabella}
                                                setGestisci={props.setGestisci}
                                            />
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan="7">
                                    Nessun referendum presente
                                </td>
                            </tr>
                        )
                    }
                </tbody>

            </Table>
        </>

    )

}