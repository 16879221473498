import axios from 'axios'

const FIRME_REFERENDUM_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'voti-proposta'

const headers = { 'Content-Type': 'application/json' }

class VotiPropostaService {
    registraVoto(voto) {
        return axios.post(FIRME_REFERENDUM_REST_API_URL + "/save", voto, headers);
    }

    revocaVoto(voto) {
        return axios.post(FIRME_REFERENDUM_REST_API_URL + "/delete", voto, headers)
    }
}

const votiPropostaServiceInstance = new VotiPropostaService()
export default votiPropostaServiceInstance