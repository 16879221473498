import Pagination from 'react-bootstrap/Pagination';
import { Col } from "react-bootstrap";

export default function PaginationBasic(props) {
    const { activepage, setActivePage, numItems, itemsPerPage } = props;

    // Calcola il numero totale di pagine
    const totalPages = Math.ceil(numItems / itemsPerPage);

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item 
                key={number} 
                active={number === activepage + 1} 
                onClick={() => setActivePage(number - 1)}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <Col sm={12}>
            <div className='d-flex justify-content-center my-4 mx-0'>
                <Pagination>
                    <Pagination.Prev 
                        onClick={() => setActivePage(Math.max(activepage - 1, 0))} 
                        disabled={activepage === 0}
                    />
                    {items}
                    <Pagination.Next 
                        onClick={() => setActivePage(Math.min(activepage + 1, totalPages - 1))} 
                        disabled={activepage === totalPages - 1}
                    />
                </Pagination>
            </div>
        </Col>
    );
}
