import { Card } from "react-bootstrap";

export default function ModuloNonAttivo(props) {

    return (
        <>
            <Card>
                <Card.Body>
                    Il modulo <strong>{props.moduleName}</strong> non è attivo.
                </Card.Body>
            </Card>
        </>
    )

}