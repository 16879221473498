import React from "react"
import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap"
import Multiselect from 'multiselect-react-dropdown';
import { validationIconValid, validationIconNotvalid } from '../../../../utilities/icone'
import AllegatiProposta from "./AllegatiProposta";
import AzioniProposta from "./AzioniProposta";
import ModalConferma from "../../../Utilities/ModalConferma";
import ProposteService from "../../../../services/PropostaService"
import AllegatoService from "../../../../services/AllegatoService"
import { statiProposta } from "../../../Utilities/statiElementEnum";

export default function ProposteGestisci(props) {
    const dayjs = require('dayjs');

    // Riferimento al form (posizione 0)
    const ref = useRef([]);

    // Stati per gestire i campi modificabili della proposta
    const [area, setArea] = useState(props.proposta.proArea)
    const [categorie, setCategorie] = useState(props.proposta.proCategorie.categorie)
    const [titolo, setTitolo] = useState(props.proposta.proTitolo)
    const [descrizione, setDescrizione] = useState(props.proposta.proDescrizione)
    const [ammessa, setAmmessa] = useState(props.proposta.proFlAmmessa)
    const [risposta, setRisposta] = useState(props.proposta.proRisposta !== undefined ? props.proposta.proRisposta : "")

    // ALLEGATI
    // eslint-disable-next-line
    const [allegati, setAllegati] = useState(props.proposta.allegati)
    const [allegatiToDelete, setAllegatiToDelete] = useState([])

    // AREE 
    const [areeMS, setAreeMS] = useState([])
    let selectArea = (area) => {
        setArea(area[0].id)

        if (
            area !== "" &&
            !!formErrors["area"]
        ) {
            setFormErrors({
                ...formErrors,
                area: null
            })
        }
    }

    // CATEGORIE
    const [categorieMS, setCategorieMS] = useState([])
    const [selectedCategorieMS, setSelectedCategorieMS] = useState([])
    const selectCategoria = (_, selectedItem) => {
        setSelectedCategorieMS(old => [...old, selectedItem])
        setCategorie([...categorie, selectedItem['name']])

        if (
            _.length > 0 &&
            !!formErrors["categorie"]
        ) {
            setFormErrors({
                ...formErrors,
                categorie: null
            })
        }
    }
    const removeCategoria = (_, removedItem) => {
        setSelectedCategorieMS(old => old.filter(el => el.id !== removedItem.id))
        setCategorie(categorie.filter(el => el !== removedItem.name))

        if (
            validated &&
            _.length === 0
        ) {
            setFormErrors({
                ...formErrors,
                categorie: errors.categorie
            })
        }
    }

    // VALIDAZIONE FORM
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({})
    const errors = {
        area: "Seleziona l'area",
        categorie: "Seleziona almeno una categoria",
        titolo: "Inserisci il titolo",
        descrizione: "Inserisci la descrizione",
        risposta: "Non puoi pubblicare una risposta vuota",
        firme: "Valore firme offline inserito non valido!"
    }
    const mandatoryField = () => {
        if (["controlla", "rispondi"].includes(props.gestisciProposta))
            return <span>*</span>

        return ""
    }
    const getValidationStyle = (field) => {
        return (validated ? (formErrors[field] ? "is-invalid" : "is-valid") : "")
    }

    // FIRME
    let [firme, setFirme] = useState(0)

    // Valori che abilitano o disabilita l'edit dei campi a seconda dell'azione di modifica
    let [readOnly1, setReadOnly1] = useState(false)
    let [readOnly2, setReadOnly2] = useState(false)

    // FINESTRE MODALI
    const [mostraModaleOperazione, setMostraModaleOperazione] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)
    const [parolaChiave, setParolaChiave] = useState("")
    const [modaleTitolo, setModaleTitolo] = useState("")
    const [modaleMessaggio, setModaleMessaggio] = useState("")

    // SALVATAGGIO PROPOSTA
    const handleSubmit = (saveType, e) => {
        e.preventDefault()

        const form = ref.current[0]

        setValidated(true);

        // Ottieni i nuovi errori
        let formErrors = checkProposta()

        if (
            form.checkValidity() !== true ||
            Object.keys(formErrors).length > 0
        ) {
            // Presenza di errori: inserimento nell'oggetto Errors e submit bloccato
            setFormErrors(formErrors)
        } else {
            setMostraModaleOperazione(true)
            if (saveType === "pubblica") {
                setParolaChiave("Pubblica")
                setModaleTitolo("Pubblica proposta")
                setModaleMessaggio("Confermi la pubblicazione della proposta? L'operazione è irreversibile!")
            }
            if (saveType === "rispondi") {
                setParolaChiave("Rispondi")
                setModaleTitolo("Rispondi alla proposta")
                setModaleMessaggio("Confermi la risposta alla proposta? L'operazione è irreversibile!")
            }
            if (saveType === "firme") {
                setParolaChiave("Firme")
                setModaleTitolo("Aggiungi firme offline")
                setModaleMessaggio("Sei sicuro di voler aggiungere " + firme + " offline?")
            }
        }
    }

    const checkProposta = () => {
        let formErrors = {}

        if (area === "")
            formErrors.area = errors.area

        if (categorie.length === 0)
            formErrors.categorie = errors.categorie

        if (props.gestisciProposta === "rispondi" && props.proposta.proStato !== statiProposta.Rifiutata)
            if (risposta === "")
                formErrors.risposta = errors.risposta

        if (
            props.gestisciProposta === "firme" &&
            firme < 1
        )
            formErrors.firme = errors.firme

        return formErrors
    }

    const getPropostaByDb = () => {
        return {
            proId: props.proposta.proId,
            bilancioPartecipativo: props.bilanci.filter(bil => bil.bilId === props.bilId)[0],
            proTitolo: props.proposta.proTitolo,
            partecipante: props.proposta.partecipante,
            proCategorie: props.proposta.proCategorie,
            proArea: props.proposta.proArea,
            proDataPubblicazione: props.proposta.proDataPubblicazione,
            proDataInvio: props.proposta.proDataInvio,
            proDescrizione: props.proposta.proDescrizione,
            proRisposta: props.proposta.proRisposta,
            proFlAmmessa: props.proposta.proFlAmmessa,
            proStato: props.proposta.proStato,
            proDataRisposta: props.proposta.proDataRisposta,
            proAssociazione: props.proposta.proAssociazione,
            proVotiOffline: props.proposta.proVotiOffline,
            proEmail: props.proposta.proEmail
        }
    }

    const salvaProposta = (saveType) => {
        let proposta

        saveType = saveType.toLowerCase()

        if (props.gestisciProposta === "controlla") {
            proposta = {
                ...getPropostaByDb(),
                proArea: area,
                proCategorie: { categorie: categorie },
                proTitolo: titolo,
                proDescrizione: descrizione,
                proDataPubblicazione: dayjs(),
                proStato: statiProposta.Pubblicata
            }
        }

        if (props.gestisciProposta === "rispondi") {
            proposta = {
                ...getPropostaByDb(),
                proArea: area,
                proCategorie: { categorie: categorie },
                proTitolo: titolo,
                proDescrizione: descrizione,
                proFlAmmessa: ammessa,
                proRisposta: risposta,
                proDataRisposta: dayjs(),
            }
            if (ammessa)
                proposta.proStato = statiProposta.Vincitrice
            if (props.proposta.proStato === statiProposta.Rifiutata)
                proposta.proStato = statiProposta.Pubblicata
        }

        if (props.gestisciProposta === "firme") {
            proposta = {
                ...getPropostaByDb(),
                proVotiOffline: props.proposta.proVotiOffline + parseInt(firme)
            }
        }

        ProposteService.saveProposta(proposta)
            .then(() => {
                AllegatoService.eliminaAllegati(allegatiToDelete)
            })
            .then(() => {
                props.setDirty(true)
                if (saveType === "pubblica") {
                    setModaleTitolo("Pubblica proposta")
                    setModaleMessaggio("Proposta pubblicata con successo")
                }
                else if (saveType === "rispondi") {
                    setModaleTitolo("Rispondi alla proposta")
                    setModaleMessaggio("Risposta pubblicata con successo")
                }
                else if (saveType === "firme") {
                    setModaleTitolo("Registra firme")
                    setModaleMessaggio("Registrazione firme avvenuta con successo")
                }
                setMostraModaleOperazione(false)
                setMostraModaleSuccesso(true)
            })
            .catch(() => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                if (saveType === "pubblica") {
                    setModaleTitolo("Errore nella pubblicazione della proposta")
                    setModaleMessaggio("Si è verificato un errore durante la pubblicazione della proposta.")
                }
                if (saveType === "rispondi") {
                    setModaleTitolo("Errore nella risposta alla proposta")
                    setModaleMessaggio("Si è verificato un errore durante la pubblicazione della risposta alla proposta.")
                }
                if (saveType === "firme") {
                    setModaleTitolo("Errore nella registrazione delle firme")
                    setModaleMessaggio("Si è verificato un errore durante la registrazione delle firme.")
                }
            })
    }

    // RIFIUTA PROPOSTA
    const preparaRifiuta = () => {
        setMostraModaleOperazione(true)
        setParolaChiave("Rifiuta")
        setModaleTitolo("Rifiuta proposta")
        setModaleMessaggio("Confermi il rifiuto della proposta? L'operazione è irreversibile!")
    }

    const rifiutaProposta = () => {
        let proposta = {
            ...getPropostaByDb(),
            proStato: statiProposta.Rifiutata
        }

        ProposteService.saveProposta(proposta)
            .then(() => {
                props.setDirty(true)
                setMostraModaleOperazione(false)
                setMostraModaleSuccesso(true)
                setModaleTitolo("Rifiiuta proposta")
                setModaleMessaggio("Proposta rifiutata con successo")
            })
            .catch(() => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                setModaleTitolo("Errore nel rifiuto della proposta")
                setModaleMessaggio("Si è verificato un errore durante il rifiuto della proposta.")
            })
    }

    const initAree = () => {
        if (
            props.comune &&
            props.comune.impostazioniComune &&
            props.comune.impostazioniComune.imcAreeUrbane &&
            props.comune.impostazioniComune.imcAreeUrbane.aree_urbane
        ) {
            setAreeMS(props.comune.impostazioniComune.imcAreeUrbane.aree_urbane.map((el, i) => {
                return { "name": el, "id": el }
            }))
        }
    }

    const initCategorie = () => {
        let categorie = []

        if (
            props.comune &&
            props.comune.impostazioniComune &&
            props.comune.impostazioniComune.imcCategorieProposte &&
            props.comune.impostazioniComune.imcCategorieProposte.categorie_proposte
        ) {
            categorie = props.comune.impostazioniComune.imcCategorieProposte.categorie_proposte.map((el, i) => {
                return { "name": el, "id": i }
            })
        }
        setCategorieMS(categorie)
        setSelectedCategorieMS(props.proposta.proCategorie.categorie.map((el, i) => { return { id: i, name: el } }))
    }

    const impostaSelectedValues = () => {
        let selectedValues = areeMS.filter(el => el.name === area)
        return (selectedValues) ? selectedValues : [{ name: "---", id: "---" }]
    }

    useEffect(() => {
        let bilancio = props.bilanci.filter(el => el.bilId === props.bilId)[0]

        initAree()
        initCategorie()
        setReadOnly1(props.gestisciProposta === "firme")
        setReadOnly2(dayjs(bilancio.bilDataChiusura).isBefore(dayjs()))
        // eslint-disable-next-line
    }, [props.bilId, props.bilanci, dayjs, props.gestisciProposta])

    return (
        <>
            <Container>

                <h2 className="title2">
                    {props.gestisciProposta === "controlla" &&
                        <span>Controlla la proposta</span>}
                    {props.gestisciProposta === "rispondi" &&
                        <span>Rispondi alla proposta</span>}
                    {props.gestisciProposta === "firme" &&
                        <span>Registra firme raccolte offline</span>}
                </h2>

                {["controlla", "rispondi", "firme"].includes(props.gestisciProposta) &&
                    <Row>
                        <Col className="mb-4 italic required-alert">I campi contrassegnati da un asterisco (*) sono obbligatori</Col>
                    </Row>
                }

                <Row>
                    <Col md={props.proposta.proAssociazione !== undefined ? 5 : 8}>
                        <p>
                            <strong>Autore: </strong>
                            {props.proposta.partecipante.datiPartecipante.dparNome} {props.proposta.partecipante.datiPartecipante.dparCognome}
                        </p>
                    </Col>
                    {props.proposta.proAssociazione !== undefined &&
                        <Col md={5}>
                            <p>
                                <strong>Associazione: </strong>
                                {props.proposta.proAssociazione}
                            </p>
                        </Col>
                    }
                    <Col className="text-md-right" md={props.proposta.proAssociazione !== undefined ? 2 : 4}>
                        <p><strong>Inviata il: </strong> {dayjs(props.proposta.proDataInvio).format('DD/MM/YYYY')}</p>
                    </Col>
                </Row>

                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={el => ref.current[0] = el}>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <strong>Area: </strong>
                            {mandatoryField()}
                        </Form.Label>
                        <div className="val-cnt-1">
                            {!readOnly1 && !readOnly2 ? (
                                <>
                                    <Multiselect
                                        displayValue="name"
                                        className="multiselect-style1"
                                        onSelect={selectArea}
                                        selectedValues={impostaSelectedValues()}
                                        options={areeMS}
                                        singleSelect
                                        avoidHighlightFirstOption
                                    />
                                    {formErrors.area && formErrors.area.length > 0 ? (
                                        <>
                                            <Form.Control.Feedback type='invalid' className="block">
                                                <strong>{formErrors.area}</strong>
                                            </Form.Control.Feedback>
                                            <div className="icon">{validationIconNotvalid}</div>
                                        </>
                                    ) : (
                                        validated &&
                                        <div className="icon">{validationIconValid}</div>
                                    )}
                                </>) : (
                                <Form.Control
                                    type="text"
                                    value={area}
                                    readOnly
                                />
                            )}
                        </div>
                    </Form.Group>

                    {<Form.Group className="mb-3">
                        <Form.Label>
                            <strong>Categorie: </strong>
                            {mandatoryField()}
                        </Form.Label>
                        <div className="val-cnt-2">
                            {!readOnly1 && !readOnly2 ? (
                                <>
                                    <Multiselect
                                        options={categorieMS}
                                        selectedValues={selectedCategorieMS}
                                        onSelect={selectCategoria}
                                        onRemove={removeCategoria}
                                        placeholder="Seleziona le categorie"
                                        displayValue="name"
                                        className={getValidationStyle('categorie')}
                                    />
                                    {formErrors.categorie && formErrors.categorie.length > 0 ? (
                                        <>
                                            <Form.Control.Feedback type='invalid' className="block">
                                                <strong>{formErrors.categorie}</strong>
                                            </Form.Control.Feedback>
                                            <div className="icon">{validationIconNotvalid}</div>
                                        </>
                                    ) : (
                                        validated &&
                                        <div className="icon">{validationIconValid}</div>
                                    )}
                                </>) : (
                                <Form.Control
                                    type="text"
                                    value={categorie.map((el, i) => {
                                        if (i !== categorie.length - 1 && el !== "")
                                            return `${el}, `
                                        else return el
                                    })}
                                    readOnly
                                />
                            )}
                        </div>
                    </Form.Group>}
                    <Form.Group className="mb-4">
                        <Form.Label>
                            <strong>Titolo: </strong>
                            {mandatoryField()}
                        </Form.Label>
                        <Form.Control
                            required
                            placeholder="Inserisci il titolo"
                            value={titolo}
                            readOnly={readOnly1 || readOnly2}
                            onChange={(ev) => { setTitolo(ev.target.value) }}

                        />
                        <div className="invalid-feedback">
                            <strong>{errors.titolo}</strong>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            <strong>Descrizione: </strong>
                            {mandatoryField()}
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            value={descrizione}
                            required
                            readOnly={readOnly1 || readOnly2}
                            placeholder="Inserisci la descrizione"
                            onChange={ev => { setDescrizione(ev.target.value) }}
                            className="no-resize"

                        />
                        <div className="invalid-feedback">
                            <strong>{errors.descrizione}</strong>
                        </div>
                    </Form.Group>

                    {!readOnly1 &&
                        <AllegatiProposta
                            allegati={allegati}
                            allegatiToDelete={allegatiToDelete}
                            setAllegatiToDelete={setAllegatiToDelete}
                            readOnly={readOnly2}
                        />}

                    {(props.gestisciProposta === "rispondi" && props.proposta.proStato !== statiProposta.Rifiutata) &&
                        <>
                            <Row>
                                <Col>
                                    <h4 className="mt-4"><strong>Risposta: </strong></h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="m-2">
                                    <Form.Check
                                        type="checkbox"
                                        label="Dichiara la proposta come vincitrice"
                                        checked={ammessa}
                                        readOnly={readOnly2}
                                        onChange={() => { setAmmessa(old => !old) }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            as="textarea"
                                            rows={10} placeholder="Scrivi la risposta alla proposta..."
                                            value={risposta}
                                            onChange={(ev) => { setRisposta(ev.target.value) }}
                                            className="no-resize"
                                            readOnly={readOnly2}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {formErrors.risposta && formErrors.risposta.length > 0 &&
                                <Form.Control.Feedback type='invalid' className="block">
                                    <strong>{formErrors.risposta}</strong>
                                </Form.Control.Feedback>
                            }
                        </>
                    }

                    {props.gestisciProposta === "firme" &&
                        <>
                            <Row className="mt-5 ">
                                <Col md={6}>
                                    <h4><strong>Firme raccolte offline</strong></h4>
                                </Col>

                                <Col md={6}>
                                    <InputGroup className="">
                                        <Form.Control
                                            type="number"
                                            min="1"
                                            className={"text-center " + getValidationStyle('firme')}
                                            onChange={(ev) => { setFirme(ev.target.value) }}
                                        />
                                    </InputGroup>
                                    {formErrors.firme &&
                                        <Form.Control.Feedback
                                            type='invalid'
                                            className="block"
                                        >
                                            <strong>{formErrors.firme}</strong>
                                        </Form.Control.Feedback>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>Firme presenti finora: (<strong>{props.proposta.proVotiOffline}</strong>)</Col>
                            </Row>
                        </>
                    }
                    <AzioniProposta
                        gestisciProposta={props.gestisciProposta}
                        handleSubmit={handleSubmit}
                        preparaRifiuta={preparaRifiuta}
                        setGestisci={props.setGestisci}
                        stato={props.proposta.proStato}
                    />
                </Form>
            </Container>

            {/* modale di conferma operazione */}
            <ModalConferma
                mostra={mostraModaleOperazione}
                setMostra={setMostraModaleOperazione}
                mode={parolaChiave.toLowerCase() === "rifiuta" ? "danger" : "continua"}
                titolo={modaleTitolo}
                messaggio={modaleMessaggio}
                bodyAlign="text-center"
                btnConferma={parolaChiave.toLowerCase() === "firme" ?
                    "Si, registra le firme" :
                    `Si, ${parolaChiave.toLowerCase()}`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleOperazione(false) }}
                azioneConferma={() => {
                    if (parolaChiave.toLowerCase() === "rifiuta")
                        rifiutaProposta()
                    else if (["pubblica", "rispondi", "firme"].includes(parolaChiave.toLowerCase()))
                        salvaProposta(parolaChiave)
                }}
            />

            {/* modali di esito operazione */}
            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo={modaleTitolo}
                messaggio={modaleMessaggio}
                bodyAlign="text-center"
                btnConferma="Torna a tutte le proposte"
                azioneConferma={() => { props.setGestisci("proposte-elenco") }}
            />

            {/* modali di errore */}
            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo={modaleTitolo}
                messaggio={modaleMessaggio}
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => {
                    setMostraModaleErrore(false)
                }}
            />
        </>
    )
}