import { useState, useEffect } from "react";
import { Form, Row, Col, Card, Container, Button } from "react-bootstrap";
import CategorieProposte from "../BilanciPartecipativi/CategorieProposte";
import AreeUrbane from "../BilanciPartecipativi/AreeUrbane";
import ImpostazioniComuneService from "../../../services/ImpostazioniComuneService";
import ModalConferma from "../../Utilities/ModalConferma";

export default function ImpostazioniIniziative(props) {
    let [categorie, setCategorie] = useState([])
    let [aree, setAree] = useState([])

    // Stati per le finestre modali
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)

    const salvaImpostazioni = () => {
        let impostazioni = {
            ...props.comune.impostazioniComune,
            imcCategorieProgetti: { categorie_progetti: [...categorie] },
            imcAreeUrbane: { aree_urbane: [...aree] },
            comune: props.comune
        }

        ImpostazioniComuneService.aggiornaImpostazioni(impostazioni)
            .then(res => {
                if (res.status === 200) {
                    setMostraModaleConferma(false)
                    setMostraModaleSuccesso(true)
                    props.setRicaricaComune(true)
                    props.setMostraTabella(true)

                }
                else if (res.status === 500) {
                    setMostraModaleConferma(false)
                    setMostraModaleErrore(true)
                }
            }).catch(err => {
                setMostraModaleConferma(false)
                setMostraModaleErrore(true)
            })
    }

    useEffect(() => {
        setCategorie((props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategorieProgetti && props.comune.impostazioniComune.imcCategorieProgetti.categorie_progetti) || [])
        setAree((props.comune.impostazioniComune && props.comune.impostazioniComune.imcAreeUrbane && props.comune.impostazioniComune.imcAreeUrbane.aree_urbane) || [])
        props.setStopLoading(true)
    }, [props.comune, props])

    return (
        <>
            <Form>
                <Row className="d-flex">
                    <Col xs={12} md={6}>
                        <Card className="h-100 p-3 pt-0">
                            <Form.Group as={Col} className="mb-4 h-100">
                                <Form.Label column sm="6" className="pb-0">
                                    <p className="mb-2">Categorie per i progetti</p>
                                </Form.Label>
                                <CategorieProposte categorie={categorie} setCategorie={setCategorie} />
                            </Form.Group>
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card className="h-100 p-3 pt-0">
                            <Form.Group as={Col} className="mb-4 h-100">
                                <Form.Label column sm="6" className="pb-0">
                                    <p className="mb-2">Aree urbane</p>
                                </Form.Label>
                                <AreeUrbane aree={aree} setAree={setAree} />
                            </Form.Group>
                        </Card>
                    </Col>
                </Row >
            </Form >

            <Container className="d-flex justify-content-end w-90 p-0 pt-5">
                <Button onClick={() => { setMostraModaleConferma(true) }}>Aggiorna</Button>
            </Container>


            <ModalConferma
                mostra={mostraModaleConferma}
                setMostra={setMostraModaleConferma}
                mode="continua"
                titolo="Salvataggio impostazioni"
                messaggio="Confermi il salvataggio delle impostazioni?"
                bodyAlign="text-center"
                btnConferma="Si, salva"
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleConferma(false) }}
                azioneConferma={salvaImpostazioni}
            />

            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo="Salvataggio impostazioni"
                messaggio="Impostazioni aggiornate con successo"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => { setMostraModaleSuccesso(false) }}
            />

            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo="Salvataggio impostazioni"
                messaggio="Si è verificato un errore durante l'aggiornamento delle impostazioni"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => { setMostraModaleErrore(false) }}
            />
        </>
    )
}