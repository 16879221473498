import axios from 'axios'

const PETIZIONI_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'petizione'

const headers = { 'Content-Type': 'application/json' }

class PetizioniService {
    getAll() {
        return axios.get(PETIZIONI_REST_API_URL + "/all")
    }

    findByComune(comId) {
        return axios.get(PETIZIONI_REST_API_URL + `/comune/${comId}`)
    }

    savePetizione(petizione) {
        return axios.post(PETIZIONI_REST_API_URL + "/save", petizione, headers)
    }

    getPetizione(petId) {
        return axios.get(PETIZIONI_REST_API_URL + `/${petId}`)
    }
}

const petizioniServiceInstance = new PetizioniService()
export default petizioniServiceInstance