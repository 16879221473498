import axios from 'axios'

const FIRME_PETIZIONE_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'firme-petizione'

const headers = { 'Content-Type': 'application/json' }

class FirmePetizioneService {
    registraFirma(firma) {
        return axios.post(FIRME_PETIZIONE_REST_API_URL + "/save", firma, headers);
    }

    revocaFirma(firma) {
        return axios.post(FIRME_PETIZIONE_REST_API_URL + "/delete", firma, headers)
    }
}

const firmePetizioneServiceInstace = new FirmePetizioneService()
export default firmePetizioneServiceInstace;