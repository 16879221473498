import axios from 'axios'

const SOGLIE_REFERENDUM_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'soglie-referendum'

const headers = { 'Content-Type': 'application/json' }

class SoglieReferendumService {
    aggiornaSoglieReferendum(referendum) {
        return axios.post(SOGLIE_REFERENDUM_REST_API_URL + "/save", referendum, headers);
    }
}

const soglieReferendumServiceInsance = new SoglieReferendumService()
export default soglieReferendumServiceInsance