import axios from 'axios'

const FASI_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'iniziativa-fasi'

const headers = { 'Content-Type': 'application/json' }

class IniziativaFasiService {
        aggiornaFasi(iniziativa) {
            return axios.post(FASI_REST_API_URL + "/save", iniziativa, { headers });
        }
    
        eliminaFasi(fasi) {
            return axios.post(FASI_REST_API_URL + "/delete", fasi, { headers });
        }
}

const iniziativaFasiServiceInstance = new IniziativaFasiService();
export default iniziativaFasiServiceInstance;