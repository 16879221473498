import dayjs from "dayjs";
import { Card, Col, Row } from "react-bootstrap";
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)


export default function CartaFase(props) {

    const getColoreFase = () => {
        let inizioFase = dayjs(props.fase.fasDataInizio)
        let fineFase = dayjs(props.fase.fasDataFine)
        if (dayjs().isBetween(inizioFase, fineFase, null, [])) {
            return "success"
        } else {
            return "muted"
        }
    }

    return (
        <Card className="cartaSommario shadow mt-4" border={getColoreFase()}>
            <Card.Body>
                <span className="smallText smallCaps ">Fase {props.fase.fasOrdine}</span>
                <h5> <strong>{props.fase.fasTitolo}</strong></h5>
                <p className="smallText">{props.fase.fasDescrizione}</p>
                {props.fase.fasFlVotazioni && props.fase.fasFlInvioProposte &&
                    <p> Puoi <strong>pubblicare proposte</strong> e  <strong> votarle</strong>. </p>
                }
                {props.fase.fasFlVotazioni && !props.fase.fasFlInvioProposte &&
                    <p >Puoi <strong> votare</strong> le proposte.</p>
                }
                {!props.fase.fasFlVotazioni && props.fase.fasFlInvioProposte &&
                    <p> Puoi <strong>pubblicare proposte.</strong></p>
                }
                <Row>
                    <Col>{dayjs(props.fase.fasDataInizio).format('DD/MM/YYYY')}</Col>
                    <Col className="text-end">{dayjs(props.fase.fasDataFine).format('DD/MM/YYYY')}</Col>
                </Row>
            </Card.Body>
        </Card>
    )
}