import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { rispondi,  visualizza } from '../../../utilities/icone'
import { statiSegnalazione } from '../../Utilities/statiElementEnum.js'

export default function AzioniTabella(props) {

    const handleClick = (gestisci) => {
        props.setMostraTabella(false)
        props.setSegId(props.segId)
        props.setGestisci(gestisci)
    }

    return (

        <Container   className="d-flex justify-content-around p-0 m-0">
        {[statiSegnalazione.Rifiutata, statiSegnalazione.Chiusa].includes(props.stato) ?
            <Container className="d-flex justify-content-between p-0 m-0">
                <OverlayTrigger placement="bottom"
                    overlay={
                        <Tooltip>Visualizza</Tooltip>
                    }>
                    <Button variant="noBackground" onClick={() => { handleClick("visualizza") }}>
                        {visualizza}
                    </Button>
                </OverlayTrigger>
            </Container>
            :
            <Container className="d-flex justify-content-between p-0 m-0">
                <OverlayTrigger placement="bottom"
                    overlay={
                        <Tooltip>Rispondi</Tooltip>
                    }>
                    <Button variant="noBackground" onClick={() => { handleClick("rispondi") }}>
                        {rispondi}
                    </Button>
                </OverlayTrigger>
            </Container>
        }
        </Container >
    )
}