import { useState, useEffect } from "react";
import { Container, Form, Alert, Button } from "react-bootstrap";
import Soglie from "./ImpostazioniSoglie";
import ImpostazioniComuneService from "../../../services/ImpostazioniComuneService";
import ModalConferma from "../../Utilities/ModalConferma";

export default function ImpostazioniReferenda(props) {
  let [soglie, setSoglie] = useState([])
  let [showAlert, setShowAlert] = useState({ message: "", variant: "" })
  let stepSoglie = 20

  // Stati per le finestre modali
  const [mostraModaleConferma, setMostraModaleConferma] = useState(false)
  const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
  const [mostraModaleErrore, setMostraModaleErrore] = useState(false)

  useEffect(() => {
    setSoglie((props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.soglie) || [])
  }, [props.comune])

  const checkSoglie = function () {
    let ret = true
    let sogValore

    soglie.forEach(soglia => {
      if (
        soglia.sogOrdine > 1 &&
        soglia.sogActive === true &&
        soglia.sogValore < (sogValore + stepSoglie)
      )
        ret = false

      if (soglia.sogActive === true)
        sogValore = soglia.sogValore
    })

    return ret
  }

  const svuotaMessaggi = () => {
    setShowAlert({ message: "", variant: "" })
  }

  const preparaSalva = () => {
    if (!checkSoglie())
      setShowAlert({ message: "Controlla i valori delle soglie!", variant: "danger" })
    else {
      setMostraModaleConferma(true)
    }
  }

  const salvaImpostazioni = async () => {
    let impostazioni = {
      ...props.comune.impostazioniComune,
      soglie: soglie,
      comune: props.comune
    }
    ImpostazioniComuneService.aggiornaImpostazioni(impostazioni)
      .then(res => {
        if (res.status === 200) {
          setMostraModaleConferma(false)
          setMostraModaleSuccesso(true)
          props.setRicaricaComune(true)
          props.setMostraTabella(true)

        } else if (res.status === 500) {
          setMostraModaleErrore(true)
          setMostraModaleConferma(false)
        }
      }).catch(err => {
        setMostraModaleConferma(false)
        setMostraModaleErrore(true)
      })
  }

  return (
    <>
      <Form>
        <Soglie
          soglie={soglie}
          setSoglie={setSoglie}
          stepSoglie={stepSoglie}
          svuotaMessaggi={svuotaMessaggi}
        />
      </Form >

      <Container className="d-flex justify-content-end w-90 p-0 pt-5">
        <Button className="mb-5 shadow" onClick={preparaSalva}>Aggiorna</Button>
      </Container>

      {
        showAlert.message &&
        <Container className="fixed-bottom w-50">
          <Alert variant={showAlert.variant}>{showAlert.message}</Alert>
        </Container>
      }

      <ModalConferma
        mostra={mostraModaleConferma}
        setMostra={setMostraModaleConferma}
        mode="continua"
        titolo="Salvataggio impostazioni"
        messaggio="Confermi il salvataggio delle impostazioni?"
        bodyAlign="text-center"
        btnConferma="Si, salva"
        btnAnnulla="No, annulla"
        azioneAnnulla={() => { setMostraModaleConferma(false) }}
        azioneConferma={salvaImpostazioni}
      />

      <ModalConferma
        mostra={mostraModaleSuccesso}
        setMostra={setMostraModaleSuccesso}
        mode="success"
        titolo="Salvataggio impostazioni"
        messaggio="Impostazioni aggiornate con successo"
        bodyAlign="text-center"
        btnConferma="Chiudi"
        azioneConferma={() => { setMostraModaleSuccesso(false) }}
      />

      <ModalConferma
        mostra={mostraModaleErrore}
        setMostra={setMostraModaleErrore}
        mode="error"
        titolo="Salvataggio impostazioni"
        messaggio="Si è verificato un errore durante l'aggiornamento delle impostazioni"
        bodyAlign="text-center"
        btnConferma="Chiudi"
        azioneConferma={() => { setMostraModaleErrore(false) }}
      />
    </>
  )

}