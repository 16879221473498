import { useState } from "react";
import { Row, Col, Form, Figure } from "react-bootstrap";
import ModalConferma from "../../Utilities/ModalConferma";
import ModalePreview from "../../Utente/Segnalazioni/ModalePreview";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import SegnalazioniService from "../../../services/SegnalazioniService";
import { statiSegnalazione } from "../../Utilities/statiElementEnum";
import AzioniSegnalazione from "./AzioniSegnalazione"

const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default function GestisciSegnalazione(props) {
    // Estrazione, dall'array della segnalazione, della segnalazione da visualizzare 
    let segnalazione = props.segnalazioni.filter(el => el.segId === props.segId)[0]

    let [risposta, setRisposta] = useState(segnalazione.segRisposta)

    let [mostraErroreRisposta, setMostraErroreRisposta] = useState(false)

    // Stati per le finestre modali
    const [mostraModaleOperazione, setMostraModaleOperazione] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)
    const [parolaChiave, setParolaChiave] = useState("")
    const [titolo, setTitolo] = useState("")
    const [messaggio, setMessaggio] = useState("")


    const [mostraPreview, setMostraPreview] = useState(false)
    const [numeroAllegato, setNumeroAllegato] = useState(0);
    const [oggettoSegnalazione, setOggettoSegnalazione] = useState("");
    const [figuraPreview, setFiguraPreview] = useState("")

    const apriPreview = (index, file, oggetto) => {
        setNumeroAllegato(index + 1)
        setFiguraPreview(file)
        setOggettoSegnalazione(oggetto)
        setMostraPreview(true)
    }

    const redirectToList = ()=>{
        props.setMostraTabella(true)
    }

    // CHIUDI SEGNALAZIONE
    const preparaChiudi = () => {
        setMostraModaleOperazione(true)
        setParolaChiave("Chiudi")
        setTitolo("Chiudi la segnalazione")
        setMessaggio("Sei sicuro di voler chiudere la segnalazione? L'operazione è irreversibile!")
    }

    const chiudiSegnalazione = () => {
        if (!risposta) {
            setMostraErroreRisposta(true)
        } else {
            let tmp = {
                ...segnalazione,
                segStato: statiSegnalazione.Chiusa,
                segRisposta: risposta,
                segDataChiusura: dayjs().format("YYYY-MM-DD")
            }
            SegnalazioniService.saveSegnalazione(tmp)
                .then((res) => {
                    setMostraModaleOperazione(false)
                    if (res.status === 200) {
                        setMostraModaleSuccesso(true)
                        setParolaChiave("Chiusura")
                        setTitolo("Chiusura della segnalazione")
                        setMessaggio("Chiusura della segnalazione avvenuto con successo.")

                    } else if (res.status === 500) {
                        setMostraModaleErrore(true)
                        setParolaChiave("Chiusura")
                        setTitolo("Chiusura della segnalazione")
                        setMessaggio("Si è verificato un errore durante la chiusura della segnalazione.")
                    }

                })
                .catch((err) => {
                    setMostraModaleErrore(true)
                    setParolaChiave("Chiusura")
                    setTitolo("Chiusura della segnalazione")
                    setMessaggio("Si è verificato un errore durante la chiusura della segnalazione.")
                })
        }
    }

    // RIFIUTA SEGNALAZIONE
    const preparaRifiuta = () => {
        setMostraModaleOperazione(true)
        setParolaChiave("Rifiuta")
        setTitolo("Rifiuta la segnalazione")
        setMessaggio("Sei sicuro di voler rifiutare la segnalazione? L'operazione è irreversibile!")
    }

    const rifiutaSegnalazione = () => {
        let tmp = {
            ...segnalazione,
            segStato: statiSegnalazione.Rifiutata,
            segRisposta: risposta,
            segDataChiusuta: dayjs().format("YYYY-MM-DD")
        }

        SegnalazioniService.saveSegnalazione(tmp)
            .then((res) => {
                setMostraModaleOperazione(false)
                if (res.status === 200) {
                    setMostraModaleSuccesso(true)
                    setParolaChiave("Rifiuto")
                    setTitolo("Rifiuto della segnalazione")
                    setMessaggio("Rifiuto della segnalazione avvenuto con successo.")
                }
                else if (res.status === 500) {
                    setMostraModaleErrore(true)
                    setParolaChiave("rifiuto")
                    setTitolo("Errore nel rifiuto della segnalazione")
                    setMessaggio("Si è verificato un errore durante il rifiuto della segnalazione.")
                }
            })
            .catch((err) => {
                setMostraModaleErrore(true)
                setParolaChiave("rifiuto")
                setTitolo("Errore nel rifiuto della segnalazione")
                setMessaggio("Si è verificato un errore durante il rifiuto della segnalazione.")
            })
    }

    return (
        <>
            <div className="container-sm">
                {props.gestisci === "visualizza"?
                <h2 className="title2">Visualizza la segnalazione</h2>
                :
                <h2 className="title2">Rispondi alla segnalazione</h2>
            }
                <Row>
                    <Col sm={12} md={6} style={{ height: "100%" }}>
                        <Row className="d-flex justify-content-between mb-2">
                            <span className="w-auto"><strong>Autore: </strong>
                                {segnalazione.partecipante.datiPartecipante.dparNome + ' ' + segnalazione.partecipante.datiPartecipante.dparCognome}
                            </span>

                            <span className="w-auto"><strong>Inviata il: </strong>
                                {dayjs(segnalazione.segDataInvio).format('DD/MM/YYYY')}</span>
                        </Row>

                        <Row className="d-flex justify-content-between">
                            <span className="w-auto mb-2"><strong>Oggetto: </strong>
                                {segnalazione.segOggetto}
                            </span>
                            <span className="w-auto mb-2"><strong>Categoria: </strong>
                                {segnalazione.segCategoria}
                            </span>
                            <span className="w-auto mb-2"><strong>Posizione: </strong>
                                {segnalazione.posizione.posNome}
                            </span>
                        </Row>

                        <Row>
                            <Form>
                                <Form.Group className="mb-3" >
                                    <Form.Label>
                                        <strong>Descrizione: </strong>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={10}
                                        value={segnalazione.segDescrizione}
                                        readOnly
                                        className="no-resize bg-light"
                                    />
                                </Form.Group>
                            </Form>
                        </Row>
                    </Col>
                    <Col sm={12} md={6} className="pb-3" style={{ minHeight: "40vh" }}>
                        {segnalazione.posizione &&
                            <MapContainer className="mx-1 mt-1" style={{ height: "100%" }}
                                center={[segnalazione.posizione && segnalazione.posizione.posLatitudine, segnalazione.posizione && segnalazione.posizione.posLongitudine]} zoom={15} scrollWheelZoom={false}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker

                                    position={[segnalazione.posizione && segnalazione.posizione.posLatitudine, segnalazione.posizione && segnalazione.posizione.posLongitudine]}
                                >
                                    <Popup minWidth={90}>
                                        <span style={{ textAlign: "justify" }}>
                                            {segnalazione.posizione && segnalazione.posizione.posNome}
                                        </span>
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        }
                    </Col>
                </Row>
                <Row>
                    <h5><strong>Allegati:</strong></h5>
                    {segnalazione.allegatiSegnalazione && segnalazione.allegatiSegnalazione.length > 0 &&
                        segnalazione.allegatiSegnalazione.map((file, index) => (
                            <Col md={4} sm={6} key={index}>
                                <Figure className="p-1 allegatoSegnalazione">
                                    <Figure.Image
                                        width={300}
                                        height={200}
                                        onClick={() => { apriPreview(index, file.allSegFile, segnalazione.segOggetto) }}
                                        src={`data:image/png;base64,${file.allSegFile}`}
                                    />
                                </Figure>
                            </Col>
                        ))
                    }
                </Row>
                <Row>
                    <Col>
                        <h4 className="mt-4"><strong>Risposta *: </strong></h4>
                        {mostraErroreRisposta && <p className="mt-1 text-danger d-block"><strong>
                            Non puoi chiudere una segnalazione senza pubblicare una risposta
                        </strong>
                        </p>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Scrivi la risposta alla segnalazione..."
                                    value={risposta}
                                    onChange={(ev) => { setRisposta(ev.target.value) }}
                                    className="no-resize"
                                    disabled={props.gestisci==="visualizza"}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <AzioniSegnalazione
                    gestisci={props.gestisci}
                    segStato={segnalazione.segStato}
                   redirectToList={redirectToList}
                    preparaRifiuta={preparaRifiuta}
                    preparaChiudi={preparaChiudi}
                    rifiutaSegnalazione={rifiutaSegnalazione}
                    chiudiSegnalazione={chiudiSegnalazione}
                />

                

            </ div >

            {/* modale di conferma operazione */}
            <ModalConferma
                mostra={mostraModaleOperazione}
                setMostra={setMostraModaleOperazione}
                mode={parolaChiave.toLowerCase() === "rifiuta" ? "danger" : "continua"}
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma={`Si, ${parolaChiave.toLowerCase()}`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleOperazione(false) }}
                azioneConferma={() => {
                    if (parolaChiave.toLowerCase() === "rifiuta")
                        rifiutaSegnalazione()
                    else chiudiSegnalazione()
                }}
            />

            {/* modali di esito operazione */}
            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Torna a tutte le segnalazioni"
                azioneConferma={() => {
                    props.setRicaricaSegnalazioni(true)
                    props.setMostraTabella(true)
                }}
            />

            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => {
                    setMostraModaleErrore(false)
                }}
            />
            <ModalePreview
                mostraPreview={mostraPreview}
                setMostraPreview={setMostraPreview}
                numeroAllegato={numeroAllegato}
                oggettoSegnalazione={oggettoSegnalazione}
                figuraPreview={figuraPreview}
            />
        </>
    )
}