import { Modal, Button } from "react-bootstrap";
import { CgCloseO } from 'react-icons/cg'
import { FiAlertTriangle } from 'react-icons/fi'
import { useNavigate, useLocation } from "react-router-dom";
export default function ModalPermessi(props) {
    const location = useLocation()
    const handleClose = () => {
        props.setMostra(false)
    };

    const goToPath = (path) => {
        navigate(path, 
            { 
                state: 
                { 
                    prevPath: location.pathname, 
                    petId: location.state && location.state.petId, 
                    refId: location.state && location.state.refId, 
                    bilId: location.state && location.state.bilId, 
                    proId: location.state && location.state.proId } })
    }
    const navigate = useNavigate();

    return (
        <Modal
            show={props.mostra}
            onHide={handleClose}
            centered
            backdrop={props.mode === "success" ? "static" : "true"}
        >
            <div className="modalPermessi" style={{ borderColor: `${props.mode === "danger" ? "red" : "orange"} ` }}>
                <Modal.Header >
                    <Modal.Title className="w-100 text-center ">
                        {props.mode === 'danger' ?
                            <CgCloseO className="me-2 text-danger" />
                            :
                            <FiAlertTriangle className="me-2 text-warning" />
                        }
                        {props.titolo}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.messaggio}
                </Modal.Body>
                <Modal.Footer className={props.mode !== "success" ? "d-flex justify-content-center" : "d-flex justify-content-center"} >
                    <Button variant="primary"
                        onClick={() => {
                            if (props.mode !== "danger") goToPath("/login")
                            handleClose()
                        }}>
                        {props.mode === "danger" ? "Continua la navigazione" : "Accedi alla pagina di login"}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal >
    )
}