import { useEffect, useState } from "react"
import { useLocation, } from 'react-router-dom';
import { Tabs, Tab, Container, Row, Col, Button } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom';
import iniziativaServiceInstance from "../../../services/IniziativaService";
import InformazioniIniziativa from "./InformazioniIniziativa";
import EsploraProgetti from "./EsploraProgetti";

export default function PaginaIniziativa(props) {
    const [iniziativa, setIniziativa] = useState({})
    const [loadingIni, setLoadingIni] = useState(true)
    const location = useLocation();
    const navigate = useNavigate();

    const cambiaTab = () => {
        props.setActiveTab(old => old === "informazioni" ? "progetti" : "informazioni")
    }


    //fetch petizione
    useEffect(() => {
        if (location.state && location.state.iniId) {
            iniziativaServiceInstance.getIniziativa(location.state.iniId)
                .then((res) => {
                    setIniziativa(res.data)
                })
                .catch((err) => {
                    setIniziativa({})
                    //TODO gestione dell'errore
                })
                .finally(() => { setLoadingIni(false) })

            if (location.state.activeTab === "progetti") {
                props.setActiveTab("progetti")
            }
            if (location.state.activeTab === "info") {
                props.setActiveTab("informazioni")
            }
        }
        // eslint-disable-next-line
    }, [location.state, location.state.activeTab])

    if (props.comune && props.comune.impostazioniComune) {
        if (
            props.comune.impostazioniComune.imcModuloIniziativeAttivo &&
            location.state &&
            location.state.iniId
        ) {
            return (
                <>
                    <div className="bg-secondary w-100" style={{ minHeight: "30vh", position: "absolute", zIndex: "-1" }} />
                    <Container className="mt-4 mb-4">
                        <span className="smallcaps dark">{props.comune.impostazioniComune.imcLabelModuloIniziative}</span>
                        <h1 className="title dark">{iniziativa.iniTitolo}</h1>
                    </Container>
                    <Container >
                        <Tabs activeKey={props.activeTab} className="bg-light" style={{ borderRadius: "10px 10px 0px 0px" }}
                            onClick={cambiaTab}>
                            <Tab eventKey="informazioni" title="Informazioni">
                                 <InformazioniIniziativa
                                    iniziativa={iniziativa}
                                    activeTab={props.activeTab}
                                    cambiaTab={cambiaTab}
                                    partecipante={props.partecipante}
                                    loadingIni={loadingIni}
                                /> 
                            </Tab>
                            <Tab eventKey="progetti" title="Vedi tutti i progetti">
                                 <EsploraProgetti
                                    iniziativa={iniziativa}
                                    comune={props.comune}
                                    partecipante={props.partecipante}
                                    loadingIni={loadingIni}
                                />
                            </Tab>
                        </Tabs>
                        <Row>
                            <Col xs={6} className='pr-0'>
                                <Button variant="outline-dark"
                                    onClick={() => {
                                        navigate(-1)
                                    }}>
                                    <FiChevronLeft />
                                    Torna a tutti gli elementi
                                </Button>
                            </Col>
                            <Col xs={6} className="p-0 d-flex ">
                                {props.activeTab === "informazioni" &&
                                    <Button variant="outline-dark  mb-4"
                                        onClick={() => {
                                            cambiaTab()
                                        }}>
                                        Vedi tutti i progetti
                                        <FiChevronRight />
                                    </Button>}
                            </Col>
                        </Row>
                    </Container>
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}