import { Button, Container, OverlayTrigger, Tooltip, Col, Row, Modal, Form, Alert } from "react-bootstrap"
import { BiBlock } from "react-icons/bi"
import { HiOutlineKey } from "react-icons/hi"
import { BsCheckSquare } from "react-icons/bs"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import PartecipanteService from "../../../services/PartecipanteService"
import { IoIosCog } from "react-icons/io";


export default function AzioniPerVerificati(props) {
    const [show, setShow] = useState(false)
    const [mode, setMode] = useState("")

    return (
        <>
            <Container className="d-flex justify-content-around p-0 m-0">
                {props.partecipante.parStato === 'bloccato' ?
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip>Sblocca</Tooltip>
                        }>
                        <Button
                            variant="noBackground p-0"
                            onClick={() => { setShow(true); setMode("Sblocca") }}
                        >
                            <IoIosCog size="1.5em" />
                        </Button>
                    </OverlayTrigger>
                    :
                    <>

                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>Blocca</Tooltip>
                            }>
                            <Button
                                variant="noBackground p-0"
                                onClick={() => { setShow(true); setMode("Blocca") }}
                            >
                                <BiBlock size="1.5em" />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>Modifica permessi</Tooltip>
                            }>
                            <Button
                                variant="noBackground p-0"
                                onClick={() => { setShow(true); setMode("Modifica permessi") }}
                            >
                                <HiOutlineKey size="1.5em" />
                            </Button>
                        </OverlayTrigger>
                    </>}
            </Container >
            <ModaleVerificato
                partecipante={props.partecipante}
                show={show}
                setShow={setShow}
                mode={mode}
                setRicaricaPartecipanti={props.setRicaricaPartecipanti}
                impostazioni={props.impostazioni}
            />
        </>

    )
}


function ModaleVerificato(props) {
    const [checks, setChecks] = useState(
        {
            ruoFlVotantePetizioni: false,
            ruoFlVotanteReferendum: false,
            ruoFlVotanteProposte: false,
            ruoFlPubblicatorePetizioni: false,
            ruoFlPubblicatoreReferendum: false,
            ruoFlPubblicatoreProposte: false,
            ruoFlPubblicatoreSegnalazioni: false
        })
    const [ruolo, setRuolo] = useState(false)

    const [gestisciAlert, setGestisciAlert] = useState({ mostra: false, variant: "success", messaggio: "" })

    const handleClick = () => {
        PartecipanteService.bloccaPartecipante(props.partecipante.parId, props.mode === 'Sblocca')
            .then(res => {
                handleClose()
                props.setRicaricaPartecipanti(true);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleSave = () => {
        let tmp = {
            ...props.partecipante,
            ruolo: {
                ...props.partecipante.ruolo,
                ...checks,
                ruoFlAmministratore: ruolo,
                ruoData: dayjs().format('YYYY-MM-DDTHH:mm')
            }
        }
        PartecipanteService.aggiornaPartecipante(tmp)
            .then(res => {
                //handleClose()
                props.setRicaricaPartecipanti(true);
                setGestisciAlert({ mostra: true, variant: "success", messaggio: "Aggiornamento permessi eseguito correttamente" })
            })
            .catch(err => {
                console.log(err)
                setGestisciAlert({ mostra: true, variant: "danger", messaggio: "Errore nell'aggiornamento permessi, riprova." })
            })
    }


    useEffect(() => {
        if (gestisciAlert.mostra) {
            const timer = setTimeout(() => {
                setGestisciAlert(old => ({ ...old, mostra: false }))
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [gestisciAlert]);

    useEffect(() => {
        if (props.partecipante && props.partecipante.ruolo) {
            setChecks(
                {
                    ruoFlVotantePetizioni: props.partecipante.ruolo.ruoFlVotantePetizioni,
                    ruoFlVotanteReferendum: props.partecipante.ruolo.ruoFlVotanteReferendum,
                    ruoFlVotanteProposte: props.partecipante.ruolo.ruoFlVotanteProposte,
                    ruoFlPubblicatorePetizioni: props.partecipante.ruolo.ruoFlPubblicatorePetizioni,
                    ruoFlPubblicatoreReferendum: props.partecipante.ruolo.ruoFlPubblicatoreReferendum,
                    ruoFlPubblicatoreProposte: props.partecipante.ruolo.ruoFlPubblicatoreProposte,
                    ruoFlPubblicatoreSegnalazioni: props.partecipante.ruolo.ruoFlPubblicatoreSegnalazioni,
                    ruoFlPubblicatoreProgetti: props.partecipante.ruolo.ruoFlPubblicatoreProgetti
                }
            )
            setRuolo(props.partecipante.ruolo.ruoFlAmministratore)
        }
    }, [props.partecipante])

    const handleClose = () => props.setShow(false)
    return (
        <Modal show={props.show} onHide={handleClose}
            centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{props.mode} partecipante</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>
                        <p><strong>Nome:</strong> {props.partecipante.datiPartecipante.dparNome}</p>
                    </Col>
                    <Col>
                        <p><strong>Cognome:</strong> {props.partecipante.datiPartecipante.dparCognome}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><strong>Codice fiscale:</strong> {props.partecipante.datiPartecipante.dparCf}</p>
                    </Col>
                    <Col>
                        <p><strong>Data di nascita:</strong> {dayjs(props.partecipante.datiPartecipante.dparDataNascita).format("DD/MM/YYYY")}</p>
                    </Col>
                </Row>

                {props.mode === "Modifica permessi" ?
                    <>
                        <Row>
                            <Row>
                                <Col>
                                    <p><strong>Permessi:</strong></p>
                                </Col>
                                <Col >
                                    <Button className="p-0 px-1 ms-3 mt-1"
                                        onClick={() => {
                                            setChecks({
                                                ruoFlVotantePetizioni: true,
                                                ruoFlVotanteReferendum: true,
                                                ruoFlVotanteProposte: true,
                                                ruoFlPubblicatorePetizioni: true,
                                                ruoFlPubblicatoreReferendum: true,
                                                ruoFlPubblicatoreProposte: true,
                                                ruoFlPubblicatoreSegnalazioni: true
                                            })
                                        }}>
                                        <BsCheckSquare className="mb-1" /> Seleziona tutti</Button>
                                </Col>
                            </Row>
                            <Col>
                                {props.impostazioni.imcModuloPetizioniAttivo && <Form.Check
                                    label="Votante petizioni"
                                    type="checkbox"
                                    id="votante-petizioni"
                                    checked={checks.ruoFlVotantePetizioni}
                                    onChange={() => { setChecks(old => ({ ...old, ruoFlVotantePetizioni: !old.ruoFlVotantePetizioni })) }}
                                />}

                                {props.impostazioni.imcModuloReferendumAttivo &&
                                    <Form.Check
                                        label="Votante referendum"
                                        type="checkbox"
                                        id="votante-referendum"
                                        checked={checks.ruoFlVotanteReferendum}
                                        onChange={() => { setChecks(old => ({ ...old, ruoFlVotanteReferendum: !old.ruoFlVotanteReferendum })) }}
                                    />
                                }
                                {props.impostazioni.imcModuloBilanciAttivo &&
                                    <Form.Check
                                        label="Votante proposte"
                                        type="checkbox"
                                        id="votante-proposte"
                                        checked={checks.ruoFlVotanteProposte}
                                        onChange={() => { setChecks(old => ({ ...old, ruoFlVotanteProposte: !old.ruoFlVotanteProposte })) }}
                                    />
                                }
                            </Col>
                            <Col>
                                {props.impostazioni.imcModuloPetizioniAttivo &&
                                    <Form.Check
                                        label="Pubblicatore petizioni"
                                        type="checkbox"
                                        id="pubblicatore-petizioni"
                                        checked={checks.ruoFlPubblicatorePetizioni}
                                        onChange={() => { setChecks(old => ({ ...old, ruoFlPubblicatorePetizioni: !old.ruoFlPubblicatorePetizioni })) }}
                                    />}
                                {props.impostazioni.imcModuloReferendumAttivo &&
                                    <Form.Check
                                        label="Pubblicatore referendum"
                                        type="checkbox"
                                        id="pubblicatore-referendum"
                                        checked={checks.ruoFlPubblicatoreReferendum}
                                        onChange={() => { setChecks(old => ({ ...old, ruoFlPubblicatoreReferendum: !old.ruoFlPubblicatoreReferendum })) }}
                                    />
                                }
                                {props.impostazioni.imcModuloBilanciAttivo &&
                                    <Form.Check
                                        label="Pubblicatore proposte"
                                        id="pubblicatore-proposte"
                                        type="checkbox"
                                        checked={checks.ruoFlPubblicatoreProposte}
                                        onChange={() => { setChecks(old => ({ ...old, ruoFlPubblicatoreProposte: !old.ruoFlPubblicatoreProposte })) }}
                                    />
                                }
                                {props.impostazioni.imcModuloSegnalazioniAttivo &&
                                    <Form.Check
                                        label="Pubblicatore segnalazioni"
                                        id="pubblicatore-segnalazioni"
                                        type="checkbox"
                                        checked={checks.ruoFlPubblicatoreSegnalazioni}
                                        onChange={() => { setChecks(old => ({ ...old, ruoFlPubblicatoreSegnalazioni: !old.ruoFlPubblicatoreSegnalazioni })) }}
                                    />
                                }
                                {props.impostazioni.imcModuloIniziativeAttivo &&
                                    <Form.Check
                                        label="Pubblicatore progetti"
                                        id="pubblicatore-progetti"
                                        type="checkbox"
                                        checked={checks.ruoFlPubblicatoreProgetti}
                                        onChange={() => { setChecks(old => ({ ...old, ruoFlPubblicatoreProgetti: !old.ruoFlPubblicatoreProgetti })) }}
                                    />
                                }
                            </Col>
                        </Row>

                        <p className="mt-4 mb-2"><strong>Ruolo:</strong></p>
                        <Row>
                            <Col>
                                <Form.Check
                                    className="w-auto"
                                    label="Amministratore"
                                    name="group"
                                    id="ruolo-amministratore"
                                    type="radio"
                                    checked={ruolo}
                                    onChange={() => { setRuolo(old => !old) }}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    className="w-auto"
                                    label="Visualizzatore"
                                    name="group"
                                    type="radio"
                                    id="ruolo-visualizzatore"
                                    checked={!ruolo}
                                    onChange={() => { setRuolo(old => !old) }}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    (props.mode === 'Blocca' ?
                        <>
                            <Container className="text-center mt-2">
                                <p>Sei sicuro di voler bloccare questo partecipante?</p>
                                <Button variant="outline-danger"
                                    onClick={handleClick} >Si, blocca</Button>
                            </Container>
                        </>
                        :
                        <>
                            <Container className="text-center mt-2">
                                <p>Sei sicuro di voler sbloccare questo partecipante?</p>
                                <Button variant="outline-danger"
                                    onClick={handleClick} >Si, sblocca</Button>
                            </Container>
                        </>
                    )
                }
            </Modal.Body>

            <Modal.Footer>
                {props.mode !== 'Blocca' &&
                    <>
                        {gestisciAlert.mostra && (
                            <Alert variant={gestisciAlert.variant} className="w-100">{gestisciAlert.messaggio}</Alert>)
                        }
                        <Button variant="outline-primary"
                            onClick={handleClose}>Chiudi</Button>
                        <Button variant="primary"
                            onClick={handleSave}>Aggiorna permessi</Button>
                    </>
                }
            </Modal.Footer>
        </Modal >
    )
}