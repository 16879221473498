import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { controlla, rispondi, visualizza } from '../../../../utilities/icone'
import { statiProgetto, } from "../../../Utilities/statiElementEnum";

export default function AzioniProgetti(props) {

    const handleClick = (gestisciProgetto) => {
        props.setGestisci("progetto-gestisci")
        props.setGestisciProgetto(gestisciProgetto)
        props.setProgetto(props.progetto)
    }

    return (
        <Container className="d-flex justify-content-around p-0 m-0">
            {props.stato === statiProgetto.InValidazione &&
                <OverlayTrigger placement="bottom"
                    overlay={<Tooltip>Controlla</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("controlla") }}
                    >
                        {controlla}
                    </Button>
                </OverlayTrigger>
            }
            {[statiProgetto.Pubblicato].includes(props.stato) &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Rispondi</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("rispondi") }}
                    >
                        {rispondi}
                    </Button>
                </OverlayTrigger>
            }
            {props.stato === statiProgetto.Rifiutato &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Visualizza</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("rispondi") }} >
                        {visualizza}
                    </Button>
                </OverlayTrigger>
            }
        </Container >
    )
}