import React from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LoginOk() {
  let query = useQuery();
  const authID = query.get('code');

  return (
    <div>
      <h1>Login Ok</h1>
      <p>authID: {authID}</p>
    </div>
  );
}

export default LoginOk;
