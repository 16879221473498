import { Card, Figure, Form, FormGroup, FormLabel, Row, Button, ButtonGroup, Col } from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md"
import PartecipanteService from "../../../services/PartecipanteService";
import spidLogo from '../../../immagini/login/spidLogo.png'
import qr from '../../../immagini/login/fake_qr.png'
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

var bcrypt = require('bcryptjs');

export default function SPIDcard(props) {
    const [counter, setCounter] = useState(110)
    const navigate = useNavigate();
    const location = useLocation()
    const [email, setEmail] = useState("@email.com")

    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => {
                setCounter(old => old - 1)
            }, "1000")
        }
    }, [counter])

    const login = async () => {
        let hash = await bcrypt.hash("password", '$2a$10$lo6MtehiUNWnrUsytjrveu');
        PartecipanteService.login(email, hash)
            .then((res) => {
                if (res.data.parId) {
                    props.setPartecipante(res.data)
                    sessionStorage.setItem("partecipante", res.data.parId)
                    navigate(location.state.prevPath, {
                        state: {
                            petId: location.state.petId,
                            refId: location.state.refId,
                            bilId: location.state.bilId,
                            proId: location.state.proId
                        }
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <div className="pt-4 pe-5 ps-5 pb-2 bg-secondary" style={{ minHeight: "82vh" }}>
                <Card className="w-75 mx-auto my-5 p-4" >
                    <Figure className="m-2 ms-4">
                        <Figure.Image width={100} height={100} alt="LOGO spid" src={spidLogo} />
                    </Figure>
                    <div style={{ border: "1px solid lightgray" }} className="m-2 mb-4 d-none d-md-block" />
                    <h4 className="text-muted">Richiesta di accesso SPID 2 da</h4>
                    <h2>Eleweb Partecipa</h2>

                    <Row>
                        <Col xs={12} lg={7}>
                            <FormGroup className="mt-4">
                                <FormLabel className="tex-muted" style={{ color: "#787878" }}>NOME UTENTE</FormLabel>
                                <Form.Control
                                    value={email}
                                    onChange={(ev) => { setEmail(ev.target.value) }}
                                    // placeholder="nicolettacattaneo@email.com" disabled 
                                    className="SPIDformControl" />
                            </FormGroup>
                            <FormGroup className="mt-4">
                                <FormLabel className="tex-muted" style={{ color: "#787878" }}>PASSWORD</FormLabel>
                                <Form.Control placeholder="•••••••••••••••" disabled className="SPIDformControl" />
                            </FormGroup>

                            <span className="mt-4" style={{ color: "#0047bb" }}>Hai dimenticato il nome utente o la password?</span>

                            <Row className="mt-4">
                                <Col xs={12} md={{ span: 7, order: 2 }}>
                                    <ButtonGroup className="p-1 w-100">
                                        <Button className="SPIDbutton p-1" style={{ borderRight: "1px solid white" }}>
                                            <MdAccountCircle size="1.5em" />
                                        </Button>
                                        <Button className="SPIDbutton w-100 p-1" style={{ borderLeft: "1px solid white" }}
                                            onClick={login}>
                                            ENTRA CON SPID</Button>
                                    </ButtonGroup>
                                </Col>
                                <Col xs={12} md={{ span: 4, order: 1 }}>
                                    <Button className="SPIDbutton w-100 mt-2 p-1" variant="outline-primary"
                                        onClick={() => (navigate(-1))}>ANNULLA</Button>
                                </Col>
                            </Row>

                        </Col>
                        <Col className="d-none d-lg-block text-center" >
                            <Figure className="m-2 ms-4">
                                <Figure.Image width={200} height={200} alt="LOGO spid" src={qr} />
                                <Figure.Caption>Inquadra il QR Code. </Figure.Caption>
                                <Figure.Caption>Il codice è valido per <strong>{counter} </strong>secondi.</Figure.Caption>
                            </Figure>
                        </Col>
                    </Row>
                    <Row className="text-center mt-5">
                        <p>Non hai ancora SPID?
                            <span className="text-primary"> Registrati</span>
                        </p>
                    </Row>
                </Card >
            </div>
        </>
    )
}