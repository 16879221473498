import axios from 'axios'

const SEGNALAZIONE_REST_API = process.env.REACT_APP_API_PREFIX + 'segnalazione'

const headers = { 'Content-Type': 'application/json' }

class SegnalazioniService {
    getAll() {
        return axios.get(SEGNALAZIONE_REST_API + "/all")
    }

    findByPartecipante(parId) {
        return axios.get(SEGNALAZIONE_REST_API + `/partecipante/${parId}`)
    }

    findByComune(comId) {
        return axios.get(SEGNALAZIONE_REST_API + `/comune/${comId}`)
    }

    creaSegnalazione(segnalazione) {
        return axios.post(SEGNALAZIONE_REST_API + "/create", segnalazione, headers)
    }

    saveSegnalazione(segnalazione) {
        return axios.post(SEGNALAZIONE_REST_API + "/save", segnalazione, headers)
    }

    getSegnalazione(segId) {
        return axios.get(SEGNALAZIONE_REST_API + `/${segId}`)
    }
}

const segnalazioniServiceInstance = new SegnalazioniService()
export default segnalazioniServiceInstance;