import axios from 'axios';

const ALLEGATO_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'allegato';

const headers = {
    'Content-Type': 'multipart/form-data'
}

class AllegatoService {
    salvaAllegati(allegati) {
        return axios.post(ALLEGATO_REST_API_URL + "/save", allegati, { headers });
    }

    eliminaAllegati(allegati) {
        return axios.post(ALLEGATO_REST_API_URL + "/delete", allegati, { headers });
    }
}

const allegatoServiceInstance = new AllegatoService();
export default allegatoServiceInstance;
