import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import { FiChevronRight as Scopri } from 'react-icons/fi'
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getBilancioColor } from "../../Utilities/getBorderColor";
import NumberFormat from "react-number-format"
import { statiProposta } from "../../Utilities/statiElementEnum";

export default function CartaBilancio(props) {

    const navigate = useNavigate()

    return (
        <Card className="cartaSommario w-100 mt-4 mb-2"
            border={getBilancioColor(props.bilancio)}
        >
            <Card.Body>
                <h5><strong>{props.bilancio.bilTitolo}</strong></h5>
                <Row className="w-100 m-0 mt-3 p-0">
                    <Col xs={6} md={3}>
                        <strong>Edizione</strong>
                        <p>{props.bilancio.bilEdizione}</p>
                    </Col>
                    <Col xs={6} md={3}>
                        <strong>Data inizio:</strong>
                        <p>{dayjs(props.bilancio.bilDataInizio).format("DD/MM/YYYY")}</p>
                    </Col>

                    <Col xs={6} md={3}>
                        <strong>Data chiusura:</strong>
                        <p>{dayjs(props.bilancio.bilDataChiusura).format("DD/MM/YYYY")}</p>
                    </Col>

                    <Col xs={6} md={3} className="d-flex align-self-center">
                        <Badge pill
                            bg={getBilancioColor(props.bilancio)}
                            className="mt-auto mx-md-auto py-2 "
                            style={{ fontWeight: '100', fontSize: '0.80em' }}>
                            {props.bilancio.bilStato}
                        </Badge>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className='text-center px-0 pb-3'>
                <Row className='w-100 p-0 m-0 d-flex'>
                    <Col xs={12} md={9} className='pr-0'>
                        <span >
                            <strong className="bigText">
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={props.bilancio.proposte && props.bilancio.proposte.filter(p => ![statiProposta.InValidazione, statiProposta.Rifiutata].includes(p.proStato)).length}
                                />
                            </strong>
                            {' '} proposte
                        </span>

                    </Col>

                    <Col xs={12} md={3} className="p-0 d-flex align-items-baseline">
                        <Button variant="outline-primary" size="sm" className="mt-auto mx-auto"
                            onClick={() => {
                                props.setActiveTab("informazioni")
                                navigate('/bilancio', { state: { bilId: props.bilancio.bilId } })
                            }}>
                            Scopri
                            < Scopri />
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}