import { Button, Card, Col, Row, Badge } from 'react-bootstrap'
import { FiChevronRight as Scopri } from 'react-icons/fi'
import { getIniziativaColor } from '../../Utilities/getBorderColor'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ModalPermessi from "../../Utilities/ModalPermessi";
import dayjs from "dayjs";
import { statiIniziativa } from '../../Utilities/statiElementEnum';

export default function CartaIniziativa(props) {
    const navigate = useNavigate();

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();
    
    const redirect = () => {
        navigate("/iniziativa", { state: { iniId: props.iniziativa?.iniId, activeTab: "progetti" } })
    }

    const canView = () => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            navigate('/iniziativa', { state: { iniId: props.iniziativa?.iniId, activeTab: "info" } })

        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare l'elemento. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    return (
        <Card className="cartaSommarioBorder"
           border={getIniziativaColor(props.iniziativa)}
            style={{ minWidth: "70vw" }}>

            <Card.Body className='d-flex flex-column justify-content-between'>
                <Row className='m-0 p-0 w-10' style={{"height":"100%"}}>
                    <Col lg={7} xs={12}>
                        <span className='text-muted smallcaps w-auto my-auto' style={{ fontSize: "0.9em" }}>{props.iniziativa?.iniEdizione}</span>
                        <h4 className="borderTitle" onClick={canView}><strong>{props.iniziativa?.iniTitolo}</strong></h4>
                        <span>{props.iniziativa?.iniDescrizione.length > 500 ? `${props.iniziativa?.iniDescrizione.slice(0, 500)}...` : props.iniziativa?.iniDescrizione}</span>
                    </Col>
                    <Col lg={5} xs={12}>
                        <CartaInfoIniziativa iniziativa={props.iniziativa} />
                    </Col>
                </Row>
                <Row className='m-0 mt-3 p-0 w-10 justify-content-end ' >
                    <Col>
                        <Badge
                            pill
                            bg={getIniziativaColor(props.iniziativa)}
                            className="mt-auto w-auto me-2 py-2"
                            style={{ fontWeight: '100', fontSize: '0.80em', whiteSpace: "normal" }}>
                            {props.iniziativa?.iniStato}
                        </Badge>
                    </Col>
                    <Col lg={5} xs={8} className="d-flex justify-content-between">
                        <Button variant="outline-dark" className='w-s me-3 '
                            onClick={() => {
                                redirect()
                            }}>
                            Vedi i progetti{' '}
                            <Scopri />
                        </Button>

                        <Button variant="outline-dark" className='w-s'
                            onClick={canView}>
                            Invia il tuo progetto{' '}
                            <Scopri />
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </Card >
    )
}

function CartaInfoIniziativa(props) {
    
    let [iniziativa, setIniziativa] = useState()

    useEffect(() => {
        const today = dayjs()
        let iniStato = props.iniziativa?.iniStato
        let faseCurrent

        if (iniStato === statiIniziativa.InCorso){
            let dataChiusura = dayjs(props.iniziativa?.iniDataChiusura)
            if (today.diff(dataChiusura) > 0)
                iniStato = statiIniziativa.Conclusa
        }
        if (iniStato === statiIniziativa.InCorso){
            faseCurrent = props.iniziativa?.fasiIniziativa.filter(fase => (
                (dayjs(fase.finiDataInizio).isBefore(today) || dayjs(fase.finiDataInizio).isSame(today)) &&
                (dayjs(fase.finiDataFine).isAfter(today) || dayjs(fase.finiDataFine).isSame(today))
            ))[0]
        }
        if (iniStato === statiIniziativa.Conclusa){
            let faseLast = props.iniziativa?.fasiIniziativa.filter(fase =>fase.finiOrdine === props.iniziativa?.fasiIniziativa.length)[0]
            if(faseLast){
                faseCurrent = {
                    finiOrdine: faseLast.finiOrdine,
                    finiTitolo: faseLast.finiTitolo,
                    finiDescrizione: faseLast.finiDescrizione
                }
            }else{
                faseCurrent = {
                    fasOrdine: 0,
                    fasTitolo: "",
                    fasDescrizione: ""
                }
            }
        }
        setIniziativa({
            stato: iniStato,
            fasiCount: props.iniziativa?.fasiIniziativa.length,
            faseCurrent: faseCurrent
        })
    }, [props.iniziativa])

    return (
        <Card className='mt-3 mt-lg-0 card-height-lg'>
            <Card.Body>
                {iniziativa && iniziativa.faseCurrent && iniziativa.fasiCount > 0 ? (
                    <>
                        <span className='text-muted smallcaps' style={{ fontSize: "0.9em" }}>
                            Fase { iniziativa.faseCurrent.finiOrdine} di {iniziativa.fasiCount}
                        </span>
                        <h5 className='smallcaps'><strong>{ iniziativa.faseCurrent.finiTitolo}</strong></h5>
                        <p style={{ fontSize: "0.9em" }}>{ iniziativa.faseCurrent.finiDescrizione.length > 500 ? `${ iniziativa.faseCurrent.finiDescrizione.slice(0,200)}...` : iniziativa.faseCurrent && iniziativa.faseCurrent.finiDescrizione }</p>
                    </>
                ) : (
                    <span>Nessuna fase presente</span>
                )}
            </Card.Body>
        </Card>
    )
}