import axios from 'axios'

const REFERENDUM_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'referendum'

const headers = { 'Content-Type': 'application/json' }

class ReferendaService {
    getAll() {
        return axios.get(REFERENDUM_REST_API_URL + "/all");
    }

    findByComune(comId) {
        return axios.get(REFERENDUM_REST_API_URL + `/comune/${comId}`)
    }

    saveReferendum(referendum) {
        return axios.post(REFERENDUM_REST_API_URL + "/save", referendum, headers);
    }

    getReferendum(refId) {
        return axios.get(REFERENDUM_REST_API_URL + `/${refId}`)
    }
}

const referendaServiceInstance = new ReferendaService()
export default referendaServiceInstance;