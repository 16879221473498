import { Card, Row } from "react-bootstrap";
import erroreGenerico from '../../../immagini/errore_generico.png'

export default function PaginaErroreGenerico(props) {

    return (
        <>
            <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
            <h1 className="title mt-4 text-center dark">Si è verificato un errore</h1>
            <Card className="me-5 mt-2 ms-5 mb-5" style={{ minHeight: "65vh" }}>
                <Card.Body className="text-center">
                    <Row>
                            <img src={erroreGenerico} alt="Errore Generico" className="mx-auto" style={{height:"300px", width:"350px"}} />
                        <div className="mt-5" style={{ fontSize:"1.5em"}}>
                            Ci dispiace, qualcosa è andato storto.<br />
                            Riprova più tardi o contatta l'amministratore.
                        </div>
                    </Row>
                    </Card.Body>
            </Card>
        </>
    )
}