import { Card, Row, Col } from "react-bootstrap";

export default function PaginaNonTrovata(props) {

    return (
        <>
            <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
            <h1 className="title mt-4 text-center dark">Pagina non trovata</h1>
            <Card className="me-5 mt-2 ms-5 mb-5" style={{ minHeight: "65vh" }}>
                <Card.Body className="text-center">
                    <Row>
                        <Col className="mt-5" style={{ fontSize:"2em"}}>
                            Oops, non abbiamo trovato quello che stavi cercando.<br />
                            Controlla l'indirizzo che hai digitato.
                        </Col>
                    </Row>
                    </Card.Body>
            </Card>
        </>
    )
}