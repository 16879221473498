import { Container, Form, Row, Col, Button, Figure, Card } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import ModalConferma from "../../Utilities/ModalConferma";
import ComuneService from "../../../services/ComuneService";

import { FiUpload } from 'react-icons/fi'
import { BsFileEarmarkRichtext } from "react-icons/bs"
import { GoTrash } from "react-icons/go"

export default function ImpostazioniComune(props) {
    // Riferimento al form (posizione 0)
    let ref = useRef([]);
    const [validated, setValidated] = useState(false);
    
    let inputStemma = useRef(null)
    let inputBanner = useRef(null)

    let [comNome, setComNome] = useState("")
    let [comSito, setComSito] = useState("")
    let [comSottotitolo, setComSottotitolo] = useState("")
    let [comDescrizione, setComDescrizione] = useState("")

    const [mostraModalOk, setMostraModalOk] = useState(false)
    const [mostraModalFailed, setMostraModalFailed] = useState(false)
    const [mostraModalCancelOk, setMostraModalCancelOk] = useState(false)

    // stato per lo stemma caricato, metadata e arrayBuffer
    const [stemmaFile, setStemmaFile] = useState()
    const [uploadedStemmaFileMetadata, setUploadedStemmaFileMetadata] = useState([])
    const [deletedStemmaFile, setDeletedStemmaFile] = useState(false)

    // stato per il banner caricato, metadata e arrayBuffer
    const [bannerFile, setBannerFile] = useState()
    const [uploadedBannerFileMetadata, setUploadedBannerFileMetadata] = useState([])
    const [deletedBannerFile, setDeletedBannerFile] = useState(false)

    const acceptedExtensions = ["image/*", "image/png", "image/jpg", "image/jpeg"]

    const [erroriCompilazione, setErroriCompilazione] = useState({ comNome: "", comSottotitolo: "", comDescrizione: "", stemma: "", banner: "", comSito : "" })

    const mandatoryField = () => {
        return <span>*</span>
    }

    // STEMMA
    const handleDisplayStemmaFileDetails = async () => {
        if (inputStemma?.files && inputStemma.files[0]
            && inputStemma.files[0].size <= 200 * 1024 //controlla grandezza file < 200kB 
            && acceptedExtensions.includes(inputStemma.files[0].type) //controlla estensione
        ) {
            setUploadedStemmaFileMetadata(inputStemma.files[0])
            setStemmaFile(URL.createObjectURL(inputStemma.files[0]));
            setErroriCompilazione(old => ({ ...old, stemma: "" }))
            setDeletedStemmaFile(false)
        } else {
            if (!acceptedExtensions.includes(inputStemma.files[0].type))
                setErroriCompilazione(old => ({ ...old, stemma: "Il formato del file scelto non è accettabile." }))
            else if (!inputStemma.files[0].size <= 102400)
                setErroriCompilazione(old => ({ ...old, stemma: "Il file scelto è troppo grande. Riprova." }))
            setUploadedStemmaFileMetadata([])
        }
    }

    const handleStemmaUpload = (event) => {
        event.preventDefault()
        inputStemma?.click();
    }
    
    const deleteStemmaFile = () => {
        setUploadedStemmaFileMetadata([])
        setStemmaFile()
        setDeletedStemmaFile(true)
    }

    // BANNER
    const handleDisplayBannerFileDetails = async () => {
        if (inputBanner?.files && inputBanner.files[0]
            && inputBanner.files[0].size <= 30000 * 1024 //controlla grandezza file < 30000kB 
            && acceptedExtensions.includes(inputBanner.files[0].type) //controlla estensione
        ) {
            setUploadedBannerFileMetadata(inputBanner.files[0])
            setBannerFile(URL.createObjectURL(inputBanner.files[0]));
            setErroriCompilazione(old => ({ ...old, banner: "" }))
            setDeletedBannerFile(false)
        } else {
            if (!acceptedExtensions.includes(inputBanner.files[0].type))
                setErroriCompilazione(old => ({ ...old, banner: "Il formato del file scelto non è accettabile." }))
            else if (!inputBanner.files[0].size <= 102400)
                setErroriCompilazione(old => ({ ...old, banner: "Il file scelto è troppo grande. Riprova." }))
            setUploadedBannerFileMetadata([])
        }
    }

    const handleBannerUpload = (event) => {
        event.preventDefault()
        inputBanner?.click();
    };

    const deleteBannerFile = () => {
        setUploadedBannerFileMetadata([])
        setBannerFile()
        setDeletedBannerFile(true)
    }

    const checkForm = () => {
        let condizioni_ok = true

        setValidated(true);
        
        if (!comNome) {
            setErroriCompilazione(old => ({ ...old, comNome: "Nome del comune mancante" }))
            condizioni_ok = false
        }

        if (!comSito) {
            setErroriCompilazione(old => ({ ...old, comNome: "Sito del comune mancante" }))
            condizioni_ok = false
        }

        if (!comSottotitolo) {
            setErroriCompilazione(old => ({ ...old, comSottotitolo: "Sottotitolo del comune mancante" }))
            condizioni_ok = false
        }

        if (!comDescrizione) {
            setErroriCompilazione(old => ({ ...old, comDescrizione: "Descrizione del comune mancante" }))
            condizioni_ok = false
        }

        if (erroriCompilazione.stemma)
            condizioni_ok = false

        if (erroriCompilazione.banner)
            condizioni_ok = false

        if (ref.checkValidity() === true && condizioni_ok)
            setMostraModalCancelOk(true)
    }
    

    const handleSubmit = () => {
        let comune = {
            ...props.comune,
            comNome: comNome,
            comSito: comSito,
            comSottotitolo: comSottotitolo,
            comDescrizione: comDescrizione, 
            impostazioniComune: props.comune.impostazioniComune
        }
        
        var formData = new FormData()
        formData.append("comune", JSON.stringify(comune))
        formData.append("stemma", uploadedStemmaFileMetadata)
        formData.append("banner", uploadedBannerFileMetadata)
        formData.append("deletedStemma", deletedStemmaFile)
        formData.append("deletedBanner", deletedBannerFile)

        setMostraModalCancelOk(false)

        ComuneService.aggiornaComune(formData)
            .then(() => {
                props.setRicaricaComune(true)
                setMostraModalOk(true)
            })
            .catch(() => {
                setMostraModalFailed(true)
            })
    }

    const closeOkModal = () => {
        setMostraModalOk(false)
    }

    const closeOkFailed = () => {
        setMostraModalFailed(false)
    }

    useEffect(() => {
        setComNome((props.comune.comNome) || "")
        setComSito((props.comune.comSito) || "")
        setComSottotitolo((props.comune.comSottotitolo) || "")
        setComDescrizione((props.comune.comDescrizione) || "")

        setStemmaFile(`data:image/png;base64,${props.comune.comStemmaFile}`)
        setUploadedStemmaFileMetadata({ name: props.comune.comStemmaFilename })

        setBannerFile(`data:image/png;base64,${props.comune.comBannerFile}`)
        setUploadedBannerFileMetadata({ name: props.comune.comBannerFilename })
                
    }, [props.comune])

    return (
        <>
            <Container fluid="md">
                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={el => ref = el} >
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Nome comune: {mandatoryField()}</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Inserisci il nome del comune"
                                    value={comNome}
                                    onChange={(ev) => setComNome(ev.target.value)}
                                />
                                {erroriCompilazione.comNome &&
                                    <Form.Label className="mt-1 text-danger d-block">
                                        <strong>
                                            {erroriCompilazione.comNome}
                                        </strong>
                                    </Form.Label>
                                }
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Sito comune: {mandatoryField()}</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Inserisci il sito del comune"
                                    value={comSito}
                                    onChange={(ev) => setComSito(ev.target.value)}
                                />
                                {erroriCompilazione.comSito &&
                                    <Form.Label className="mt-1 text-danger d-block">
                                        <strong>
                                            {erroriCompilazione.comSito}
                                        </strong>
                                    </Form.Label>
                                }
                            </Form.Group>
                            </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Sottotitolo: {mandatoryField()}</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placheholder="Digita il sottotitolo del comune"
                                    value={comSottotitolo}
                                    onChange={(ev) => setComSottotitolo(ev.target.value)}
                                />
                                {erroriCompilazione.comSottotitolo &&
                                    <Form.Label className="mt-1 text-danger d-block">
                                        <strong>
                                            {erroriCompilazione.comSottotitolo}
                                        </strong>
                                    </Form.Label>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Descrizione: {mandatoryField()}</strong>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    required
                                    value={comDescrizione}
                                    placeholder="Digita la descrizione del comune"
                                    onChange={(ev) => setComDescrizione(ev.target.value)}
                                    className="no-resize"
                                />
                                {erroriCompilazione.comDescrizione &&
                                    <Form.Label className="mt-1 text-danger d-block">
                                        <strong>
                                            {erroriCompilazione.comDescrizione}
                                        </strong>
                                    </Form.Label>
                                }
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mt-4 mb-3">
                        <Row>
                            <Col xs={6}>
                                <Form.Label>
                                    <strong>Carica stemma</strong>
                                    <br />
                                    <span className="text-muted smallText">
                                        (dimensione massima consentita 200kB, formati accettati:.png, .jpeg, .jpg)
                                    </span>
                                </Form.Label>
                            </Col>
                            <Col xs={6} className="text-end">
                                <div>
                                    <input
                                        className="d-none"
                                        type="file"
                                        ref={el => inputStemma = el}
                                        onChange={handleDisplayStemmaFileDetails}
                                    />
                                    <Button
                                        onClick={handleStemmaUpload}
                                        disabled={uploadedStemmaFileMetadata.name} 
                                    >
                                        <FiUpload className="me-2" size="1em" /> Carica
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        {erroriCompilazione.stemma &&
                            <Form.Label className="mt-1 text-danger d-block">
                                <strong>
                                    {erroriCompilazione.stemma}
                                </strong>
                            </Form.Label>
                        }
                        {uploadedStemmaFileMetadata.name && stemmaFile &&
                            <Card className="text-center mb-4">
                                <Card.Header className="d-flex justify-content-between">
                                    <strong className="my-auto">
                                        <BsFileEarmarkRichtext size="1.5em" />{uploadedStemmaFileMetadata.name}
                                    </strong>
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => { deleteStemmaFile() }}
                                    >
                                        <GoTrash size="1.5em" />
                                    </Button>
                                </Card.Header>
                                <Figure className="p-1 allegatoSegnalazione">
                                    <Figure.Image
                                        width={300}
                                        height={300}
                                        src={stemmaFile}
                                    />
                                </Figure>
                            </Card>
                        }
                    </Form.Group>

                    <Form.Group className="mt-4 mb-3">
                        <Row>
                            <Col xs={6}>
                                <Form.Label>
                                    <strong>Carica banner</strong>
                                    <br />
                                    <span className="text-muted smallText">
                                        (dimensione massima consentita 200kB, formati accettati:.png, .jpeg, .jpg)
                                    </span>
                                </Form.Label>
                            </Col>
                            <Col xs={6} className="text-end">
                                <div>
                                    <input
                                        className="d-none"
                                        type="file"
                                        ref={el => inputBanner = el}
                                        onChange={handleDisplayBannerFileDetails}
                                    />
                                    <Button
                                        onClick={handleBannerUpload}
                                        disabled={uploadedBannerFileMetadata.name}
                                    >
                                        <FiUpload className="me-2" size="1em" /> Carica
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        {erroriCompilazione.banner &&
                            <Form.Label className="mt-1 text-danger d-block">
                                <strong>
                                    {erroriCompilazione.banner}
                                </strong>
                            </Form.Label>
                        }
                        {uploadedBannerFileMetadata.name && bannerFile &&
                            <Card className="text-center mb-4">
                                <Card.Header className="d-flex justify-content-between">
                                    <strong className="my-auto">
                                        <BsFileEarmarkRichtext size="1.5em" />{uploadedBannerFileMetadata.name}
                                    </strong>
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => { deleteBannerFile() }}
                                    >
                                        <GoTrash size="1.5em" />
                                    </Button>
                                </Card.Header>
                                <Figure className="p-1">
                                    <Figure.Image
                                        width={800}
                                        height={500}
                                        src={bannerFile}
                                    />
                                </Figure>
                            </Card>
                        }
                    </Form.Group>
                </Form>
                <Container className="d-flex justify-content-end w-90 p-0 pt-3 pb-3">
                    <Button onClick={checkForm}>Aggiorna</Button>
                </Container>
            </Container >

            <ModalConferma
                mostra={mostraModalOk}
                setMostra={setMostraModalOk}
                mode="success"
                titolo="Salvataggio impostazioni"
                messaggio="Impostazioni salvate con successo"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={closeOkModal}
            />

            <ModalConferma
                mostra={mostraModalFailed}
                setMostra={setMostraModalFailed}
                mode="error"
                titolo="Salvataggio impostazioni"
                messaggio="Errore nel salvataggio delle impostazioni"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={closeOkFailed}
            />

            <ModalConferma
                mostra={mostraModalCancelOk}
                setMostra={setMostraModalCancelOk}
                mode="primary"
                titolo="Salvataggio impostazioni"
                messaggio="Confermi il salvataggio delle impostazioni?"
                bodyAlign="text-center"
                btnAnnulla="No, annulla"
                btnConferma="Sì, salva"
                azioneConferma={handleSubmit}
            />
        </>
    )

}