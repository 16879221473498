import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { controlla, rispondi, registra_voti, visualizza } from '../../../utilities/icone'
import { statiReferendum } from "../../Utilities/statiElementEnum";

export default function AzioniTabella(props) {

    const handleClick = (gestisci) => {
        props.setMostraTabella(false)
        props.setRefId(props.refId)
        props.setGestisci(gestisci)
    }

    return (
        <Container className="d-flex justify-content-around p-0 m-0">
            {props.stato === statiReferendum.InValidazione &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Controlla</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("controlla") }}
                    >
                        {controlla}
                    </Button>
                </OverlayTrigger>
            }


            {[statiReferendum.InCorso, statiReferendum.InAttesa, statiReferendum.FirmeRaggiunte].includes(props.stato) &&
                <>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Rispondi</Tooltip>}
                    >
                        <Button
                            variant="noBackground"
                            onClick={() => { handleClick("rispondi") }}
                        >
                            {rispondi}
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Registra firme</Tooltip>}
                    >
                        <Button
                            variant="noBackground"
                            onClick={() => { handleClick("firme") }} >
                            {registra_voti}
                        </Button>
                    </OverlayTrigger>
                </>
            }

            {[statiReferendum.FirmeNonRaggiunte, statiReferendum.Rifiutato, statiReferendum.Concluso].includes(props.stato) &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Visualizza</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("visualizza") }} >
                        {visualizza}
                    </Button>
                </OverlayTrigger>
            }
        </Container >
    )
}