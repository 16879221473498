import { Card, Form, Row, Col, Button, Alert, OverlayTrigger, Popover } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import PetizioniService from "../../../services/PetizioniService";
import ModalConferma from "../../Utilities/ModalConferma";
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { max_char_descrizione, max_char_titolo } from "../../Utilities/variables";
import { Navigate } from 'react-router-dom';
import { FiAlertTriangle } from 'react-icons/fi'
import { statiPetizione } from "../../Utilities/statiElementEnum";

export default function CreaPetizione(props) {
    const location = useLocation()
    //riferimento al form
    const ref = useRef(null);

    const [titolo, setTitolo] = useState("")
    const [associazione, setAssociazione] = useState("")
    const [categoria, setCategoria] = useState("")
    const [descrizione, setDescrizione] = useState("")
    const [anonimo, setAnonimo] = useState(false)
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");


    //stati per i modali di conferma
    const [mostraModaleAnnulla, setMostraModaleAnnulla] = useState(false);
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false);
    const [mostraModaleOk, setMostraModaleOk] = useState(false)

    //stato per mostrare alert di errore
    const [messaggioAlert, setMessaggioAlert] = useState("")

    //controllo per email
    const [error, setError] = useState(null);


    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const navigate = useNavigate();
    const goToLogin = () => {
        navigate("/login", { state: { prevPath: location.pathname } })
    }

    const handleSubmit = () => {
        const form = ref.current
        setValidated(true);
        setMostraModaleConferma(false)

        if (!isValidEmail(email)) {
            setError('Formato email non valido');
        } else {
            setError(null);
        }

        if (form.checkValidity() === true) {
            let petizione = {
                petAssociazione: associazione,
                petCategoria: props.comune.impostazioniComune.imcCategoriePetizioni.categorie_petizioni[categoria],
                petDataInvio: dayjs().format("YYYY-MM-DD"),
                petDescrizione: descrizione,
                petStato: statiPetizione.InValidazione,
                petTitolo: titolo,
                petFirmeOffline: 0,
                firmePetizione: [],
                partecipante: props.partecipante,
                petEmail: email
            }

            PetizioniService.savePetizione(petizione)
                .then(() => { setMostraModaleOk(true) })
                .catch(() => { setMessaggioAlert("Impossibile inviare la petizione.") })
        } else {
            setMessaggioAlert("Impossibile inviare la petizione.")
        }
    }

    //dismiss alert
    useEffect(() => {
        setTimeout(() => {
            setMessaggioAlert("");
        }, 3000);
    }, [messaggioAlert]);

    if (props.comune && props.comune.impostazioniComune && props.partecipante) {
        if (props.comune.impostazioniComune.imcModuloPetizioniAttivo) {
            if (props.partecipante.parId && props.partecipante.ruolo) {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea la tua petizione</h1>
                        <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header>
                                <p className="mt-2 mb-0">Compila il modulo seguente con le informazioni richieste per inviare la tua petizione.</p>
                                <p className="italic text-muted">I campi contrassegnati con un asterisco (*) sono obbligatori.</p>
                            </Card.Header>
                            <Card.Body className="p-2 p-md-5 ">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={ref} >
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Titolo * </strong>
                                            <span className="text-muted smallText">(massimo: {max_char_titolo} caratteri)</span>
                                        </Form.Label>
                                        <Form.Control required placeholder="Inserisci il titolo"
                                            value={titolo}
                                            maxLength={max_char_titolo}
                                            onChange={(ev) => { setTitolo(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Associazione, Comitato</strong>
                                        </Form.Label>
                                        <Form.Control placeholder="Inserisci il nome dell'associazione o comitato"
                                            value={associazione}
                                            onChange={(ev) => { setAssociazione(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Categoria * </strong>
                                        </Form.Label>

                                        <Form.Control required as="select" type="select" className="mb-4"
                                            value={categoria}
                                            onChange={(ev) => { setCategoria(ev.target.value) }}>
                                            <option value="" disabled hidden>Seleziona la categoria</option>
                                            {props.comune && props.comune.impostazioniComune &&
                                                props.comune.impostazioniComune.imcCategoriePetizioni.categorie_petizioni.map((el, index) => (
                                                    <option value={index} key={index}>{el}</option>
                                                ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Col xs={8}>
                                            <Form.Label>
                                                <OverlayTrigger placement="right" overlay={
                                                    <Popover id="popover-contained" >
                                                        <Popover.Header as="h3" >Aiuto:</Popover.Header>
                                                        <Popover.Body>
                                                            Esporre, nella maniera più precisa e puntuale possibile, il contenuto della petizione evidenziandone i punti salienti utili all’identificazione della problematica per la quale si richiede l’intervento dell’Amministrazione ovvero di eventuali proposte regolamentari.
                                                            E’ possibile corredare la petizione con atti, documenti e foto di supporto.
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                    <strong>
                                                        <strong>Descrizione * </strong>
                                                        <span className="text-muted smallText">(massimo: {max_char_descrizione} caratteri)</span>
                                                        <AiOutlineQuestionCircle className="me-2 ms-2 mb-1" style={{ fontSize: "20px" }} />
                                                    </strong>
                                                </OverlayTrigger>
                                            </Form.Label>
                                        </Col>

                                        <Form.Control required as="textarea" placeholder="Inserisci la descrizione della petizione" rows={10}
                                            value={descrizione}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione(ev.target.value) }} />

                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Email di contatto * </strong>
                                            <span className="text-muted smallText">(l'email che inserirai sarà utilizzata dal Comune per contattarti se necessario)</span>
                                        </Form.Label>
                                        <Form.Control required type="email" placeholder="Inserisci la tua email"
                                            value={email}
                                            onChange={(ev) => { setEmail(ev.target.value) }} />
                                        <div>
                                            {error && <span className="text-danger smallText">{error}</span>}
                                        </div>
                                    </Form.Group>


                                    {props.comune && props.comune.impostazioniComune &&
                                        props.comune.impostazioniComune.imcFlPetizioniAnonime &&
                                        <Form.Group className="mb-5" as={Row}>
                                            <Col xs={8}>
                                                <Form.Label>
                                                    <strong> Pubblica in modo anonimo *  </strong>
                                                    <span className="text-muted smallText"> (il tuo nome non sarà visibile quando la petizione sarà pubblicata)</span>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={2} className="d-flex justify-content-end">
                                                <Form.Check inline type="checkbox" label="Si"
                                                    checked={anonimo}
                                                    onChange={() => { setAnonimo(old => !old) }} />
                                            </Col>
                                            <Col xs={2} className="d-flex justify-content-end">
                                                <Form.Check inline type="checkbox" label="No"
                                                    checked={!anonimo}
                                                    onChange={() => { setAnonimo(old => !old) }} />
                                            </Col>
                                        </Form.Group>
                                    }

                                    {messaggioAlert !== "" && <Alert variant="danger" >{messaggioAlert}</Alert>}
                                    <div className="d-flex justify-content-between">
                                        <Button variant="outline-dark"
                                            onClick={() => { setMostraModaleAnnulla(true) }}>
                                            Annulla
                                        </Button>
                                        <Button
                                            onClick={() => { setMostraModaleConferma(true) }}>
                                            Invia la petizione!
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>

                        <ModalConferma
                            mostra={mostraModaleAnnulla}
                            setMostra={setMostraModaleAnnulla}
                            titolo="Annulla creazione della petizione"
                            messaggio={`Sei sicuro di voler annullare la creazione della proposta? Così facendo perderai tutto quello che hai scritto fino ad ora!`}
                            btnAnnulla="No, desidero continuare"
                            btnConferma="Sì, desidero annullare"
                            mode="danger"
                            azioneAnnulla={() => { setMostraModaleAnnulla(false) }}
                            azioneConferma={() => { navigate(-1) }}
                        />

                        <ModalConferma
                            mostra={mostraModaleConferma}
                            setMostra={setMostraModaleConferma}
                            titolo="Invia la petizione"
                            messaggio="Stai per inviare la petizione che hai appena creato. Non sarà più possibile modificarla. Dovrà essere approvata dal comune prima di essere pubblicata."
                            btnAnnulla="Continua a modificare"
                            btnConferma="Invia"
                            mode="continua"
                            azioneAnnulla={() => { setMostraModaleConferma(false) }}
                            azioneConferma={() => { handleSubmit() }}
                        />

                        <ModalConferma
                            mostra={mostraModaleOk}
                            setMostra={setMostraModaleOk}
                            titolo="Petizione inviata"
                            messaggio="La petizione è stata inviata correttamente"
                            btnConferma="Torna alle petizioni"
                            btnAnnulla=""
                            mode="success"
                            azioneAnnulla={() => { }}
                            azioneConferma={() => { navigate(-1) }}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea la tua petizione</h1>
                        <Card className="me-5 mt-2 ms-5 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header></Card.Header>
                            <Card.Body className="p-5 text-center">
                                <h2>
                                    <FiAlertTriangle className="me-2 text-warning" />
                                    Attenzione
                                </h2>
                                <br />
                                Per procedere con la creazione di una nuova petizione devi prima effettuare il login!
                                <br /><br />
                                <Button variant="primary" onClick={goToLogin}>
                                    Accedi alla pagina di Login
                                </Button>
                            </Card.Body>
                        </Card>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}