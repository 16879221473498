import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { controlla, rispondi, registra_voti, visualizza } from '../../../../utilities/icone'
import { statiProposta } from "../../../Utilities/statiElementEnum";

export default function AzioniProposte(props) {

    const handleClick = (gestisciProposta) => {
        props.setGestisci("proposta-gestisci")
        props.setGestisciProposta(gestisciProposta)
        props.setProposta(props.proposta)
    }

    return (
        <Container className="d-flex justify-content-around p-0 m-0">
            {props.stato === statiProposta.InValidazione &&
                <OverlayTrigger placement="bottom"
                    overlay={<Tooltip>Controlla</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("controlla") }}
                    >
                        {controlla}
                    </Button>
                </OverlayTrigger>
            }
            {[statiProposta.Pubblicata, statiProposta.Vincitrice].includes(props.stato) &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Rispondi</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("rispondi") }}
                    >
                        {rispondi}
                    </Button>
                </OverlayTrigger>
            }
            {props.stato === statiProposta.Pubblicata &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Registra firme</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("firme") }} >
                        {registra_voti}
                    </Button>
                </OverlayTrigger>
            }
            {props.stato === statiProposta.Rifiutata &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Visualizza</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("rispondi") }} >
                        {visualizza}
                    </Button>
                </OverlayTrigger>
            }
        </Container >
    )
}