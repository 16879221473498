import { useState, useEffect } from "react";
import { Form, Row, Col, Card, InputGroup, Container, Button } from "react-bootstrap";
import Categorie from "../Utilities/Categorie";
import ImpostazioniComuneService from "../../../services/ImpostazioniComuneService";
import ModalConferma from "../../Utilities/ModalConferma";

export default function ImpostazioniPetizioni(props) {
  let [categorie, setCategorie] = useState([])
  let [soglia, setSoglia] = useState(0)
  let [ritiraFirme, setRitiraFirme] = useState(false)
  let [petizioniAnonime, setPetizioniAnonime] = useState(false)

  // Stati per le finestre modali
  const [mostraModaleConferma, setMostraModaleConferma] = useState(false)
  const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
  const [mostraModaleErrore, setMostraModaleErrore] = useState(false)

  const salvaImpostazioni = () => {
    let impostazioni = {
      ...props.comune.impostazioniComune,
      imcCategoriePetizioni: { categorie_petizioni: [...categorie] },
      imcSogliaPetizioni: soglia,
      imcFlRitiraFirme: ritiraFirme,
      imcFlPetizioniAnonime: petizioniAnonime,
      comune: props.comune
    }
    ImpostazioniComuneService.aggiornaImpostazioni(impostazioni)
      .then(res => {
        if (res.status === 200) {
          props.setRicaricaComune(true)
          setMostraModaleConferma(false)
          setMostraModaleSuccesso(true)
          props.setMostraTabella(true)

        }
        else if (res.status === 500) {
          setMostraModaleConferma(false)
          setMostraModaleErrore(true)
        }

      }).catch(err => {
        setMostraModaleErrore(false)
      })
  }
  
  useEffect(() => {
    setCategorie((props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategoriePetizioni && props.comune.impostazioniComune.imcCategoriePetizioni.categorie_petizioni) || [])
    setSoglia((props.comune.impostazioniComune && props.comune.impostazioniComune.imcSogliaPetizioni) || 0)
    if (props.comune.impostazioniComune) {
      setRitiraFirme(props.comune.impostazioniComune.imcFlRitiraFirme)
      setPetizioniAnonime(props.comune.impostazioniComune.imcFlPetizioniAnonime)
    }
  }, [props.petizioni, props.comune])

  return (
    <>
      <Form>
        <Row className="d-flex">
          <Col md={6}>
            <Form.Group as={Row} className="mb-4" controlId="formSoglia">
              <Form.Label column sm="6">
                Soglia numero di firme
              </Form.Label>
              <Col sm="6">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={soglia}
                    type="number"
                    className="text-center"
                    onChange={(ev) => { setSoglia(ev.target.value) }}
                  />
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formRitiraFirme" >
              <Form.Label column sm="6">
                Permetti ai partecipanti di ritirare le firme
              </Form.Label>
              <Col sm="6" className="d-flex justify-content-between">

                <Form.Check
                  inline
                  label="Si"
                  name="ritiraFirme"
                  type="radio"
                  checked={ritiraFirme}
                  onChange={() => setRitiraFirme(old => !old)}
                />
                <Form.Check
                  inline
                  label="No"
                  name="ritiraFirme"
                  type="radio"
                  checked={!ritiraFirme}
                  onChange={() => setRitiraFirme(old => !old)}
                />

              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formPetAnonime">
              <Form.Label column sm="6">
                Permetti petizioni anonime
              </Form.Label>
              <Col sm="6" className="d-flex justify-content-between">

                <Form.Check
                  inline
                  label="Si"
                  name="petAnonime"
                  type="radio"
                  checked={petizioniAnonime}
                  onChange={() => setPetizioniAnonime(old => !old)}
                />
                <Form.Check
                  inline
                  label="No"
                  name="petAnonime"
                  type="radio"
                  checked={!petizioniAnonime}
                  onChange={() => setPetizioniAnonime(old => !old)}
                />

              </Col>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Card className="h-100 p-3 pt-0">
              <Form.Group as={Col} className="mb-4 h-100">
                <Form.Label column sm="6" className="pb-0">
                  <p className="mb-2">Categorie</p>
                </Form.Label>
                <Categorie categorie={categorie} setCategorie={setCategorie} />
              </Form.Group>
            </Card>
          </Col>
        </Row >
      </Form >
      <Container className="d-flex justify-content-end w-90 p-0 pt-5 mb-5">
        <Button className="shadow" onClick={() => { setMostraModaleConferma(true) }}>Aggiorna</Button>
      </Container>

      <ModalConferma
        mostra={mostraModaleConferma}
        setMostra={setMostraModaleConferma}
        mode="continua"
        titolo="Salvataggio impostazioni"
        messaggio="Confermi il salvataggio delle impostazioni?"
        bodyAlign="text-center"
        btnConferma="Si, salva"
        btnAnnulla="No, annulla"
        azioneAnnulla={() => { setMostraModaleConferma(false) }}
        azioneConferma={salvaImpostazioni}
      />

      <ModalConferma
        mostra={mostraModaleSuccesso}
        setMostra={setMostraModaleSuccesso}
        mode="success"
        titolo="Salvataggio impostazioni"
        messaggio="Impostazioni aggiornate con successo"
        bodyAlign="text-center"
        btnConferma="Chiudi"
        azioneConferma={() => { setMostraModaleSuccesso(false) }}
      />

      <ModalConferma
        mostra={mostraModaleErrore}
        setMostra={setMostraModaleErrore}
        mode="error"
        titolo="Salvataggio impostazioni"
        messaggio="Si è verificato un errore durante l'aggiornamento delle impostazioni"
        bodyAlign="text-center"
        btnConferma="Chiudi"
        azioneConferma={() => { setMostraModaleErrore(false) }}
      />
    </>
  )
}