import { useEffect, useState } from "react"
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService"
import { useLocation, } from 'react-router-dom';
import { Tabs, Tab, Container, Row, Col, Button } from "react-bootstrap";
import InformazioniBilancio from "./InformazioniBilancio";
import EsploraProposte from "./EsploraProposte";
import { Navigate } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom';

export default function PaginaBilancio(props) {
    const [bilancio, setBilancio] = useState({})
    const [loadingBil, setLoadingBil] = useState(true)
    const location = useLocation();
    const navigate = useNavigate();

    const cambiaTab = () => {
        props.setActiveTab(old => old === "informazioni" ? "proposte" : "informazioni")
    }


    //fetch petizione
    useEffect(() => {
        if (location.state && location.state.bilId) {
            BilancioPartecipativoService.getBilancio(location.state.bilId)
                .then((res) => {
                    setBilancio(res.data)
                })
                .catch((err) => {
                    setBilancio({})
                    //TODO gestione dell'errore
                })
                .finally(() => { setLoadingBil(false) })

            if (location.state.activeTab === "proposte") {
                props.setActiveTab("proposte")
            }
            if (location.state.activeTab === "info") {
                props.setActiveTab("informazioni")
            }
        }
        // eslint-disable-next-line
    }, [location.state, location.state.activeTab])

    if (props.comune && props.comune.impostazioniComune) {
        if (
            props.comune.impostazioniComune.imcModuloBilanciAttivo &&
            location.state &&
            location.state.bilId
        ) {
            return (
                <>
                    <div className="bg-secondary w-100" style={{ minHeight: "30vh", position: "absolute", zIndex: "-1" }} />
                    <Container className="mt-4 mb-4">
                        <span className="smallcaps dark">{props.comune.impostazioniComune.imcLabelModuloBilanci}</span>
                        <h1 className="title dark">{bilancio.bilTitolo}</h1>
                    </Container>
                    <Container >
                        <Tabs activeKey={props.activeTab} className="bg-light" style={{ borderRadius: "10px 10px 0px 0px" }}
                            onClick={cambiaTab}>
                            <Tab eventKey="informazioni" title="Informazioni">
                                <InformazioniBilancio
                                    bilancio={bilancio}
                                    activeTab={props.activeTab}
                                    cambiaTab={cambiaTab}
                                    partecipante={props.partecipante}
                                    loadingBil={loadingBil}
                                />
                            </Tab>
                            <Tab eventKey="proposte" title="Vedi tutte le proposte">
                                <EsploraProposte
                                    bilancio={bilancio}
                                    comune={props.comune}
                                    partecipante={props.partecipante}
                                    loadingBil={loadingBil}
                                />
                            </Tab>
                        </Tabs>
                        <Row>
                            <Col xs={6} className='pr-0'>
                                <Button variant="outline-dark"
                                    onClick={() => {
                                        navigate(-1)
                                    }}>
                                    <FiChevronLeft />
                                    Torna a tutti gli elementi
                                </Button>
                            </Col>
                            <Col xs={6} className="p-0 d-flex ">
                                {props.activeTab === "informazioni" &&
                                    <Button variant="outline-dark  mb-4"
                                        onClick={() => {
                                            cambiaTab()
                                        }}>
                                        Vedi tutte le proposte
                                        <FiChevronRight />
                                    </Button>}
                            </Col>
                        </Row>
                    </Container>
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}