import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { controlla, proposte } from '../../../utilities/icone'

export default function AzioniTabella(props) {

    const handleClick = (gestisci) => {
        props.setMostraTabella(false)
        props.setBilId(props.bilId)
        props.setGestisci(gestisci)
    }

    return (
        <Container className="d-flex justify-content-around p-0 m-0">
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Controlla</Tooltip>}
            >
                <Button
                    variant="noBackground"
                    onClick={() => { handleClick("controlla") }}
                >
                    {controlla}
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Proposte</Tooltip>}
            >
                <Button
                    variant="noBackground"
                    onClick={() => { handleClick("proposte-elenco") }}
                >
                    {proposte}
                </Button>
            </OverlayTrigger>
        </Container >
    )
}