import { Button, Row, Col } from "react-bootstrap";
import { FiChevronLeft } from 'react-icons/fi'

export default function AzioniPetizione(props) {

    return (
        <>
            <Row className="mt-3 mb-5">
                <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 1 }}>
                    <Button variant="outline-dark" className="w-100 my-2 my-md-1 shadow" onClick={props.redirectToList}>
                        <FiChevronLeft />
                        Indietro
                    </Button>
                </Col>

                {props.gestisci === "controlla" &&
                    <>
                        <Col xs={{ span: 12, order: 2 }} md={{ span: 2, offset: 6, order: 2 }}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                                variant="outline-secondary"
                                onClick={props.preparaRifiuta}
                            >
                                Rifiuta
                            </Button>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 3 }}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                                variant="primary"
                                onClick={props.preparaSalva}
                            >
                                Salva e pubblica
                            </Button>
                        </Col>
                    </>
                }

                {props.gestisci === "rispondi"
                    &&
                    <>
                        <Col xs={{ span: 12, order: 2 }} md={{ span: 2, offset: 6, order: 2 }}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                                variant="outline-secondary"
                                onClick={() => { props.preparaSalva("rispondi") }}
                            >
                                Rispondi
                            </Button>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 3 }}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                                variant="primary"
                                onClick={() => { props.preparaSalva("chiudi") }}
                            >
                                Chiudi la petizione
                            </Button>
                        </Col>
                    </>
                }

                {props.gestisci === "visualizza"
                    &&
                    <>
                       
                    </>
                }

                {props.gestisci === "firme" &&
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 2, offset: 8, order: 2 }}>
                        <Button className="w-100 my-2 my-md-1 shadow"
                            variant="primary"
                            onClick={props.preparaRegistraFirme}
                        >
                            Registra firme
                        </Button>
                    </Col>
                }
            </Row>
        </>
    )
}