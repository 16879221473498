import { Card, Col, Row } from 'react-bootstrap'


export default function CartaSommarioSegnalazione(props) {
    return (
        <>
            <Card className="cartaSommarioBorder me-3 "
                border="primary"
                style={{ minHeight: "30vh", minWidth: "30vh", height:"300px" }} >
                <Card.Body className='d-flex flex-column justify-content-start'>

                    <Row className='align-items-center'>
                        <Col className="pe-0 text-center">
                            <h5 ><strong>{props.titolo}</strong></h5>
                        </Col>
                        <Col>
                            <Card.Img className="m-auto mt-2" variant="top" src={props.img} />
                        </Col>
                    </Row>
                    <p>{props.descrizione}</p>
                </Card.Body>
            </Card >
        </>
    )
}