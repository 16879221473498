import { Card, Row, Col, Form, Button } from "react-bootstrap"
import { GoTrash } from "react-icons/go"

export default function Fase(props) {

    const getValidationStyle = (field, index) => {
        if (props.validato) {
            if (
                props.errors.errorsFasi !== undefined &&
                props.errors.errorsFasi[index] !== undefined &&
                props.errors.errorsFasi[index][field] !== undefined
            )
                return "is-invalid"
            else
                return "is-valid"
        }

        return ""
    }

    const getValidationFeedback = (field, index) => {
        if (
            props.errors.errorsFasi !== undefined &&
            props.errors.errorsFasi[index] !== undefined &&
            props.errors.errorsFasi[index][field] !== undefined
        )
            return props.errors.errorsFasi[index][field]
        else
            return ""
    }

    return (
        <>
            <Card className="px-md-2 my-3 cm-rounded-1_5">
                <Card.Body>
                    <Card.Title className="underline mb-3">Fase {props.fase.finiOrdine}</Card.Title>
                    <Button variant="danger" className="btn-sm delete-fase" onClick={() => {props.handleDeleteFase(props.fase)}}>
                        <GoTrash size="1.5em" style={{"color":"#fff"}}/>
                    </Button>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Col md={2}>
                                    <Form.Label className="small mt-1">
                                        <strong>Titolo: </strong>
                                        {props.mandatoryField()}
                                    </Form.Label>
                                </Col>
                                <Col md={10}>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={props.fase.finiTitolo}
                                        onChange={(ev) => {
                                            props.setFaseField('finiTitolo', { ...props.fase, finiTitolo: ev.target.value }, props.index)
                                        }}
                                        className={getValidationStyle("finiTitolo", props.index)}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        <strong>{getValidationFeedback("finiTitolo", props.index)}</strong>
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label className="small">
                                <strong>Descrizione: </strong>
                                {props.mandatoryField()}
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                size="sm"
                                value={props.fase.finiDescrizione}
                                onChange={(ev) => {
                                    props.setFaseField('finiDescrizione', { ...props.fase, finiDescrizione: ev.target.value }, props.index)
                                }}
                                className={"no-resize " + getValidationStyle("finiDescrizione", props.index)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                <strong>{getValidationFeedback("finiDescrizione", props.index)}</strong>
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between mb-4">
                        <Col md={3}>
                            <Form.Label className="small">
                                <strong>Data inizio: </strong>
                                {props.mandatoryField()}
                            </Form.Label>
                            <Form.Control
                                type="date"
                                size="sm"
                                value={props.fase.finiDataInizio}
                                onChange={(ev) => {
                                    props.setFaseField('finiDataInizio', { ...props.fase, finiDataInizio: ev.target.value }, props.index)
                                }}
                                className={getValidationStyle("finiDataInizio", props.index)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                <strong>{getValidationFeedback("finiDataInizio", props.index)}</strong>
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={3}>
                            <Form.Label className="small">
                                <strong>Data fine: </strong>
                                {props.mandatoryField()}
                            </Form.Label>
                            <Form.Control
                                type="date"
                                size="sm"
                                value={props.fase.finiDataFine}
                                onChange={(ev) => {
                                    props.setFaseField('finiDataFine', { ...props.fase, finiDataFine: ev.target.value }, props.index)
                                }}
                                className={getValidationStyle("finiDataFine", props.index)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                <strong>{getValidationFeedback("finiDataFine", props.index)}</strong>
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between">
                        <Col>
                            <Form.Group as={Row}>
                                <Col>
                                    <Form.Label className="small">
                                        <strong>Permetti ai partecipanti di inviare proposte: </strong>
                                    </Form.Label>
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="small">No</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="invio-proposte"
                                        className="inline mx-2"
                                        checked={props.fase.finiFlInvioProposte}
                                        onChange={(ev) => {
                                            props.setFaseField('finiFlInvioProposte', { ...props.fase, finiFlInvioProposte: ev.target.checked }, props.index)
                                        }}
                                    />
                                    <Form.Label className="small">Sì</Form.Label>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}