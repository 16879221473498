import Modal from 'react-bootstrap/Modal';
import { Figure } from "react-bootstrap";

export default function ModalePreview(props) {
    const handleClose = () => {
        props.setMostraPreview(false)
    }

    return (
        <Modal
            show={props.mostraPreview}
            centered
            size="lg"
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Allegato {props.numeroAllegato} per la segnalazione "{props.oggettoSegnalazione}"</Modal.Title>
            </Modal.Header>

            <Modal.Body className='text-center' >
                <Figure>
                    <Figure.Image
                        src={`data:image/png;base64,${props.figuraPreview}`} />
                </Figure>
            </Modal.Body>

        </Modal>
    )
}