import { Card, Row, Col, ProgressBar, Button, Badge } from "react-bootstrap";
import { BsPersonCircle } from 'react-icons/bs'
import { FiChevronRight as Scopri } from 'react-icons/fi'
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getReferendumColor } from "../../Utilities/getBorderColor";
import { useState } from "react";
import ModalPermessi from "../../Utilities/ModalPermessi";
import NumberFormat from "react-number-format"
import { statiReferendum } from "../../Utilities/statiElementEnum";

export default function CartaReferendum(props) {

    const navigate = useNavigate();

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    //stati

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    //percentuale di completamento da mostrare sulla progress bar
    const getProgressBarValue = () => {
        if (props.referendum.firmeReferendum) {
            return (props.referendum.firmeReferendum.length + props.referendum.refFirmeOffline) * 100 / mostraSoglia()
        }
    }

    //funzione che sceglie la giusta soglia da mostrare
    const mostraSoglia = () => {
        if (props.referendum.soglieReferendum && props.referendum.soglieReferendum.length === 0) {
            //il referendum non ha superato alcuna soglia, quindi scrivo la prima
            return props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.soglie[0].sogValore
        } else {
            //il referendum ha superato alcune soglie, quindi prendo la prossima, se esiste
            let ultimaSoglia = props.referendum.soglieReferendum[props.referendum.soglieReferendum.length - 1].soglia
            let ordineUltimaSoglia = ultimaSoglia.sogOrdine
            let prossimaSoglia = props.comune.impostazioniComune.soglie
                .filter(s => s.sogOrdine === ordineUltimaSoglia + 1)
            return prossimaSoglia.length === 0 ? ultimaSoglia.sogValore : prossimaSoglia[0].sogValore
        }
    }

    const IsLogged = () => {
        // 1. no permesso visua elemento
        // 2. non loggato
        // 3. con permessi
        if (
            (
                props.partecipante &&
                props.partecipante.ruolo &&
                props.partecipante.ruolo.ruoFlVisualizzatore
            ) ||
            !(
                props.partecipante &&
                props.partecipante.parId
            )
        ){
            //caso 3
            navigate('/referendum', { state: { refId: props.referendum.refId } })
        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare il referendum. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    const permettiFirma = () => {
        let inizioFirme = dayjs(props.referendum.refInizioRaccoltaFirme)
        let fineFirme = dayjs(props.referendum.refFineRaccoltaFirme)
        return dayjs().isBetween(inizioFirme, fineFirme, null, [])
    }

    return (
        <Card className="cartaSommario w-100 mt-4 mb-2"
            border={getReferendumColor(props.referendum)}
        >
            <Card.Body>
                <h5><strong>{props.referendum.refTitolo}</strong></h5>
                <p>
                    <BsPersonCircle className="me-2 m-auto" />
                    {props.referendum.partecipante && props.referendum.partecipante.datiPartecipante &&
                        props.referendum.partecipante.datiPartecipante.dparNome} {' '}
                    {props.referendum.partecipante && props.referendum.partecipante.datiPartecipante &&
                        props.referendum.partecipante.datiPartecipante.dparCognome} {' '}
                    {props.referendum.refAssociazione ? `per ${props.referendum.refAssociazione}` : ""}
                </p>
                <Row className="w-100 m-0 mt-2 p-0">
                    {props.referendum.refStato !== statiReferendum.Rifiutato ?
                        <>
                            <Col xs={6} md={3}>
                                <strong>Pubblicato il:</strong>
                                <p>{dayjs(props.referendum.refDataPubblicazione).format("DD/MM/YYYY")}</p>
                            </Col>
                            <Col xs={6} md={3}>
                                <strong>Termine:</strong>
                                <p>{dayjs(props.referendum.refFineRaccoltaFirme).format("DD/MM/YYYY")}</p>
                            </Col>
                        </>
                        :
                        <Col xs={12} md={6}>
                        </Col>
                    }
                    <Col xs={6} md={3}>
                        <strong>Tipo:</strong>
                        <p>{props.referendum.refTipo}</p>
                    </Col>

                    <Col xs={6} md={3} className="d-flex align-self-center">
                        <Badge pill
                            bg={getReferendumColor(props.referendum)}
                            className="mt-auto mx-md-auto py-2 "
                            style={{ fontWeight: '100', fontSize: '0.80em' }}>
                            {props.referendum.refStato}
                        </Badge>
                    </Col>
                </Row>
            </Card.Body>

            {props.referendum.refStato !== statiReferendum.Rifiutato &&
                <Card.Footer className='text-center px-0 pb-3'>
                    <Row className='w-100 p-0 m-0 d-flex'>
                        <Col xs={12} md={9} className='pr-0'>
                            <span >
                                <strong className="bigText">
                                    <NumberFormat
                                        displayType="text"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        value={props.referendum.firmeReferendum && props.referendum.firmeReferendum.length + props.referendum.refFirmeOffline}
                                    />
                                </strong>/
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={mostraSoglia()}
                                />
                            </span>
                            <ProgressBar variant="progressBar" now={getProgressBarValue()} />
                        </Col>


                        <Col xs={12} md={3} className="p-0 d-flex align-items-baseline mt-2">
                            <Button variant="outline-primary" size="sm" className="mt-auto mx-auto"
                                onClick={IsLogged}>
                                {props.referendum.refStato !== statiReferendum.Concluso && permettiFirma()
                                    ? "Leggi e firma" : "Scopri"}
                                < Scopri />
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            }
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </Card>
    )
}