import { Button, Card } from 'react-bootstrap'
import { FiChevronRight as Crea } from 'react-icons/fi'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalPermessi from "../../Utilities/ModalPermessi";

export default function CartaCrea(props) {
    const navigate = useNavigate();

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    const goToPath = (path) => {
        navigate(path)
    }

    const canPublish = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId && props.partecipante.ruolo) {
            //caso 3
            if (props.partecipante.ruolo.ruoFlPubblicatorePetizioni && props.mode === "petizione")
                goToPath("/crea-petizione")
            if (props.partecipante.ruolo.ruoFlPubblicatoreReferendum && props.mode === "referendum")
                goToPath("/crea-referendum")
            if (props.partecipante.ruolo.ruoFlPubblicatoreSegnalazioni && props.mode === "segnalazione")
                goToPath("/crea-segnalazione")
            else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio(`Attualmente non possiedi i permessi per creare 
                ${props.mode === "petizione" ? "una petizione." : ""}
                ${props.mode === "referendum" ? "un referendum." : ""}
                ${props.mode === "segnalazione" ? "una segnalazione." : ""}
                L'operazione è bloccata!`)
                setMode("danger")
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio(`Per procedere con la creazione di 
            ${props.mode === "petizione" ? "una nuova petizione" : ""}
            ${props.mode === "referendum" ? "un nuovo referendum" : ""}
            ${props.mode === "segnalazione" ? "una nuova segnalazione" : ""}
            devi prima effettuare il login!`)
            setMode("warning")
        }
        setMostraModalPermessi(true)
    }

    return (
        <div>

        <Card className="text-center bg-secondary"
            style={{ minHeight: "30vh", minWidth: "30vh", height:"300px" }}>
            <Card.Body className='text-center mb-3'>
                {props.mode === "petizione" && <h4 className=' text-light my-4'>Crea adesso la tua <strong>petizione</strong>!</h4>}
                {props.mode === "referendum" && <h4 className='text-light my-4'>Crea adesso il tuo <strong>referendum</strong>!</h4>}
                {props.mode === "segnalazione" && <h4 className='text-light my-4'>Crea adesso la tua <strong>segnalazione</strong>!</h4>}

                <span className='text-light italic d-sm-none' style={{ fontSize: "0.9em" }}>
                    Accedi alla piattaforma tramite SPID e compila i moduli necessari, è facile!
                </span >
            </Card.Body>
            <Card.Footer className='text-center px-0 pb-3'>
                <Button variant="primary" className={props.mode === "referendum" ? "px-1" : ""}
                    onClick={canPublish}>
                    {props.mode === "petizione" && "Crea la tua petizione"}
                    {props.mode === "referendum" && "Crea il tuo referendum"}
                    {props.mode === "segnalazione" && "Crea la tua segnalazione"}
                    <Crea />
                </Button>
            </Card.Footer>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
                />
        </Card>
                </div>
    )
}