import { statiPetizione } from "../../Utilities/statiElementEnum";
import { useState, useEffect } from "react";
import { Row, Col, Form, Container, InputGroup, ProgressBar, Alert } from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import AzioniPetizione from "./AzioniPetizione";
import PetizioniService from "../../../services/PetizioniService"
import ModalConferma from "../../Utilities/ModalConferma";
import { getStatusPetizione } from "../../Utilities/getStatus";

const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat')


export default function GestisciPetizione(props) {

    // Valore che permette l'inizializzazione una volta sola al caricamento della pagina
    let [init, setInit] = useState(true)

    // Estrazione della petizione da visualizzare nella pagina dettaglio
    let petizione = props.petizioni.filter(el => el.petId === props.petId)[0]

    // Valore che abilita o disabilita la possibilità di modificare i campi Titolo, Descrizione, Categoria, InizioRaccoltaFirme, FineRaccoltaFirme,
    // a seconda dell'azione di modifica della petizione (Validazione (abilitata) vs Risposta (disabilitata))
    let [readOnly1, setReadOnly1] = useState(false)

    // Elenco categorie
    let [categorie, setCategorie] = useState([])
    let [categorieList, setCategorieList] = useState([])

    // Stati per gestire i campi modificabili della petizione
    let [petTitolo, setPetTitolo] = useState(petizione.petTitolo)
    let [petCategoria, setPetCategoria] = useState(petizione.petCategoria)
    let [petDescrizione, setPetDescrizione] = useState(petizione.petDescrizione)
    let [petInizioRaccoltaFirme, setPetInizioRaccoltaFirme] = useState(dayjs().format('YYYY-MM-DD'))
    let [petFineRaccoltaFirme, setPetFineRaccoltaFirme] = useState(dayjs().add(2, 'M').format('YYYY-MM-DD'))
    let [risposta, setRisposta] = useState(petizione.petRisposta)

    // Stato che gestisce firme da registrare
    let [firme, setFirme] = useState(0)

    let [showAlert, setShowAlert] = useState({ message: "", variant: "" })

    // Stati per le finestre modali
    const [mostraModaleOperazione, setMostraModaleOperazione] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)
    const [parolaChiave, setParolaChiave] = useState("")
    const [titolo, setTitolo] = useState("")
    const [messaggio, setMessaggio] = useState("")

    dayjs.extend(customParseFormat)

    const initCategorie = () => {
        setCategorie(props.comune.impostazioniComune.imcCategoriePetizioni.categorie_petizioni)
    }

    const initCategorieList = () => {
        setCategorieList(props.comune.impostazioniComune.imcCategoriePetizioni.categorie_petizioni &&
            props.comune.impostazioniComune.imcCategoriePetizioni.categorie_petizioni.map((el, i) => {
                return { "name": el, "id": el }
            }))
    }

    let selectPetCategoria = (petCategoria) => {
        setPetCategoria(petCategoria[0].id)
    }

    const getFirmeProgressBarValue = () => {
        if (
            petizione.firmePetizione &&
            props.comune &&
            props.comune.impostazioniComune
        )
            return (petizione.firmePetizione.length + petizione.petFirmeOffline) * 100 / props.comune.impostazioniComune.imcSogliaPetizioni
    }

    const redirectToList = () => {
        props.setMostraTabella(true)
    }

    const mandatoryField = () => {
        if (props.gestisci === "controlla")
            return <span>*</span>

        return ""
    }

    const impostaSelectedValues = () => {
        let selectedValues = [categorieList.filter(el => el.id === petCategoria)[0]]
        if (selectedValues[0]) {
            return selectedValues
        }
        else {
            return [{ name: "---", id: "---" }]
        }
    }

    const checkPetizione = () => {
        let ret = true

        if (props.gestisci === "controlla") {
            if (
                !petTitolo.length ||
                !petDescrizione.length ||
                !petInizioRaccoltaFirme ||
                !petFineRaccoltaFirme ||
                !categorieList.filter(el => el.id === petCategoria).length === 0
            ) {
                setShowAlert({ message: "Compila tutti i campi obbligatori", variant: "danger" })
                ret = false
            }

            if (ret)
                if (!checkDateRaccoltaFirme()) {
                    setShowAlert({ message: "Controllare i valori delle date inserite", variant: "danger" })
                    ret = false
                }
        }

        if (props.gestisci === "rispondi") {
            if (!risposta || risposta.length === 0) {
                setShowAlert({ message: "Inserisci la risposta alla petizione!", variant: "danger" })
                ret = false
            }
        }

        return ret
    }

    const checkDateRaccoltaFirme = () => {
        if (
            dayjs(petInizioRaccoltaFirme, 'YYYY-MM-DD', true).isValid() === false ||
            dayjs(petFineRaccoltaFirme, 'YYYY-MM-DD', true).isValid() === false
        )
            return false

        let date1 = new Date(petInizioRaccoltaFirme)
        let date2 = new Date(petFineRaccoltaFirme)
        let diffInDays = (date2 - date1) / (1000 * 60 * 60 * 24)

        if (diffInDays < 1)
            return false

        return true
    }

    //PUBBLICAZIONE PETIZIONE OR PUBBLICAZIONE RISPOSTA DELLA PETIZIONE
    const preparaSalva = (azione) => {
        if (checkPetizione()) {
            setMostraModaleOperazione(true)
            if (props.gestisci === "controlla") {
                setParolaChiave("Pubblica")
                setTitolo("Pubblica la petizione")
                setMessaggio("Sei sicuro di voler pubblicare la petizione? L'operazione è irreversibile!")
            }
            if (props.gestisci === "rispondi") {
                if (azione === "rispondi") {
                    setParolaChiave("rispondi")
                    setTitolo("Rispondi alla petizione")
                    setMessaggio("Confermi la risposta alla petizione?")
                }
                if (azione === "chiudi") {
                    setParolaChiave("chiudi")
                    setTitolo("Chiudi la petizione")
                    setMessaggio("Sei sicuro di voler chiudere la petizione? L'operazione è irreversibile!")
                }
            }
        }
    }

    const salvaPetizione = (azione) => {
        let temp
        if (props.gestisci === "controlla") {
            temp = {
                ...petizione,
                petTitolo: petTitolo,
                petCategoria: petCategoria,
                petDescrizione: petDescrizione,
                petInizioRaccoltaFirme: petInizioRaccoltaFirme,
                petFineRaccoltaFirme: petFineRaccoltaFirme,
                petDataPubblicazione: dayjs().format('YYYY-MM-DD'),
                petStato: statiPetizione.InCorso
            }
        }

        if (props.gestisci === "rispondi") {
            if (azione === "rispondi") {
                temp = {
                    ...petizione,
                    petRisposta: risposta,
                    petDataRisposta: dayjs().format('YYYY-MM-DD'),
                }
            }
            if (azione === "chiudi") {
                temp = {
                    ...petizione,
                    petStato: statiPetizione.Conclusa
                }
            }
        }

        PetizioniService.savePetizione(temp)
            .then((res) => {
                if (res.status === 200) {
                    setMostraModaleOperazione(false)
                    setMostraModaleSuccesso(true)
                    if (props.gestisci === "controlla") {
                        setParolaChiave("Pubblicazione")
                        setTitolo("Pubblicazione della petizione")
                        setMessaggio("Pubblicazione della petizione avvenuto con successo.")
                    } else if (props.gestisci === "rispondi") {
                        if (azione === "rispondi") {
                            setParolaChiave("Pubblicazione risposta")
                            setTitolo("Pubblicazione della risposta alla petizione")
                            setMessaggio("Pubblicazione della risposta alla petizione avvenuto con successo.")
                        }
                        if (azione === "chiudi") {
                            setParolaChiave("Chiusura petizione")
                            setTitolo("Chiusura della petizione")
                            setMessaggio("Chiusura della petizione avvenuto con successo.")
                        }
                    }
                }
                else if (res.status === 500) {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    if (props.gestisci === "controlla") {
                        setParolaChiave("pubblicazione")
                        setTitolo("Errore nella pubblicazione della petizione")
                        setMessaggio("Si è verificato un errore durante la pubblicazione della petizione.")
                    }
                    else if (props.gestisci === "rispondi") {
                        setParolaChiave("pubblicazione risposta")
                        setTitolo("Errore nella pubblicazione della risposta alla petizione")
                        setMessaggio("Si è verificato un errore durante la pubblicazione della risposta alla petizione.")
                    }
                }
            }).catch(err => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                if (props.gestisci === "controlla") {
                    setParolaChiave("pubblicazione")
                    setTitolo("Errore nella pubblicazione della petizione")
                    setMessaggio("Si è verificato un errore durante la pubblicazione della petizione.")
                }
                else if (props.gestisci === "rispondi") {
                    setParolaChiave("pubblicazione risposta")
                    setTitolo("Errore nella pubblicazione della risposta alla petizione")
                    setMessaggio("Si è verificato un errore durante la pubblicazione della risposta alla petizione.")
                }
            })
    }

    // RIFIUTO PETIZIONE
    const preparaRifiuta = () => {
        setMostraModaleOperazione(true)
        setParolaChiave("Rifiuta")
        setTitolo("Rifiuta la petizione")
        setMessaggio("Sei sicuro di voler rifiutare la petizione? L'operazione è irreversibile!")
    }

    const rifiutaPetizione = () => {
        let temp = { ...petizione, petStato: statiPetizione.Rifiutata }
        PetizioniService.savePetizione(temp)
            .then(res => {
                if (res.status === 200) {
                    setMostraModaleOperazione(false)
                    setMostraModaleSuccesso(true)
                    setParolaChiave("Rifiuto")
                    setTitolo("Rifiuto della petizione")
                    setMessaggio("Rifiuto della petizione avvenuto con successo.")
                }
                else if (res.status === 500) {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    setParolaChiave("rifiuto")
                    setTitolo("Errore nel rifiuto della petizione")
                    setMessaggio("Si è verificato un errore durante il rifiuto della petizione.")
                }
            })
            .catch(err => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                setParolaChiave("rifiuto")
                setTitolo("Errore nel rifiuto della petizione")
                setMessaggio("Si è verificato un errore durante il rifiuto della petizione.")
            })
    }

    // REGISTRAZIONE FIRME
    const preparaRegistraFirme = () => {
        if (firme < 1)
            setShowAlert({ message: "Valore firme offline inserito non valido!", variant: "danger" })
        else {
            setMostraModaleOperazione(true)
            setParolaChiave("Registra firme per")
            setTitolo("Registra firme per la petizione")
            setMessaggio("Sei sicuro di voler registrare le firme per la petizione? L'operazione è irreversibile!")
        }
    }

    const registraFirme = () => {
        let temp = {
            ...petizione,
            petFirmeOffline: petizione.petFirmeOffline + parseInt(firme)
        }

        PetizioniService.savePetizione(temp)
            .then(response => {
                if (response.status === 200) {
                    setMostraModaleOperazione(false)
                    setMostraModaleSuccesso(true)
                    setParolaChiave("Registrazione firme per")
                    setTitolo("Registrazione firme della petizione")
                    setMessaggio("Registrazione firme della petizione avvenuto con successo.")
                } else if (response.status === 500) {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    setParolaChiave("registrazione firme per")
                    setTitolo("Errore nella registrazione delle firme della petizione")
                    setMessaggio("Si è verificato un errore durante la registrazione delle firme della petizione.")
                }
            })
            .catch(err => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                setParolaChiave("registrazione firme per")
                setTitolo("Errore nella registrazione delle firme della petizione")
                setMessaggio("Si è verificato un errore durante la registrazione delle firme della petizione.")
            })
    }

    useEffect(() => {
        setTimeout(() => {
            setShowAlert({});
        }, 3000);
    }, [showAlert])

    useEffect(() => {
        if (init) {
            if (props.gestisci === "controlla") {
                initCategorie()
                initCategorieList()
            }

            if (props.gestisci !== "controlla")
                setReadOnly1(true)
            setInit(false)
        }
        // eslint-disable-next-line
    }, [props.gestisci, init])

    return (
        <>
            <div className="container-sm">
                {props.gestisci === "controlla" && <h2 className="title2">Controlla la petizione</h2>}
                {props.gestisci === "rispondi" && <h2 className="title2">Rispondi alla petizione</h2>}
                {props.gestisci === "firme" && <h2 className="title2">Registra firme raccolte offline</h2>}

                {
                    props.gestisci === "controlla" &&
                    <Row>
                        <Col className="mb-4 italic required-alert">I campi contrassegnati da un asterisco (*) sono obbligatori</Col>
                    </Row>
                }

                <Row>
                    <Col md={petizione.petAssociazione !== undefined ? 5 : 10}>
                        <p>
                            <strong>Autore: </strong>
                            {petizione.partecipante.datiPartecipante.dparNome} {petizione.partecipante.datiPartecipante.dparCognome}
                        </p>
                    </Col>
                    {
                        petizione.petAssociazione !== undefined &&
                        <Col md={5}>
                            <p>
                                <strong>Associazione: </strong>
                                {petizione.petAssociazione}
                            </p>
                        </Col>
                    }
                    <Col className="text-md-right" md={2}>
                        <p><strong>Inviata il: </strong> {dayjs(petizione.petDataInvio).format('DD/MM/YYYY')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Titolo: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={petTitolo}
                                    onChange={(ev) => setPetTitolo(ev.target.value)}
                                    readOnly={readOnly1}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={4}>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Categoria: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                {
                                    props.gestisci === "controlla" &&
                                        categorie.length > 0 ? (
                                        <Multiselect
                                            displayValue="name"
                                            className="multiselect-style1"
                                            onSelect={selectPetCategoria}
                                            selectedValues={impostaSelectedValues()}
                                            options={categorieList}
                                            singleSelect
                                            avoidHighlightFirstOption
                                            style={{
                                                chips: {
                                                    'fontSize': '1rem',
                                                    'marginBottom': '0'
                                                }
                                            }}
                                        />
                                    ) : (
                                        <Form.Control
                                            type="text"
                                            value={petCategoria}
                                            readOnly
                                        />
                                    )
                                }
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Descrizione: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    value={petDescrizione}
                                    onChange={(ev) => setPetDescrizione(ev.target.value)}
                                    readOnly={readOnly1}
                                    className="no-resize"
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Inizio Raccolta Firme: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={petizione.petInizioRaccoltaFirme || petInizioRaccoltaFirme}
                                    onChange={(ev) => setPetInizioRaccoltaFirme(ev.target.value)}
                                    readOnly={readOnly1}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={4} />
                    <Col md={4}>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Fine Raccolta Firme: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control type="date"
                                    value={petizione.petFineRaccoltaFirme || petFineRaccoltaFirme}
                                    onChange={(ev) => setPetFineRaccoltaFirme(ev.target.value)}
                                    readOnly={readOnly1}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

                {props.gestisci === "rispondi" &&
                    <>
                        {petizione.petStato !== statiPetizione.Rifiutata &&
                                <Row>
                                    <Col>
                                        <h4 className="mt-4"><strong>Avanzamento Firme:</strong></h4>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>
                                                {petizione.firmePetizione.length + petizione.petFirmeOffline} / {props.comune.impostazioniComune.imcSogliaPetizioni}
                                            </Form.Label>
                                            <ProgressBar variant="progressBar" now={getFirmeProgressBarValue()} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                        }
                        {
                        ![statiPetizione.FirmeNonRaggiunte, statiPetizione.Rifiutata].includes(getStatusPetizione(petizione, props.comune)) &&
                            <>
                                <Row>
                                    <Col>
                                        <h4 className="mt-4"><strong>Risposta: </strong></h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={10} placeholder="Scrivi la risposta alla petizione..."
                                                    value={risposta}
                                                    onChange={(ev) => { setRisposta(ev.target.value) }}
                                                    className="no-resize"
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                }

                {props.gestisci === "firme" &&
                    <>
                        <Row className="mt-5 ">
                            <Col md={6}>
                                <h4 ><strong>Firme raccolte offline: </strong></h4>
                            </Col>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        className="text-center"
                                        onChange={(ev) => { setFirme(ev.target.value) }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>Firme presenti finora: (<strong>{petizione.petFirmeOffline}</strong>)</Col>
                        </Row>
                    </>
                }

                <AzioniPetizione
                    gestisci={props.gestisci}
                    petStato={getStatusPetizione(petizione, props.comune)}
                    firme={firme}
                    redirectToList={redirectToList}
                    preparaRifiuta={preparaRifiuta}
                    preparaSalva={preparaSalva}
                    preparaRegistraFirme={preparaRegistraFirme}
                    rifiutaPetizione={rifiutaPetizione}
                    salvaPetizione={salvaPetizione}
                    registraFirme={registraFirme}
                />

            </ div>

            {
                showAlert.message &&
                <Container className="fixed-bottom w-50">
                    <Alert variant={showAlert.variant}>{showAlert.message}</Alert>
                </Container>
            }

            {/* modale di conferma operazione */}
            <ModalConferma
                mostra={mostraModaleOperazione}
                setMostra={setMostraModaleOperazione}
                mode={parolaChiave.toLowerCase() === "rifiuta" ? "danger" : "continua"}
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma={parolaChiave.toLowerCase() === "registra firme per" ?
                    "Si, registra le firme" :
                    `Si, ${parolaChiave.toLowerCase()}`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleOperazione(false) }}
                azioneConferma={() => {
                    if (parolaChiave.toLowerCase() === "rifiuta")
                        rifiutaPetizione()
                    else if (["pubblica", "rispondi", "chiudi"].includes(parolaChiave.toLowerCase()))
                        salvaPetizione(parolaChiave)
                    else
                        registraFirme()
                }}
            />

            {/* modali di esito operazione */}
            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Torna a tutte le petizioni"
                azioneConferma={() => {
                    props.setRicaricaPetizioni(true)
                    props.setMostraTabella(true)
                }}
            />

            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => {
                    setMostraModaleErrore(false)
                }}
            />

        </>
    )
}