import axios from 'axios'

const PROPOSTA_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'proposta'

const headers = { 'Content-Type': 'application/json' }

class PropostaService {

    saveProposta(proposta) {
        return axios.post(PROPOSTA_REST_API_URL + "/save", proposta, headers)
    }

    getProposta(proId) {
        return axios.get(PROPOSTA_REST_API_URL + `/${proId}`)
    }
}

const propostaServiceInstance = new PropostaService()
export default propostaServiceInstance;