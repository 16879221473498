import { Card, Container, Row, Col, ProgressBar, Button, Badge } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import PetizioniService from "../../../services/PetizioniService";
import { firmaPetizione } from "../../../utilities/icone";
import { getPetizioneColor } from "../../Utilities/getBorderColor";
import { BsPersonCircle } from 'react-icons/bs'
import { ImCalendar } from 'react-icons/im'
import { MdOutlineCategory } from 'react-icons/md'
import { GrStatusInfo } from 'react-icons/gr'
import { FaRegDotCircle } from 'react-icons/fa'
import { FiChevronLeft } from 'react-icons/fi'
import { BiEraser } from "react-icons/bi"
import ModalConferma from "../../Utilities/ModalConferma";
import FirmePetizioneService from "../../../services/FirmePetizioneService";
import dayjs from "dayjs";
import ModalPermessi from "../../Utilities/ModalPermessi";
import NumberFormat from "react-number-format"
import ListGroup from 'react-bootstrap/ListGroup';
import { getStatusPetizione } from "../../Utilities/getStatus";
import { Navigate } from 'react-router-dom';
import { statiPetizione } from "../../Utilities/statiElementEnum";

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default function PaginaPetizione(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [petizione, setPetizione] = useState({})
    const [ricaricaPetizione, setRicaricaPetizione] = useState(true)

    // stati per aprire i modali
    const [mostraModaleFirma, setMostraModaleFirma] = useState(false)
    const [mostraModaleRevoca, setMostraModaleRevoca] = useState(false)
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false)
    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);

    const [votoEspresso, setVotoEspresso] = useState(false)

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    //percentuale di completamento da mostrare sulla progress bar
    const getProgressBarValue = () => {
        if (petizione.firmePetizione && props.comune && props.comune.impostazioniComune)
            return (petizione.firmePetizione.length + petizione.petFirmeOffline) * 100 / props.comune.impostazioniComune.imcSogliaPetizioni
    }

    //numero di firme che mancano alla soglia
    const getFirmeMancanti = () => {
        return petizione &&
            props.comune &&
            props.comune.impostazioniComune &&
            petizione.firmePetizione &&
            props.comune.impostazioniComune.imcSogliaPetizioni - (petizione.firmePetizione.length + petizione.petFirmeOffline)
    }

    const handleFirma = () => {
        const firma = {
            fipData: dayjs().format('YYYY-MM-DD'),
            petizione: petizione,
            partecipante: props.partecipante
        }
        FirmePetizioneService.registraFirma(firma)
            .then(() => {
                setRicaricaPetizione(true)
                setMostraModaleConferma(true)
            })
            .catch(() => {
                //TODO: gestione dell'errore
            })
            .finally(() => {
                setMostraModaleFirma(false)
            })
    }

    const handleRevoca = () => {
        const firma = {
            fipId: petizione.firmePetizione.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0].fipId,
            fipData: dayjs(petizione.firmePetizione.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0].data).format('YYYY-MM-DD'),
            partecipante: props.partecipante,
            petizione: petizione
        }
        FirmePetizioneService.revocaFirma(firma)
            .then(() => {
                setRicaricaPetizione(true)
                setVotoEspresso(false)
            })
            .catch((err) => {
                console.log(err)
                //TODO: gestione dell'errore
            })
            .finally(() => {
                setMostraModaleRevoca(false)
            })
    }

    //fetch petizione
    useEffect(() => {
        if (ricaricaPetizione) {
            if (location.state && location.state.petId) {
                PetizioniService.getPetizione(location.state.petId)
                    .then((res) => {
                        setPetizione({...res.data, petStato: getStatusPetizione(res.data, props.comune)})

                        if (res.data.firmePetizione &&
                            res.data.firmePetizione.filter(firma => firma.partecipante.parId === props.partecipante.parId).length > 0) {
                            setVotoEspresso(true)
                        } else {
                            setVotoEspresso(false)
                        }
                    })
                    .catch((err) => {
                        setPetizione({})
                        //TODO gestione dell'errore
                    })
                setRicaricaPetizione(false)
            }
        }
    }, [location.state, ricaricaPetizione, props])

    const IsLogged = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlVotantePetizioni && props.partecipante.ruolo.ruoFlVisualizzatore) {
                //caso 3
                setMostraModaleFirma(true)
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per firmare una petizione. L'operazione è bloccata!")
                setMode("danger")
                setMostraModalPermessi(true)
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la firma di una petizione devi prima effettuare il login!")
            setMode("warning")
            setMostraModalPermessi(true)
        }
    }

    const permettiFirma = () => {
        return dayjs().isBefore(dayjs(petizione.petFineRaccoltaFirme))
    }

    if (props.comune && props.comune.impostazioniComune) {
        if (
            props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
            location.state &&
            location.state.petId
        ) {
            return (
                <>
                    <div className="pt-4 px-md-5 px-2 pb-2 bg-secondary">
                        <Row className="w-100 p-0 m-0">
                            <Col className="p-0 align-self-center">
                                <span className="smallcaps dark">Petizione</span>
                                <h1 className="title dark">
                                    {petizione.petTitolo}
                                </h1>
                                <h5><Badge bg="primary">{petizione.petCategoria}</Badge></h5>
                            </Col>
                            <Col xs={12} sm={5} className="p-0">
                                <Card className="text-center cartaSommario"
                                    border={getPetizioneColor(petizione)}
                                >
                                    <Card.Body>
                                        <h3 className="smallcaps title m-0">{petizione.petStato}</h3>
                                        <Row>
                                            { }
                                            <span>{dayjs(petizione.petInizioRaccoltaFirme).format('DD/MM/YYYY')} - {dayjs(petizione.petFineRaccoltaFirme).format('DD/MM/YYYY')}</span>
                                        </Row>
                                        <Row>
                                            <span className="mt-3">
                                                <strong className="bigText">
                                                    <NumberFormat
                                                        displayType="text"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        value={petizione.firmePetizione && petizione.firmePetizione.length + petizione.petFirmeOffline}
                                                    />
                                                </strong>/
                                                <NumberFormat
                                                    displayType="text"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    value={props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcSogliaPetizioni}
                                                />
                                            </span>
                                        </Row>
                                        <Container className="mb-3">
                                            <ProgressBar variant="progressBar" now={getProgressBarValue()} />
                                        </Container>
                                        {!votoEspresso && petizione.petStato !== statiPetizione.Conclusa &&
                                            <Row>
                                                <div>
                                                    <Button className="w-20"
                                                        onClick={IsLogged}
                                                        disabled={!permettiFirma()}>
                                                        Firma la petizione {firmaPetizione}
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "50vh" }}>
                        <Card.Header />
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={7}>
                                    {!petizione.petFlAnonima ?
                                        <p className="text-center">
                                            <BsPersonCircle className="me-2 m-auto" />
                                            {petizione.partecipante && petizione.partecipante.datiPartecipante &&
                                                petizione.partecipante.datiPartecipante.dparNome} {' '}
                                            {petizione.partecipante && petizione.partecipante.datiPartecipante &&
                                                petizione.partecipante.datiPartecipante.dparCognome} {' '}
                                            {petizione.petAssociazione ? `per ${petizione.petAssociazione}` : ""}
                                        </p>
                                        :
                                        <p className="text-center">
                                            <BsPersonCircle className="me-2 m-auto" />
                                            L'autore ha scelto di rimanere anonimo
                                        </p>
                                    }
                                    <Card>
                                        <Card.Header>{petizione.petTitolo}</Card.Header>
                                        <Card.Body>
                                            {petizione.petDescrizione && petizione.petDescrizione.split("\n").map((val, index) => (
                                                <span key={index}>{val} <br /></span>
                                            ))}
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col xs={{ span: 9, offset: 1 }} md={{ span: 4, offset: 1 }} className="text-center">
                                    <Row>
                                        <Col xs={{ span: 9, offset: 2 }} md={{ span: 7, offset: 1 }} className="text-center">
                                            <ListGroup variant="flush" >
                                                &nbsp;
                                                <span >Raccolta firme &nbsp; <ImCalendar /></span>
                                                <ListGroup.Item>
                                                    <span> dal <strong>{dayjs(petizione.petInizioRaccoltaFirme).format('DD/MM/YYYY')}</strong></span>
                                                    <br />
                                                    <p> al <strong>{dayjs(petizione.petFineRaccoltaFirme).format('DD/MM/YYYY')}</strong></p>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <p>
                                                        {petizione.petCategoria}
                                                        &nbsp;
                                                        <MdOutlineCategory />
                                                    </p>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <p>
                                                        {petizione.petStato}
                                                        &nbsp;
                                                        <GrStatusInfo />
                                                    </p>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    {petizione.petStato === statiPetizione.InCorso && petizione.petStato !== statiPetizione.Conclusa &&
                                                        <>
                                                            <p>
                                                                Mancano ancora <strong>
                                                                    <NumberFormat
                                                                        displayType="text"
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        value={getFirmeMancanti()}
                                                                    />
                                                                </strong> firme
                                                                &nbsp;
                                                                <FaRegDotCircle />
                                                            </p>
                                                        </>
                                                    }
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Col className=" mt-5 text-center">
                                {petizione.petStato === statiPetizione.Conclusa && petizione.firmePetizione.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0] &&
                                    <p>Hai <strong>firmato</strong> questa petizione in data <strong>{dayjs(petizione.firmePetizione.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0].data).format('DD/MM/YYYY')}</strong></p>
                                }
                                {petizione.petStato !== statiPetizione.Conclusa ?
                                    // se la petizione non è conclusa mostro il pulsante per votare o revocare
                                    !votoEspresso ?
                                        <>
                                            <p>Questa petizione ti ha convinto?</p>
                                            <Button
                                                onClick={IsLogged}
                                                disabled={!permettiFirma()}>
                                                Firma adesso!
                                                <span className="m-2">{firmaPetizione}</span>
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <p>Hai firmato questa petizione in data {dayjs(petizione.firmePetizione.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0].data).format('DD/MM/YYYY')}</p>
                                            {props.comune && props.comune.impostazioniComune &&
                                                props.comune.impostazioniComune.imcFlRitiraFirme &&
                                                <Button variant="outline-danger"
                                                    onClick={() => { setMostraModaleRevoca(true) }}>
                                                    Revoca la firma
                                                    <BiEraser className="ms-2" size={25} />
                                                </Button>
                                            }
                                        </>
                                    :
                                        // se la petizione è conclusa mostro la risposta
                                        <>
                                            <div className="divider" />
                                            <h2 className="title2 text-start mb-3">Risposta dal comune</h2>
                                            <p className="text-start">Pubblicata in data {dayjs(petizione.petDataRisposta).format('DD/MM/YYYY')}</p>
                                            <Card>
                                                <Card.Body className="text-justify">
                                                    {petizione.petRisposta && petizione.petRisposta.split("\n").map((val, index) => (
                                                        <span key={index}>{val} <br /></span>
                                                    ))}
                                                </Card.Body>
                                            </Card>
                                        </>
                                }
                            </Col>
                        </Card.Body>
                        <Card.Footer />
                    </Card>
                    <Button
                        variant="outline-dark"
                        className="ms-5 mb-3"
                        onClick={() => { navigate('/esplora-petizioni') }}
                    >
                        <FiChevronLeft />
                        Torna a tutte le petizioni
                    </Button>

                    <ModalConferma
                        mostra={mostraModaleFirma}
                        setMostra={setMostraModaleFirma}
                        titolo="Firma la petizione"
                        messaggio={`Stai per firmare la petizione "${petizione.petTitolo}". Sei sicuro?`}
                        btnAnnulla="No, annulla"
                        btnConferma="Si, firma"
                        mode="continua"
                        azioneAnnulla={() => { setMostraModaleFirma(false) }}
                        azioneConferma={() => { handleFirma() }}
                    />

                    <ModalConferma
                        mostra={mostraModaleConferma}
                        setMostra={setMostraModaleConferma}
                        titolo="Firmata con successo!"
                        messaggio={`La petizione è stata firmata con successo!`}
                        btnConferma="Chiudi"
                        mode="success"
                        azioneConferma={() => { setMostraModaleConferma(false) }}
                    />

                    <ModalConferma
                        mostra={mostraModaleRevoca}
                        setMostra={setMostraModaleRevoca}
                        titolo="Revoca la firma"
                        messaggio={`Stai per revocare la firma che hai espresso per la petizione "${petizione.petTitolo}". Sei sicuro?`}
                        btnAnnulla="No, non revocare"
                        btnConferma="Si, revoca firma"
                        mode="danger"
                        azioneAnnulla={() => { setMostraModaleRevoca(false) }}
                        azioneConferma={() => { handleRevoca() }}
                    />
                    <ModalPermessi
                        mostra={mostraModalPermessi}
                        setMostra={setMostraModalPermessi}
                        titolo={titolo}
                        messaggio={messaggio}
                        mode={mode}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
} 
