import { Button, Col, Row } from "react-bootstrap";
import { FiChevronLeft } from 'react-icons/fi'
import { statiBilancio } from "../../Utilities/statiElementEnum";

export default function AzioniBilancio(props) {

    return (
        <>
            <Row className="mt-3 mb-5">
                <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 1 }}>
                    <Button variant="outline-dark"
                        className="w-100 my-2 my-md-1 shadow"
                        onClick={() => { props.setMostraTabella(true) }}>
                        <FiChevronLeft />
                        Indietro
                    </Button>
                </Col>

                {
                    props.bilStato === statiBilancio.NonPubblicato &&
                    <>
                        <Col xs={{span:12, order:2}} md={{span:2, offset:6, order:2}}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                            type="submit"
                            variant="outline-secondary"
                            onClick={
                                e => {
                                    props.handleSubmit("salva-bozza", e)
                                }
                            }
                        >
                            Salva bozza
                        </Button>
                        </Col>
                        <Col xs={{span:12, order:2}} md={{span:2, order:2}}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                            type="submit"
                            onClick={
                                e => {
                                    props.handleSubmit("pubblica", e)
                                }
                            }
                        >
                            Salva e pubblica
                        </Button>
                        </Col>
                    </>
                }
                {
                    [statiBilancio.InCorso, statiBilancio.Concluso].includes(props.bilStato) &&
                    <Col xs={{span:12, order:1}} md={{span:2, offset:8, order:2}}>
                    <Button className="w-100 my-2 my-md-1 shadow"
                        variant="primary" type="submit"
                            onClick={
                                e => {
                                    props.handleSubmit("salva", e)
                                }
                            }
                        >
                            Salva
                        </Button>
                    </Col>
                }
            </Row>
        </>
    )
}