import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export default function CartaElemento(props) {

    const navigate = useNavigate();

    const [hovered, setHovered] = useState(false);

    const goToPath = () => {
        navigate(props.path)
    }


    return (
        <Card className="text-center mr-2 mt-2 w-100 h-100 bg-secondary cartaCruscotto d-flex align-items-center"
            style={{ cursor: hovered ? 'pointer' : 'default' }}
            onClick={goToPath}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            <div className="my-auto w-100">
                <Container className="d-flex justify-content-between mt-3 ">
                    <Card.Title className="title my-auto">{props.titolo}</Card.Title>
                    {typeof props.icona === 'string' ? (
                        <Card.Img style={{ width: '2rem', height: '2rem' }} variant="top" src={props.icona} />
                    ) : (
                        <div style={{ width: '2rem', height: '2rem' }}>
                            {props.icona}
                        </div>
                    )}
                </Container>
                <Card.Body className="p-4">
                    <Card.Text>
                        Sono presenti <br /> <strong className="bigText">{props.numero || "..."} {props.parolaElementi} </strong>
                        <br /> nel sistema
                    </Card.Text>
                </Card.Body>
            </div>
        </Card>
    )
}