import axios from 'axios';

const FASI_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'bilancio-partecipativo-fasi';

const headers = { 'Content-Type': 'application/json' };

class BilancioPartecipativoFasiService {
    aggiornaFasi(bilancio) {
        return axios.post(FASI_REST_API_URL + "/save", bilancio, { headers });
    }

    eliminaFasi(fasi) {
        return axios.post(FASI_REST_API_URL + "/delete", fasi, { headers });
    }
}

const bilancioPartecipativoFasiServiceInstance = new BilancioPartecipativoFasiService();
export default bilancioPartecipativoFasiServiceInstance;
