import React from "react"
import { FiChevronLeft } from 'react-icons/fi'
import { Row, Col, Button } from "react-bootstrap"
import { statiProgetto } from "../../../Utilities/statiElementEnum"

export default function AzioniProgetto(props) {

    return (
        <>
            <Row className="mt-3 mb-5">
                <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 1 }}>
                    <Button variant="outline-dark"
                        className="w-100 my-2 my-md-1 shadow"
                        onClick={() => props.setGestisci("progetti-elenco")}>
                        <FiChevronLeft />
                        Indietro
                    </Button>
                </Col>

                {props.gestisciProgetto === "controlla" &&
                    <>
                        <Col xs={{ span: 12, order: 2 }} md={{ span: 2, offset: 6, order: 2 }}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                                variant="outline-secondary"
                                onClick={props.preparaRifiuta}
                            >
                                Rifiuta
                            </Button>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 3 }}>
                            <Button className="w-100 my-2 my-md-1 shadow"
                                variant="primary"
                                onClick={
                                    e => {
                                        props.handleSubmit("pubblica", e)
                                    }
                                }
                            >
                                Salva e pubblica
                            </Button>
                        </Col>
                    </>
                }

                {(props.gestisciProgetto === "rispondi" && props.stato !== statiProgetto.Rifiutato) &&
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 3, offset:8  }}>
                        <Button className="w-100 my-2 my-md-1 shadow"
                            variant="primary"
                            onClick={e => { props.handleSubmit("rispondi", e) }}
                        >
                            Rispondi al progetto
                        </Button>
                    </Col>
                }

                {/* {props.stato === statiProposta.Rifiutata &&
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 3 , offset:8}}>
                        <Button className="w-100 my-2 my-md-1 shadow"

                            variant="primary"
                            onClick={e => { props.handleSubmit("pubblica", e) }}
                        >
                            Pubblica proposta
                        </Button>
                    </Col>

                } */}
            </Row>
        </>
    )
}