function Enum(baseEnum) {  
    return new Proxy(baseEnum, {
      get(target, name) {
        if (!baseEnum.hasOwnProperty(name)) {
          throw new Error(`"${name}" value does not exist in the enum`)
        }
        return baseEnum[name]
      },
      set(target, name, value) {
        throw new Error('Cannot add a new value to the enum')
      }
    })
  }


//stati ammessi per le petizioni
const statiPetizione = Enum({
  InValidazione: 'In validazione',
  InCorso: 'In corso',
  InAttesa: 'In attesa di risposta',
  Conclusa: 'Conclusa',
  FirmeNonRaggiunte: "Firme non raggiunte",
  Rifiutata: "Rifiutata"
})

//stati ammessi per i referendum
const statiReferendum = Enum({
  InValidazione: 'In validazione',
  InCorso: 'In corso',
  InAttesa: 'In attesa di ammissione',
  FirmeRaggiunte: 'Firme raggiunte',
  Concluso: 'Concluso',
  FirmeNonRaggiunte: "Firme non raggiunte",
  Rifiutato: "Rifiutato"
})

//tipi referendum ammessi
const tipiReferendum= Enum({
  Abrogativo:"Abrogativo",
  Consultivo:"Consultivo",
  Propositivo:"Propositivo",
})

//stati ammessi per le segnalazioni
const statiSegnalazione = Enum({
  InAttesa: "In attesa di risposta",
  Chiusa: "Chiusa",
  Rifiutata: "Rifiutata"
})

//stati ammessi per i bilanci
const statiBilancio = Enum({
  NonPubblicato: "Non pubblicato",
  InCorso: 'In corso',
  Concluso: "Concluso"
})


//stati ammessi per le proposte
const statiProposta = Enum({
  InValidazione: 'In validazione',
  Pubblicata:'Pubblicata',
  Vincitrice: 'Vincitrice',
  Rifiutata: 'Rifiutata'
})

//stati ammessi per le iniziative
const statiIniziativa= Enum({
  NonPubblicata: "Non pubblicata",
  InCorso: 'In corso',
  Conclusa: "Conclusa"
})


//stati ammessi per i progetti
const statiProgetto = Enum({
  InValidazione: 'In validazione',
  Pubblicato:'Pubblicato',
  Rifiutato: 'Rifiutato'
})



export{
  statiPetizione, 
  statiReferendum,tipiReferendum,
  statiSegnalazione, 
  statiBilancio,statiProposta,
  statiIniziativa, statiProgetto
}