import { useState, useEffect } from "react"
import { Row, Col, Form, Container, InputGroup, Alert } from "react-bootstrap"
import Multiselect from 'multiselect-react-dropdown';
import ApprovazioniReferendum from "./ApprovazioniReferendum"
import AzioniReferendum from "./AzioniReferendum"
import ReferendaService from "../../../services/ReferendaService"
import ModalConferma from "../../Utilities/ModalConferma";
import SoglieReferendumService from "../../../services/SoglieReferendumService"
import { statiReferendum } from "../../Utilities/statiElementEnum";
import { getStatusReferendum } from "../../Utilities/getStatus";

const dayjs = require('dayjs')
const customParseFormat = require('dayjs/plugin/customParseFormat')

export default function GestisciReferendum(props) {

    dayjs.extend(customParseFormat)

    // Valore che permette l'inizializzazione una volta sola al caricamento della pagina
    let [init, setInit] = useState(true)
    // Valore che abilita o disabilita la possibilità di modificare i campi Titolo, Descrizione, Tipo, InizioRaccoltaFirme, FineRaccoltaFirme,
    // a seconda dell'azione di modifica del referendum (Validazione (abilitata) vs Risposta (disabilitata))
    let [readOnly1, setReadOnly1] = useState(false)

    // Elenco tipi
    let [tipi, setTipi] = useState([])

    // Estrazione, dall'array dei referendum, del referendum da visualizzare 
    let referendum = props.referenda.filter(el => el.refId === props.refId)[0]

    // Stati per gestire i campi modificabili del referendum
    let [refTitolo, setRefTitolo] = useState(referendum.refTitolo)
    let [refTipo, setRefTipo] = useState(referendum.refTipo)
    let [refDescrizione, setRefDescrizione] = useState(referendum.refDescrizione)
    let [refInizioRaccoltaFirme, setRefInizioRaccoltaFirme] = useState(dayjs().format('YYYY-MM-DD'))
    let [refFineRaccoltaFirme, setRefFineRaccoltaFirme] = useState(dayjs().add(2, 'M').format('YYYY-MM-DD'))
    let [esitoConsultazione, setEsitoConsultazione] = useState(referendum.refEsitoConsultazione)

    let [enteApprovazione, setEnteApprovazione] = useState("")
    let [dataApprovazione, setDataApprovazione] = useState(dayjs().format('YYYY-MM-DD'))
    let [sogliaToApprove, setSogliaToApprove] = useState()

    // Stato che gestisce firme da registrare
    let [firme, setFirme] = useState(0)

    let [showAlert, setShowAlert] = useState({ message: "", variant: "" })

    // Stati per le finestre modali
    const [mostraModaleOperazione, setMostraModaleOperazione] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)
    const [parolaChiave, setParolaChiave] = useState("")
    const [titolo, setTitolo] = useState("")
    const [messaggio, setMessaggio] = useState("")

    const initTipi = () => {
        setTipi([
            { id: "consultivo", name: "Consultivo" },
            { id: "propositivo", name: "Propositivo" },
            { id: "abrogativo", name: "Abrogativo" }
        ])
    }

    const capitalize = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    let selectRefTipo = (refTipo) => {
        setRefTipo(refTipo[0].id)
    }

    const redirectToList = () => {
        props.setMostraTabella(true)
    }

    const mandatoryField = () => {
        if (props.gestisci === "controlla")
            return <span className='mandatory'>*</span>

        return ""
    }

    const checkReferendum = () => {
        let ret = true

        if (props.gestisci === "controlla") {
            if (
                !refTitolo.length ||
                !refDescrizione.length ||
                !refInizioRaccoltaFirme ||
                !refFineRaccoltaFirme
            ) {
                setShowAlert({ message: "Compila tutti i campi obbligatori", variant: "danger" })
                ret = false
            }

            if (ret)
                if (!checkDateRaccoltaFirme()) {
                    setShowAlert({ message: "Controllare i valori delle date inserite", variant: "danger" })
                    ret = false
                }
        }

        if (props.gestisci === "rispondi") {
            if (!esitoConsultazione || esitoConsultazione.length === 0) {
                setShowAlert({ message: "Inserisci l'esito della consultazione!", variant: "danger" })
                ret = false
            }
        }

        return ret
    }

    const checkDateRaccoltaFirme = () => {
        if (
            dayjs(refInizioRaccoltaFirme, 'YYYY-MM-DD', true).isValid() === false ||
            dayjs(refFineRaccoltaFirme, 'YYYY-MM-DD', true).isValid() === false
        )
            return false

        let date1 = new Date(refInizioRaccoltaFirme)
        let date2 = new Date(refFineRaccoltaFirme)
        let diffInDays = (date2 - date1) / (1000 * 60 * 60 * 24)

        if (diffInDays < 1)
            return false

        return true
    }

    //    RIFIUTA   REFERENDUM
    const preparaRifiuta = () => {
        setMostraModaleOperazione(true)
        setParolaChiave("Rifiuta")
        setTitolo("Rifiuta il referendum")
        setMessaggio("Sei sicuro di voler rifiutare il referendum? L'operazione è irreversibile!")
    }

    const rifiutaReferendum = () => {
        let tmp = { ...referendum, refStato: statiReferendum.Rifiutato }
        ReferendaService.saveReferendum(tmp)
            .then(res => {
                if (res.status === 200) {
                    setMostraModaleOperazione(false)
                    setMostraModaleSuccesso(true)
                    setParolaChiave("Rifiuto")
                    setTitolo("Rifiuto del referendum")
                    setMessaggio("Rifiuto del referendum avvenuto con successo.")
                }
                else if (res.status === 500) {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    setParolaChiave("rifiuto")
                    setTitolo("Errore nel rifiuto del referendum")
                    setMessaggio("Si è verificato un errore durante il rifiuto del referendum.")
                }
            }).catch(err => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                setParolaChiave("rifiuto")
                setTitolo("Errore nel rifiuto del referendum")
                setMessaggio("Si è verificato un errore durante il rifiuto del referendum.")
            })
    }

    // PUBBLICA E RISPONDI
    const preparaSalva = () => {
        if (checkReferendum()) {
            setMostraModaleOperazione(true)
            if (props.gestisci === "controlla") {
                setParolaChiave("Pubblica")
                setTitolo("Pubblica il referendum")
                setMessaggio("Sei sicuro di voler pubblicare il referendum? L'operazione è irreversibile!")
            }
            if (props.gestisci === "rispondi") {
                setParolaChiave("Rispondi")
                setTitolo("Rispondi al referendum")
                setMessaggio("Sei sicuro di voler rispondere al referendum? L'operazione è irreversibile!")
            }
        }
    }

    const salvaReferendum = () => {
        let tmp

        if (props.gestisci === "controlla") {
            tmp = {
                ...referendum,
                refTitolo: refTitolo,
                refTipo: refTipo,
                refDescrizione: refDescrizione,
                refInizioRaccoltaFirme: refInizioRaccoltaFirme,
                refFineRaccoltaFirme: refFineRaccoltaFirme,
                refDataPubblicazione: dayjs().format('YYYY-MM-DD'),
                refStato: statiReferendum.InCorso
            }
        }

        if (props.gestisci === "rispondi"
        ) {
            tmp = {
                ...referendum,
                refEsitoConsultazione: esitoConsultazione,
                refDataEsito: dayjs(),
                refStato: statiReferendum.Concluso
            }
        }
        ReferendaService.saveReferendum(tmp)
            .then(res => {
                if (res.status === 200) {
                    setMostraModaleOperazione(false)
                    setMostraModaleSuccesso(true)
                    if (props.gestisci === "controlla") {
                        setParolaChiave("Pubblicazione")
                        setTitolo("Pubblicazione del referendum")
                        setMessaggio("Pubblicazione del referendum avvenuto con successo.")
                    }
                    else if (props.gestisci === "rispondi") {
                        setParolaChiave("Pubblicazione esito")
                        setTitolo("Pubblicazione dell'esito della consultazione referendaria")
                        setMessaggio("Pubblicazione dell'esito della consultazione referendaria avvenuto con successo.")
                    }
                }
                else if (res.status === 500) {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    if (props.gestisci === "controlla") {
                        setParolaChiave("pubblicazione")
                        setTitolo("Errore nella pubblicazione del referendum")
                        setMessaggio("Si è verificato un errore durante la pubblicazione del referendum.")
                    }
                    else if (props.gestisci === "rispondi") {
                        setParolaChiave("pubblicazione risposta")
                        setTitolo("Errore nella pubblicazione dell'esito della consultazione referendaria")
                        setMessaggio("Si è verificato un errore durante la pubblicazione dell'esito della consultazione referendaria.")
                    }
                }
            }).catch(err => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                if (props.gestisci === "controlla") {
                    setParolaChiave("pubblicazione")
                    setTitolo("Errore nella pubblicazione del referendum")
                    setMessaggio("Si è verificato un errore durante la pubblicazione del referendum.")
                }
                else if (props.gestisci === "rispondi") {
                    setParolaChiave("pubblicazione risposta")
                    setParolaChiave("pubblicazione risposta")
                    setTitolo("Errore nella pubblicazione dell'esito della consultazione referendaria")
                    setMessaggio("Si è verificato un errore durante la pubblicazione dell'esito della consultazione referendaria.")
                }
            })
    }

    // REGISTRA FIRME
    const preparaRegistraFirme = () => {
        if (firme < 1)
            setShowAlert({ message: "Valore firme offline inserito non valido!", variant: "danger" })
        else {
            setMostraModaleOperazione(true)
            setParolaChiave("Registra firme per")
            setTitolo("Registra firme per il referendum")
            setMessaggio("Sei sicuro di voler registrare le firme per il referendum? L'operazione è irreversibile!")
        }
    }

    const registraFirme = async () => {
        let tmp = {
            ...referendum,
            refFirmeOffline: referendum.refFirmeOffline + parseInt(firme)
        }
        ReferendaService.saveReferendum(tmp)
            .then(res => {
                if (res.status === 200) {
                    setMostraModaleOperazione(false)
                    setMostraModaleSuccesso(true)
                    setParolaChiave("Registrazione firme per")
                    setTitolo("Registrazione firme del referendum")
                    setMessaggio("Registrazione firme del referendum avvenuto con successo.")
                } else if (res.status === 500) {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    setParolaChiave("registrazione firme per")
                    setTitolo("Errore nella registrazione delle firme del referendum")
                    setMessaggio("Si è verificato un errore durante la registrazione delle firme del referendum.")
                }
            })
            .catch(err => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                setParolaChiave("registrazione firme per")
                setTitolo("Errore nella registrazione delle firme del referendum")
                setMessaggio("Si è verificato un errore durante la registrazione delle firme del referendum.")
            })
    }

    // APPROVA SOGLIA
    const approvaSoglia = () => {
        let tmp = {
            ...referendum,
        }
        tmp.soglieReferendum.push({
            sorData: dataApprovazione,
            sorEnteApprovante: enteApprovazione,
            soglia: sogliaToApprove
        })

        let soglieAttiveTotale = props.comune.impostazioniComune.soglie.filter((soglia) => (soglia.sogActive === true)).length
        if (soglieAttiveTotale === tmp.soglieReferendum.length)
            tmp.refStato = statiReferendum.FirmeRaggiunte

        if (tmp.refStato === statiReferendum.FirmeRaggiunte) {
            ReferendaService.saveReferendum(tmp)
                .then(res => {
                    if (res.status === 200) {
                        setMostraModaleOperazione(false)
                        setMostraModaleSuccesso(true)
                        setParolaChiave("Ammissione soglia")
                        setTitolo("Ammissione per la soglia corrente")
                        setMessaggio("Ammissione del referendum per la soglia corrente avvenuto con successo.")
                    } else if (res.status === 500) {
                        setMostraModaleOperazione(false)
                        setMostraModaleErrore(true)
                        setParolaChiave("ammissione soglia")
                        setTitolo("Errore nell'ammissione per la soglia corrente")
                        setMessaggio("Si è verificato un errore durante l'ammissione per la soglia corrente del referendum.")
                    }
                })
                .catch(err => {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    setParolaChiave("ammissione soglia")
                    setTitolo("Errore nell'ammissione per la soglia corrente")
                    setMessaggio("Si è verificato un errore durante l'ammissione per la soglia corrente del referendum.")
                })
        }

        SoglieReferendumService.aggiornaSoglieReferendum(tmp)
            .then(res => {
                if (res.status === 200) {
                    setMostraModaleOperazione(false)
                    setMostraModaleSuccesso(true)
                    setParolaChiave("Ammissione soglia")
                    setTitolo("Ammissione per la soglia corrente")
                    setMessaggio("Ammissione del referendum per la soglia corrente avvenuto con successo.")
                } else if (res.status === 500) {
                    setMostraModaleOperazione(false)
                    setMostraModaleErrore(true)
                    setParolaChiave("ammissione soglia")
                    setTitolo("Errore nell'ammissione per la soglia corrente")
                    setMessaggio("Si è verificato un errore durante l'ammissione per la soglia corrente del referendum.")
                }
            })
            .catch(err => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                setParolaChiave("ammissione soglia")
                setTitolo("Errore nell'ammissione per la soglia corrente")
                setMessaggio("Si è verificato un errore durante l'ammissione per la soglia corrente del referendum.")
            })
    }

    useEffect(() => {
        setTimeout(() => {
            setShowAlert({});
        }, 3000);

        if (init) {
            if (props.gestisci === "controlla")
                initTipi()
            if (props.gestisci !== "controlla")
                setReadOnly1(true)
            setInit(false)
        }
    }, [showAlert, props.gestisci, init])

    return (
        <>
              <div className="container-sm">
                {props.gestisci === "controlla" && <h2 className="title2">Controlla il referendum</h2>}
                {props.gestisci === "rispondi" && <h2 className="title2">Rispondi al referendum</h2>}
                {props.gestisci === "firme" && <h2 className="title2">Registra firme raccolte offline</h2>}

                {
                    props.gestisci === "controlla" &&
                    <Row>
                        <Col className="mb-4 italic required-alert">I campi contrassegnati da un asterisco (*) sono obbligatori</Col>
                    </Row>
                }

                <Row>
                    <Col md={referendum.refAssociazione !== undefined ? 5 : 10}>
                        <p>
                            <strong>Autore: </strong>
                            {referendum.partecipante.datiPartecipante.dparNome} {referendum.partecipante.datiPartecipante.dparCognome}
                        </p>
                    </Col>
                    {
                        referendum.refAssociazione !== undefined &&
                        <Col md={5}>
                            <p>
                                <strong>Associazione: </strong>
                                {referendum.refAssociazione}
                            </p>
                        </Col>
                    }
                    <Col className="text-md-right" md={2}>
                        <p><strong>Inviato il: </strong> {dayjs(referendum.refDataInvio).format('DD/MM/YYYY')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Titolo: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={refTitolo}
                                    onChange={(ev) => setRefTitolo(ev.target.value)}
                                    readOnly={readOnly1}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={4}>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Tipo: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                {
                                    props.gestisci === "controlla" &&
                                        tipi.length > 0 ? (
                                        <Multiselect
                                            displayValue="name"
                                            className="multiselect-style1"
                                            onSelect={selectRefTipo}
                                            selectedValues={[tipi.filter(el => el.id === refTipo)[0]]}
                                            options={tipi}
                                            singleSelect
                                            avoidHighlightFirstOption
                                            style={{
                                                chips: {
                                                    'fontSize': '1rem',
                                                    'marginBottom': '0'
                                                }
                                            }}
                                        />
                                    ) : (
                                        <Form.Control
                                            type="text"
                                            value={capitalize(refTipo)}
                                            readOnly
                                        />
                                    )
                                }
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label>
                                <strong>Descrizione: </strong>
                                {mandatoryField()}
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                value={refDescrizione}
                                onChange={(ev) => setRefDescrizione(ev.target.value)}
                                readOnly={readOnly1}
                                className="no-resize"
                            />
                        </Form.Group>
                    </Form>
                </Row>
                <Row>
                    <Col md={4}>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Inizio Raccolta Firme: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={referendum.refInizioRaccoltaFirme || refInizioRaccoltaFirme}
                                    onChange={(ev) => setRefInizioRaccoltaFirme(ev.target.value)}
                                    readOnly={readOnly1}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={4} />
                    <Col md={4}>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Fine Raccolta Firme: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={referendum.refFineRaccoltaFirme || refFineRaccoltaFirme}
                                    onChange={(ev) => setRefFineRaccoltaFirme(ev.target.value)}
                                    readOnly={readOnly1}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

                {
                    props.gestisci === "rispondi" && ![statiReferendum.Rifiutato, statiReferendum.FirmeNonRaggiunte].includes(getStatusReferendum(referendum)) &&
                    <ApprovazioniReferendum
                        comune={props.comune}
                        referendum={referendum}
                        enteApprovazione={enteApprovazione}
                        setEnteApprovazione={setEnteApprovazione}
                        dataApprovazione={dataApprovazione}
                        setDataApprovazione={setDataApprovazione}
                        sogliaToApprove={sogliaToApprove}
                        setSogliaToApprove={setSogliaToApprove}
                        rifiutaReferendum={rifiutaReferendum}
                        showAlert={showAlert}
                        setShowAlert={setShowAlert}
                        setTitolo={setTitolo}
                        setMessaggio={setMessaggio}
                        setMostraModaleOperazione={setMostraModaleOperazione}
                        setMostraModaleSuccesso={setMostraModaleSuccesso}
                        setMostraModaleErrore={setMostraModaleErrore}
                        setParolaChiave={setParolaChiave}
                    />
                }

                {[statiReferendum.FirmeRaggiunte, statiReferendum.Concluso].includes(referendum.refStato)  &&
                    <Row>
                        <h4 className="mt-4"><strong>Esito consultazione: </strong></h4>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={10}
                                    placeholder="Scrivi l'esito della consultazione solo a scrutinio concluso"
                                    value={esitoConsultazione}
                                    onChange={(ev) => setEsitoConsultazione(ev.target.value)}
                                    className="no-resize"
                                />
                            </Form.Group>
                        </Form>
                    </Row>
                }

                {props.gestisci === "firme" &&
                    <>
                        <Row className="mt-5 ">
                            <Col md={6}>
                                <h4><strong>Firme raccolte offline: </strong></h4>
                            </Col>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        className="text-center"
                                        onChange={(ev) => { setFirme(ev.target.value) }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>Firme presenti finora: (<strong>{referendum.refFirmeOffline}</strong>)</Col>
                        </Row>
                    </>
                }

                <AzioniReferendum
                    gestisci={props.gestisci}
                    refStato={referendum.refStato}
                    firme={firme}
                    redirectToList={redirectToList}
                    preparaRifiuta={preparaRifiuta}
                    preparaSalva={preparaSalva}
                    preparaRegistraFirme={preparaRegistraFirme}
                    rifiutaReferendum={rifiutaReferendum}
                    salvaReferendum={salvaReferendum}
                    registraFirme={registraFirme}
                />
            </ div>

            {
                showAlert.message &&
                <Container className="fixed-bottom w-50">
                    <Alert variant={showAlert.variant}>{showAlert.message}</Alert>
                </Container>
            }

            {/* modale di conferma operazione */}
            <ModalConferma
                mostra={mostraModaleOperazione}
                setMostra={setMostraModaleOperazione}
                mode={parolaChiave.toLowerCase() === "rifiuta" ? "danger" : "continua"}
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma={parolaChiave.toLowerCase() === "registra firme per" ?
                    "Si, registra le firme" :
                    `Si, ${parolaChiave.toLowerCase()}`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleOperazione(false) }}
                azioneConferma={() => {
                    if (parolaChiave.toLowerCase() === "rifiuta")
                        rifiutaReferendum()
                    else if (parolaChiave.toLowerCase() === "pubblica" || parolaChiave.toLowerCase() === "rispondi")
                        salvaReferendum()
                    else if (parolaChiave.toLowerCase() === "rifiuta ammissione")
                        rifiutaReferendum()
                    else if (parolaChiave.toLowerCase() === "ammetti soglia")
                        approvaSoglia()
                    else registraFirme()
                }}
            />

            {/* modali di esito operazione */}
            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Torna a tutti i referendum"
                azioneConferma={() => {
                    props.setRicaricaReferendum(true)
                    props.setMostraTabella(true)
                }}
            />

            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => {
                    setMostraModaleErrore(false)
                }}
            />
        </>
    )
}