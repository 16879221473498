import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { Card, Row, Col, Button, Badge, ListGroup, Figure } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { FiChevronLeft } from 'react-icons/fi'
import { ImCalendar } from 'react-icons/im'
import { MdOutlineCategory } from 'react-icons/md'
import { GrStatusInfo } from 'react-icons/gr'
import SegnalazioniService from "../../../services/SegnalazioniService";
import ModalePreview from "./ModalePreview";
import { getSegnalazioneColor } from "../../Utilities/getBorderColor";
import dayjs from "dayjs";
import { Navigate } from 'react-router-dom';
import { statiSegnalazione } from '../../Utilities/statiElementEnum';


var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default function PaginaSegnalazione(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [segnalazione, setSegnalazione] = useState({})
    const [ricaricaSegnalazione, setRicaricaSegnalazione] = useState(true)

    // stati per aprire i modali
    const [mostraPreview, setMostraPreview] = useState(false)

    const [numeroAllegato, setNumeroAllegato] = useState(0);
    const [oggettoSegnalazione, setOggettoSegnalazione] = useState("");
    const [figuraPreview, setFiguraPreview] = useState("")

    useEffect(() => {
        if (ricaricaSegnalazione) {
            if (location.state && location.state.segId) {
                SegnalazioniService.getSegnalazione(location.state.segId)
                    .then((res) => {
                        setSegnalazione(res.data)
                    })
                    .catch((err) => {
                        setSegnalazione({})
                        //TODO gestione dell'errore
                    })
                setRicaricaSegnalazione(false)
            }
        }
    }, [location.state, ricaricaSegnalazione, props])

    const apriPreview = (index, file, oggetto) => {
        setNumeroAllegato(index + 1)
        setFiguraPreview(file)
        setOggettoSegnalazione(oggetto)
        setMostraPreview(true)
    }

    if (props.comune && props.comune.impostazioniComune) {
        if (
            props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
            location.state &&
            location.state.segId
        ) {
            return (
                <>
                    <div className="pt-4 px-md-5 px-2 pb-2 bg-secondary">
                        <Row className="w-100 p-0 m-0">
                            <Col className="p-0 align-self-center">
                                <span className="smallcaps dark">Segnalazione</span>
                                <h1 className="title dark">{segnalazione.segOggetto}
                                </h1>
                                <h5><Badge bg="primary">{segnalazione.segCategoria}</Badge></h5>
                            </Col>
                            <Col xs={12} md={7} className="p-0">
                                <Card className="text-center cartaSommario"
                                    border={getSegnalazioneColor(segnalazione)}
                                >
                                    <Card.Body>
                                        <h3 className="smallcaps title m-0"> {segnalazione.segStato}</h3>
                                        <p className="pt-2">Inviata il: {dayjs(segnalazione.segDataInvio).format("DD/MM/YYYY")}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "50vh" }}>
                        <Card.Header />
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={7}>
                                    <Card style={{ minHeight: "100%" }}>
                                        <Card.Header className="text-center">{segnalazione.segOggetto}</Card.Header>
                                        <Card.Body>
                                            {segnalazione.segDescrizione && segnalazione.segDescrizione.split("\n").map((val, index) => (
                                                <span key={index}>{val} <br /></span>
                                            ))}
                                        </Card.Body>
                                        <Card.Body >
                                            {segnalazione.allegatiSegnalazione && segnalazione.allegatiSegnalazione.length > 0 &&
                                                segnalazione.allegatiSegnalazione.map((file, index) => (
                                                    <Figure key={index} className="p-1 allegatoSegnalazione">
                                                        <Figure.Image
                                                            width={300}
                                                            height={200}
                                                            onClick={() => { apriPreview(index, file.allSegFile, segnalazione.segOggetto) }}
                                                            src={`data:image/png;base64,${file.allSegFile}`}
                                                        />
                                                    </Figure>
                                                ))
                                            }
                                        </Card.Body>
                                    </Card>

                                </Col>
                                <Col xs={12} md={5}>
                                    {segnalazione.posizione &&
                                        <MapContainer className="mx-1 mt-1" style={{ height: "40vh" }}
                                            center={[segnalazione.posizione && segnalazione.posizione.posLatitudine, segnalazione.posizione && segnalazione.posizione.posLongitudine]} zoom={15} scrollWheelZoom={false}>
                                            <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Marker

                                                position={[segnalazione.posizione && segnalazione.posizione.posLatitudine, segnalazione.posizione && segnalazione.posizione.posLongitudine]}
                                            >
                                                <Popup minWidth={90}>
                                                    <span>
                                                        {segnalazione.posizione && segnalazione.posizione.posNome}
                                                    </span>
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    }
                                    <ListGroup className="mt-2">
                                        <ListGroup.Item >
                                            <strong> <ImCalendar /> Inviata il: </strong>
                                            {dayjs(segnalazione.segDataInvio).format("DD/MM/YYYY")}
                                        </ListGroup.Item>
                                        <ListGroup.Item >
                                            <strong> <GrStatusInfo /> Indirizzo: </strong>
                                            {segnalazione.posizione && segnalazione.posizione.posNome}
                                        </ListGroup.Item>
                                        <ListGroup.Item >
                                            <strong>  <MdOutlineCategory /> Categoria: </strong>
                                            {segnalazione.segCategoria}
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Col className=" mt-5 text-center">
                                {segnalazione.segStato === statiSegnalazione.Chiusa &&
                                    // se la segnalazione è conclusa mostro la risposta
                                    <>
                                        <div className="divider" />
                                        <h2 className="title2 text-start mb-3">Risposta dal comune</h2>
                                        <p className="text-start">Pubblicata in data {dayjs(segnalazione.segDataRisposta).format('DD/MM/YYYY')}</p>
                                        <Card>
                                            <Card.Body className="text-justify">
                                                {segnalazione.segRisposta && segnalazione.segRisposta.split("\n").map((val, index) => (
                                                    <span key={index}>{val} <br /></span>
                                                ))}
                                            </Card.Body>
                                        </Card>
                                    </>
                                }

                            </Col>
                        </Card.Body>
                        <Card.Footer />
                    </Card>
                    <Button variant="outline-dark" className="ms-5 mb-3"
                        onClick={() => { navigate('/esplora-segnalazioni') }}>
                        <FiChevronLeft />
                        Torna a tutte le segnalazioni</Button>

                    <ModalePreview
                        mostraPreview={mostraPreview}
                        setMostraPreview={setMostraPreview}
                        numeroAllegato={numeroAllegato}
                        oggettoSegnalazione={oggettoSegnalazione}
                        figuraPreview={figuraPreview}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
} 
