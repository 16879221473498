import { Button, Row, Col } from "react-bootstrap";
import { FiChevronLeft } from 'react-icons/fi'
export default function AzioniPetizione(props) {

    return (
        <>
           <Row className="mt-3 mb-5">
                    <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 1 }}>
                        <Button variant="outline-dark"
                            className="w-100 my-2 my-md-1 shadow"
                            onClick={props.redirectToList}>
                            <FiChevronLeft />
                            Indietro
                        </Button>
                    </Col>
                            
                            
                            {props.gestisci === "rispondi" &&
                            <>
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 2, offset:5, order: 3 }}>
                        <Button className="w-100 my-2 my-md-1 shadow" variant="outline-secondary" 
                            onClick={props.preparaRifiuta}>
                            Rifiuta segnalazione
                        </Button>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 3, order: 3 }}>
                        <Button className="w-100 my-2 my-md-1 shadow" onClick={props.preparaChiudi}>
                            Rispondi e chiudi segnalazione
                        </Button>

                    </Col>
                                </>
                            }
                </Row>
        </>
    )
}