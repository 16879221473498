import ModalConferma from "../../Utilities/ModalConferma";

import { Card, Form, Row, ListGroup, Col, Button, Alert, OverlayTrigger, Popover, InputGroup, ButtonGroup } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import Select from 'react-select'

import { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { max_char_descrizione, max_char_titolo } from "../../Utilities/variables";

import { FiUpload } from 'react-icons/fi'
import { BsFileEarmarkRichtext } from "react-icons/bs"
import { GoTrash } from "react-icons/go"
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { BsFillGeoAltFill } from 'react-icons/bs'

import axios from "axios";
import dayjs from "dayjs";
import SegnalazioniService from "../../../services/SegnalazioniService";
import { Navigate } from 'react-router-dom';
import { FiAlertTriangle } from 'react-icons/fi'
import { statiSegnalazione } from "../../Utilities/statiElementEnum";

export default function CreaSegnalazione(props) {
    const location = useLocation()
    const navigate = useNavigate();
    const goToLogin = () => {
        navigate("/login", { state: { prevPath: location.pathname } })
    }

    let ref = useRef(null);
    const markerRef = useRef(null)
    let inputFile = useRef(null)
    let select = useRef(null)
    let campo_indirizzo = useRef(null)
    const map = useRef(null)

    const [indirizzo, setIndirizzo] = useState("")
    const [indirizziMultipli, setIndirizziMultipli] = useState([])

    const [erroriCompilazione, setErroriCompilazione] = useState({ indirizzo: "", categoria: "", oggetto: "", descrizione: "", allegati: "", email: "" })

    const [posizione, setPosizione] = useState([])

    const [categorie, setCategorie] = useState([])
    const [categoriaSelezionata, setCategoriaSelezionata] = useState([])

    const [oggetto, setOggetto] = useState("")

    const [descrizione, setDescrizione] = useState("")
    const [email, setEmail] = useState("");

    const [validated, setValidated] = useState(false);

    //stati per i modali di conferma
    const [mostraModaleAnnulla, setMostraModaleAnnulla] = useState(false);
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false);
    const [mostraModaleOk, setMostraModaleOk] = useState(false)

    //stato per mostrare alert di errore
    const [messaggioAlert, setMessaggioAlert] = useState("")

    //stato per i file caricati, metadata e arrayBuffer
    const [uploadedFilesMetadata, setUploadedFilesMetadata] = useState([])
    const acceptedExtensions = ["image/*", "image/png", "image/jpg", "image/jpeg"]


    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    //CATEGORIA
    function handleSelect(data) {
        setCategoriaSelezionata(data);
    }

    const checkForm = () => {
        let condizioni_ok = true

        setValidated(true);
        setErroriCompilazione({ indirizzo: "", categoria: "", oggetto: "", descrizione: "", allegati: "", email: "" })
        campo_indirizzo.classList.remove("is-invalid")
        select.controlRef.classList.remove("is-invalid")

        if (indirizzo.trim().length === 0) {
            setErroriCompilazione(old => ({ ...old, indirizzo: "Indirizzo mancante" }))
            campo_indirizzo.classList.add("is-invalid")
            condizioni_ok = false
        }
        else if (!posizione.length > 0) {
            campo_indirizzo.classList.add("is-invalid")
            setErroriCompilazione(old => ({ ...old, indirizzo: "L'indirizzo inserito non è stato individuato sulla mappa" }))
            condizioni_ok = false
        }

        if (categoriaSelezionata.length === 0) {
            select.controlRef.classList.add("is-invalid")
            setErroriCompilazione(old => ({ ...old, categoria: "Categoria mancante" }))
            condizioni_ok = false
        } else {
            select.controlRef.classList.add("is-valid")
        }

        if (!oggetto) {
            setErroriCompilazione(old => ({ ...old, oggetto: "Oggetto mancante" }))
            condizioni_ok = false
        }
        if (!descrizione) {
            setErroriCompilazione(old => ({ ...old, descrizione: "Descrizione mancante" }))
            condizioni_ok = false
        }

        if (!email) {
            setErroriCompilazione(old => ({ ...old, email: 'Email mancante' }))
            condizioni_ok = false
        }
        if (!isValidEmail(email)) {
            setErroriCompilazione(old => ({ ...old, email: 'Formato email non valido' }))
            condizioni_ok = false
        }

        if (!uploadedFilesMetadata.length > 0) {
            setErroriCompilazione(old => ({ ...old, allegati: 'Allegati mancanti' }))

            condizioni_ok = false
        }

        if (ref.checkValidity() === true && condizioni_ok) {
            setMostraModaleConferma(true)
        }
        else {
            setMessaggioAlert("Correggi gli errori e riprova")
        }
    }

    const handleSubmit = () => {
        let segnalazione = {
            partecipante: props.partecipante,
            posizione: {
                comune: props.comune,
                posLatitudine: posizione[0],
                posLongitudine: posizione[1],
                posNome: indirizzo,
            },
            segCategoria: categoriaSelezionata.value,
            segDataInvio: dayjs().format("YYYY-MM-DD"),
            segDescrizione: descrizione,
            segEmail: email,
            segOggetto: oggetto,
            segStato: statiSegnalazione.InAttesa,
        }

        var formData = new FormData()
        formData.append("segnalazione", JSON.stringify(segnalazione))
        uploadedFilesMetadata.forEach(el => {
            formData.append("file", el)
        })

        SegnalazioniService.creaSegnalazione(formData)
            .then(() => {
                setMostraModaleConferma(false)
                setMostraModaleOk(true)
            })
            .catch((err) => {
                console.log(err.message)
                setMessaggioAlert("Impossibile inviare la segnalazione")
            })
    }

    //dismiss alert
    useEffect(() => {
        setTimeout(() => {
            setMessaggioAlert("");
        }, 3000);
    }, [messaggioAlert]);

    useEffect(() => {
        let opt = []
        opt = props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategorieSegnalazioni.categorie_segnalazioni.map((el, i) => {
            return { "value": el, "label": el }
        })
        setCategorie(opt)
    }, [props.comune.impostazioniComune])

    // MAPPA
    const localizzaSullaMappa = () => {
        let ci = campo_indirizzo
        setErroriCompilazione("")
        let indirizzo_per_ricerca = indirizzo.replace(" ", "+")
        axios.get('//nominatim.openstreetmap.org/search?format=json&q=' + indirizzo_per_ricerca)
            .then((res) => {
                if (res.data.length === 1) {
                    // unico risultato
                    setIndirizzo(res.data[0].display_name)
                    setPosizione([res.data[0].lat, res.data[0].lon])
                    map.current.flyTo([res.data[0].lat, res.data[0].lon], 13)
                    ci.classList.remove("is-invalid")

                } else if (res.data.length > 1) {
                    //più di un risultato
                    setIndirizziMultipli(res.data)
                    setErroriCompilazione(old => ({ ...old, indirizzo: "A questo indirizzo corrispondono più risultati, aggiungi qualche altra informazione o scegli dal seguente elenco." }))
                } else {
                    // nessun risultato
                    setPosizione([])
                    setErroriCompilazione(old => ({ ...old, indirizzo: "Questo indirizzo non può essere localizzato sulla mappa, correggi gli errori." }))
                }
            })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            localizzaSullaMappa()
        }
    }

    //sposta il marker e salva posizione raggiunta
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosizione([marker.getLatLng().lat, marker.getLatLng().lng])
                    axios.get("//nominatim.openstreetmap.org/reverse?format=json&lat="
                        + marker.getLatLng().lat + "&lon=" + marker.getLatLng().lng)
                        .then(res => {
                            setIndirizzo(res.data.display_name)
                        })
                        .catch(err => console.log(err))
                }
            },
        }),
        [],
    )

    //FILE
    const handleDisplayFileDetails = async () => {
        if (inputFile?.files && inputFile.files[0]
            && inputFile.files[0].size <= 200 * 1024 //controlla grandezza file < 200kB 
            && acceptedExtensions.includes(inputFile.files[0].type) //controlla estensione
            && uploadedFilesMetadata.length < 5 //controlla # allegati
        ) {
            setUploadedFilesMetadata(old => [...old, inputFile.files[0]])
            setErroriCompilazione(old => ({ ...old, allegati: "" }))
        } else {
            if (uploadedFilesMetadata.length >= 5) setMessaggioAlert("Non puoi allegare più di cinque allegati.")
            else if (uploadedFilesMetadata.filter(file => file.name === inputFile.files[0].name).length > 1) setMessaggioAlert("Non puoi caricare file con lo stesso nome.")
            else if (!acceptedExtensions.includes(inputFile.files[0].type)) setMessaggioAlert("Il formato del file scelto non è accettabile.")
            else if (!inputFile.files[0].size <= 102400) setMessaggioAlert("Il file scelto è troppo grande. Riprova.")
        }
    }

    const handleUpload = (event) => {
        event.preventDefault()
        inputFile?.click();
    };

    const deleteFile = (name) => {
        setUploadedFilesMetadata(old => old.filter(el => el.name !== name))
    }

    if (props.comune && props.comune.impostazioniComune && props.partecipante) {
        if (props.comune.impostazioniComune.imcModuloSegnalazioniAttivo) {
            if (props.partecipante.parId && props.partecipante.ruolo) {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea la tua segnalazione</h1>
                        <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header>
                                <p className="mt-2 mb-0">Compila il modulo seguente con le informazioni richieste per inviare la tua segnalazione.</p>
                                <p className="italic text-muted">I campi contrassegnati con un asterisco (*) sono obbligatori.</p>
                            </Card.Header>
                            <Card.Body className="p-md-5 p-2">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={el => ref = el} >
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Indirizzo * </strong>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control placeholder="Inserisci il nome della via o della piazza, poi premi il pulsante o premi invio"
                                                value={indirizzo}
                                                ref={el => campo_indirizzo = el}
                                                onChange={(ev) => { setIndirizzo(ev.target.value) }}
                                                onKeyPress={handleKeyPress}
                                            />
                                            <Button onClick={localizzaSullaMappa}>
                                                Localizza sulla mappa <BsFillGeoAltFill /></Button>
                                        </InputGroup>
                                        {erroriCompilazione.indirizzo &&
                                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                                {erroriCompilazione.indirizzo}
                                            </strong>
                                            </Form.Label>}
                                        <ListGroup>
                                            {indirizziMultipli.map((el, index) => (
                                                <ListGroup.Item action key={index} onClick={() => {
                                                    setIndirizzo(el.display_name)
                                                    setPosizione([el.lat, el.lon])
                                                    setIndirizziMultipli([])
                                                    setErroriCompilazione("")
                                                    map.current.flyTo([el.lat, el.lon], 13)
                                                }}>{el.display_name}</ListGroup.Item>
                                            ))}
                                        </ListGroup>

                                    </Form.Group>

                                    <MapContainer className="mx-1 mt-1" style={{ height: "40vh" }} ref={map}
                                        center={props.comune.impostazioniComune && props.comune.impostazioniComune.imcCentroMappa.centro_mappa}
                                         zoom={13} scrollWheelZoom={false}>
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {posizione.length > 0 &&
                                            <Marker
                                                draggable={true}
                                                eventHandlers={eventHandlers}
                                                position={posizione}
                                                ref={markerRef}
                                            >
                                                <Popup minWidth={90}>
                                                    <span>
                                                        {indirizzo}
                                                    </span>
                                                </Popup>
                                            </Marker>
                                        }
                                    </MapContainer>

                                    <Form.Group className="mb-4 mt-4">
                                        <Form.Label>
                                            <strong>Categoria * </strong>
                                        </Form.Label>
                                        <Select
                                            options={categorie}
                                            placeholder="Seleziona la categoria"
                                            value={categoriaSelezionata}
                                            onChange={handleSelect}
                                            ref={el => select = el}
                                            isClearable={true}
                                            isSearchable={true}
                                            required
                                        />
                                        {erroriCompilazione.categoria &&
                                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                                {erroriCompilazione.categoria}
                                            </strong>
                                            </Form.Label>}
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Oggetto * </strong>
                                            <span className="text-muted smallText">(massimo: {max_char_titolo} caratteri)</span>
                                        </Form.Label>
                                        <Form.Control required placeholder="Inserisci l'oggetto della segnalazione"
                                            value={oggetto}
                                            maxLength={max_char_titolo}
                                            onChange={(ev) => { setOggetto(ev.target.value) }} />
                                        {erroriCompilazione.oggetto &&
                                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                                {erroriCompilazione.oggetto}
                                            </strong>
                                            </Form.Label>}
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Col xs={8}>
                                            <Form.Label>
                                                <OverlayTrigger placement="right" overlay={
                                                    <Popover id="popover-contained" >
                                                        <Popover.Header as="h3" >Aiuto:</Popover.Header>
                                                        <Popover.Body>
                                                            Esporre, nella maniera più precisa e puntuale possibile, il contenuto della petizione evidenziandone i punti salienti utili all’identificazione della problematica per la quale si richiede l’intervento dell’Amministrazione ovvero di eventuali proposte regolamentari.
                                                            E’ possibile corredare la petizione con atti, documenti e foto di supporto.
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                    <strong>
                                                        <strong>Descrizione * </strong>
                                                        <span className="text-muted smallText">(massimo: {max_char_descrizione} caratteri)</span>
                                                        <AiOutlineQuestionCircle className="me-2 ms-2 mb-1" style={{ fontSize: "20px" }} />
                                                    </strong>
                                                </OverlayTrigger>
                                            </Form.Label>
                                        </Col>

                                        <Form.Control className="no-resize" required as="textarea" placeholder="Inserisci la descrizione della petizione" rows={10}
                                            value={descrizione}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione(ev.target.value) }} />
                                        {erroriCompilazione.descrizione &&
                                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                                {erroriCompilazione.descrizione}
                                            </strong>
                                            </Form.Label>}
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Row>
                                            <Col xs={10} md={9}>
                                                <Form.Label>
                                                    <strong className="mediumText">Allegati *</strong>
                                                    <span className="text-muted smallText">(dimensione massima consentita 200kB, formati accettati:.png, .jpeg, .jpg)</span>
                                                </Form.Label>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <div>
                                                    <input
                                                        className="d-none"
                                                        type="file"
                                                        multiple={true}
                                                        ref={el => inputFile = el}
                                                        onChange={handleDisplayFileDetails} />
                                                    <Button
                                                        onClick={handleUpload}>
                                                        <FiUpload className="me-2" size="1em" /> Carica
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {erroriCompilazione.allegati &&
                                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                                {erroriCompilazione.allegati}
                                            </strong>
                                            </Form.Label>}
                                        {uploadedFilesMetadata.length > 0 && uploadedFilesMetadata.map((el, i) => (
                                            <ButtonGroup key={i} className="me-3 mb-3">
                                                <Button variant="outline-primary" > <BsFileEarmarkRichtext size="1.5em" /> {el.name}</Button>
                                                <Button variant="outline-danger" onClick={() => { deleteFile(el.name) }}> <GoTrash size="1.5em" /></Button>
                                            </ButtonGroup>
                                        ))}
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Email di contatto * </strong>
                                            <span className="text-muted smallText">(l'email che inserirai sarà utilizzata dal Comune per contattarti se necessario)</span>
                                        </Form.Label>
                                        <Form.Control required type="email" placeholder="Inserisci la tua email"
                                            value={email}
                                            onChange={(ev) => { setEmail(ev.target.value) }} />
                                        {erroriCompilazione.email &&
                                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                                {erroriCompilazione.email}
                                            </strong>
                                            </Form.Label>}

                                    </Form.Group>

                                    {messaggioAlert !== "" && <Alert variant="danger" >{messaggioAlert}</Alert>}
                                    <div className="d-flex justify-content-between">
                                        <Button variant="outline-dark"
                                            onClick={() => { setMostraModaleAnnulla(true) }}>
                                            Annulla
                                        </Button>
                                        <Button
                                            onClick={checkForm}>
                                            Invia la segnalazione!
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>

                        <ModalConferma
                            mostra={mostraModaleAnnulla}
                            setMostra={setMostraModaleAnnulla}
                            titolo="Annulla creazione della segnalazione"
                            messaggio={`Sei sicuro di voler annullare la creazione della segnalazione? Così facendo perderai tutto quello che hai scritto fino ad ora!`}
                            btnAnnulla="No, desidero continuare"
                            btnConferma="Sì, desidero annullare"
                            mode="danger"
                            azioneAnnulla={() => { setMostraModaleAnnulla(false) }}
                            azioneConferma={() => { navigate(-1) }}
                        />

                        <ModalConferma
                            mostra={mostraModaleConferma}
                            setMostra={setMostraModaleConferma}
                            titolo="Invia la segnalazione"
                            messaggio="Stai per inviare la segnalazione che hai appena creato. Non sarà più possibile modificarla."
                            btnAnnulla="Continua a modificare"
                            btnConferma="Invia"
                            mode="continua"
                            azioneAnnulla={() => { setMostraModaleConferma(false) }}
                            azioneConferma={() => { handleSubmit() }}
                        />

                        <ModalConferma
                            mostra={mostraModaleOk}
                            setMostra={setMostraModaleOk}
                            titolo="Segnalazione inviata"
                            messaggio="La segnalazione è stata inviata correttamente"
                            bodyAlign="text-center"
                            btnConferma="Torna alle segnalazioni"
                            btnAnnulla=""
                            mode="success"
                            azioneAnnulla={() => { }}
                            azioneConferma={() => { navigate(-1) }}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea la tua segnalazione</h1>
                        <Card className="me-5 mt-2 ms-5 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header></Card.Header>
                            <Card.Body className="p-5 text-center">
                                <h2>
                                    <FiAlertTriangle className="me-2 text-warning" />
                                    Attenzione
                                </h2>
                                <br />
                                Per procedere con la creazione di un nuova segnalazione devi prima effettuare il login!
                                <br /><br />
                                <Button variant="primary" onClick={goToLogin}>
                                    Accedi alla pagina di Login
                                </Button>
                            </Card.Body>
                        </Card>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}