import { statiPetizione } from "../../Utilities/statiElementEnum";
import { Card, Row, Col, ProgressBar, Button, Badge } from "react-bootstrap";
import { BsPersonCircle } from 'react-icons/bs'
import { FiChevronRight as Scopri } from 'react-icons/fi'
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getPetizioneColor } from "../../Utilities/getBorderColor";
import { useState } from "react";
import ModalPermessi from "../../Utilities/ModalPermessi";
import { getStatusPetizione } from '../../Utilities/getStatus';
import NumberFormat from "react-number-format"

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default function CartaPetizione(props) {

    const navigate = useNavigate();

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    //stati

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    //percentuale di completamento da mostrare sulla progress bar
    const getProgressBarValue = () => {
        if (
            props.petizione.firmePetizione &&
            props.comune &&
            props.comune.impostazioniComune
        )
            return (props.petizione.firmePetizione.length + props.petizione.petFirmeOffline) * 100 / props.comune.impostazioniComune.imcSogliaPetizioni
    }
    
    const IsLogged = () => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            navigate('/petizione', { state: { petId: props.petizione.petId } })
        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare la petizione. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }
    
    const permettiFirma = () => {
        let inizioFirme = dayjs(props.petizione.petInizioRaccoltaFirme)
        let fineFirme = dayjs(props.petizione.petFineRaccoltaFirme)
        return dayjs().isBetween(inizioFirme, fineFirme, null, [])
    }

    return (
        <Card className="cartaSommario w-100 mt-4 mb-2"
            border={getPetizioneColor(props.petizione)}
        >
            <Card.Body>
                <h5><strong>{props.petizione.petTitolo}</strong></h5>
                {!props.petizione.petFlAnonima ?
                    <p>
                        <BsPersonCircle className="me-2 m-auto" />
                        {props.petizione.partecipante && props.petizione.partecipante.datiPartecipante &&
                            props.petizione.partecipante.datiPartecipante.dparNome} {' '}
                        {props.petizione.partecipante && props.petizione.partecipante.datiPartecipante &&
                            props.petizione.partecipante.datiPartecipante.dparCognome} {' '}
                        {props.petizione.petAssociazione ? `per ${props.petizione.petAssociazione}` : ""}
                    </p>
                    :
                    <p><BsPersonCircle className="me-2 m-auto" />
                        Autore anonimo
                    </p>
                }
                <Row className="w-100 m-0 mt-2 p-0">
                    <Col xs={6} md={3}>
                        <strong>Pubblicata il:</strong>
                        <p>{dayjs(props.petizione.petDataPubblicazione).format("DD/MM/YYYY")}</p>
                    </Col>
                    <Col xs={6} md={3}>
                        <strong>Termine:</strong>
                        <p>{dayjs(props.petizione.petFineRaccoltaFirme).format("DD/MM/YYYY")}</p>
                    </Col>
                    <Col xs={6} md={3}>
                        <strong>Categoria:</strong>
                        <p>{props.petizione.petCategoria}</p>
                    </Col>

                    <Col xs={6} md={3} className="d-flex align-self-center">
                        <Badge pill
                            bg={getPetizioneColor(props.petizione)}
                            className="mt-auto mx-md-auto py-2 "
                            style={{ fontWeight: '100', fontSize: '0.80em' }}
                        >
                            {getStatusPetizione(props.petizione, props.comune)}
                        </Badge>
                    </Col>
                </Row>
            </Card.Body>
            {
                props.petizione.petStato !== statiPetizione.Rifiutata &&
                <Card.Footer className='text-center px-0 pb-3'>
                    <Row className='w-100 p-0 m-0 d-flex'>
                        <Col xs={12} md={9} className='pr-0'>
                            <span >
                                <strong className="bigText">
                                    <NumberFormat
                                        displayType="text"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        value={props.petizione.firmePetizione && props.petizione.firmePetizione.length + props.petizione.petFirmeOffline}
                                    />
                                </strong>/
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcSogliaPetizioni}
                                />
                            </span>
                            <ProgressBar variant="progressBar" now={getProgressBarValue()} />
                        </Col>
                        <Col xs={12} md={3} className="p-0 d-flex align-items-baseline mt-2">
                            <Button variant="outline-primary" size="sm" className="mt-auto mx-auto"
                                onClick={IsLogged}>
                                {props.petizione.petStato !== statiPetizione.Conclusa && permettiFirma()
                                    ? "Leggi e firma" : "Scopri"}
                                < Scopri />
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            }
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />

        </Card >
    )
}