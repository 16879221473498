import axios from 'axios'

const PROGETTO_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'progetto'

const headers = { 'Content-Type': 'application/json' }

class ProgettoService {

    creaProgetto(progetto) {
        return axios.post(PROGETTO_REST_API_URL + "/create", progetto, headers)
    }
    
    getProgetto(progId) {
        return axios.get(PROGETTO_REST_API_URL + `/${progId}`)
    }

    
    saveProgetto(progetto) {
        return axios.post(PROGETTO_REST_API_URL + "/save", progetto, headers)
    }

}

const progettoServiceInstance = new ProgettoService()
export default progettoServiceInstance;