import { useState } from "react";
import { Container, Row, Col, Dropdown, NavDropdown, Form, FormControl, Badge } from "react-bootstrap"
import { CloseButton, Button, Table, InputGroup, OverlayTrigger, Popover } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import AzioniProposte from "./AzioniProposte";
import { getPropostaColor } from '../../../Utilities/getBorderColor'
import { getStatusOrderProposta } from '../../../Utilities/getStatusOrder'
import { FiChevronLeft } from 'react-icons/fi'
import { statiProposta } from "../../../Utilities/statiElementEnum";
import { BsQuestionCircle } from "react-icons/bs"
import { PiFileCsv } from "react-icons/pi";import { convertPropostaToCSV } from "../../../Utilities/covertToCSV";
 const dayjs = require('dayjs');


export default function ProposteElenco(props) {

    let aree = (props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcAreeUrbane && props.comune.impostazioniComune.imcAreeUrbane.aree_urbane) || ["Caricamento..."]
    let proposte = props.bilanci.filter(el => el.bilId === props.bilId)[0].proposte
    let categorie = [...new Set([...(props.comune.impostazioniComune?.imcCategorieProposte?.categorie_proposte || []), ...proposte.flatMap(p => p.proCategorie.categorie)])].sort()

    const [titolo, setTitolo] = useState("")
     let [statoPropostaFilter, setStatoPropostaFilter] = useState("")
     let [areaFilter, setAreaFilter] = useState("")
    let [categoriaFilter, setCategoriaFilter] = useState("")

    const getProposte = () => {
        let ret = proposte

        if (statoPropostaFilter.length > 0)
            ret = ret.filter((pro) => pro.proStato === statoPropostaFilter)

        if (areaFilter.length > 0)
            ret = ret.filter((pro) => pro.proArea === areaFilter)

        if (categoriaFilter.length > 0)
            ret = ret.filter((pro) => pro.proCategorie.categorie.includes(categoriaFilter))

        if (titolo.length > 0)
            ret = ret.filter((pro => pro.proTitolo.toLowerCase().includes(titolo.toLowerCase())))

        return ret
    }

    const redirectToList = () => {
        props.setMostraTabella(true)
        props.setStatoPropostaFilter("")
        props.setAreaFilter("")
    }

    let filterByStatoProposta = (statoProposta) => {
        setStatoPropostaFilter(statoProposta)
    }

    let resetStatoProposta = () => {
        setStatoPropostaFilter("")
    }

    let filterByArea = (area) => {
        setAreaFilter(area)
    }

    let resetArea = () => {
        setAreaFilter("")
    }

    let filterByCategoria = (categoria) => {
        setCategoriaFilter(categoria)
    }
    let resetCategoria = (categoria) => {
        setCategoriaFilter("")
    }


    
    // Funzione per scaricare il CSV
    const handleDownloadReports = () => {
        const proposte = getProposte(); // Ottieni i dati delle proposte
        const csvContent = convertPropostaToCSV(proposte, props.bilanci.filter(el => el.bilId === props.bilId)[0].bilTitolo); // Converti i dati in CSV
        const url = window.URL.createObjectURL(new Blob([csvContent])); // Crea l'URL
        const link = document.createElement('a');
        const fileName = `Proposte_${props.bilanci.filter(el => el.bilId === props.bilId)[0].bilTitolo}_${dayjs(new Date()).format("DDMMYYHHmm")}.csv`;
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <>
            <Container className="p-0 px-md-4 py-md-0">
                <div className="d-flex justify-content-between">
                    <h2 className="title2">
                        Proposte per: {props.bilanci.filter(el => el.bilId === props.bilId)[0].bilTitolo}

                    </h2>
                    <OverlayTrigger trigger="click" rootClose placement="left"
                        overlay={
                            <Popover id="popover-contained" >
                                <Popover.Header as="h3">Aiuto:</Popover.Header>
                                <Popover.Body>
                                    Ecco tutte le proposte inviate per questo bilancio.
                                    <br />
                                    <br />
                                    Possibili <strong>stati</strong>:
                                    <ul>
                                        <li><strong>In validazione</strong>: proposta non visibile ai partecipanti</li>
                                        <li><strong>Rifiutata</strong>: proposta di cui è visualizzabile solo il titolo</li>
                                        <li><strong>Pubblicata</strong>: proposta visibile ai partecipanti</li>
                                        <li><strong>Vincitrice</strong>: proposta che è stata eletta come vincitrice</li>
                                    </ul>
                                    <br />
                                    Possibili <strong>azioni</strong>:
                                    <ul>
                                        <li><strong>Controlla</strong>: per rifiutare o pubblicare la proposta</li>
                                        <li><strong>Rispondi</strong>: per inserire la risposta del comune</li>
                                        <li><strong>Registra firme</strong>: per registrare le firme raccolte al di fuori della piattaforma</li>
                                    </ul>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <div className="my-auto" style={{ cursor: "pointer" }} >
                            <BsQuestionCircle size="2em" className="my-auto" />
                        </div>
                    </OverlayTrigger>
                </div>
                <Row className="d-flex justify-content-between my-4">
                    <Col>
                        <Dropdown className="d-inline" autoClose="inside">
                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                <span className="px-5">Filtra</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStatoProposta}>
                                    {Object.values(statiProposta)
                                        .map((stato, index) => (
                                            <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                        )
                                        )}
                                </NavDropdown>
                                <NavDropdown title="Area" className="noChangeOnClick" drop="end" onSelect={filterByArea}>
                                    {
                                        aree.map((area, index) => (
                                            <NavDropdown.Item key={index} eventKey={area}>{area}</NavDropdown.Item>
                                        ))
                                    }
                                </NavDropdown>
                                <NavDropdown title="Categoria" className="noChangeOnClick" drop="end" onSelect={filterByCategoria}>
                                    {
                                        categorie.map((categoria, index) => (
                                            <NavDropdown.Item key={index} eventKey={categoria}>{categoria}</NavDropdown.Item>
                                        ))
                                    }
                                </NavDropdown>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            statoPropostaFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-1 d-block">
                                Stato: <strong> {statoPropostaFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetStatoProposta} />
                                </Badge>
                            </Form.Label>
                        }
                        {
                            areaFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-1 d-block">
                                Area: <strong> {areaFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetArea} />
                                </Badge>
                            </Form.Label>
                        }
                         {
                            categoriaFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-1 d-block">
                                Categoria: <strong> {categoriaFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetCategoria} />
                                </Badge>
                            </Form.Label>
                        }
                    </Col>
                    <Col xs={12} md={5} className="d-flex justify-content-end">
                        <InputGroup className="mt-2 mt-md-0" style={{maxHeight:"1rem"}}>
                            <InputGroup.Text id="basic-addon1">
                                <GoSearch />
                            </InputGroup.Text>
                            <FormControl placeholder="Cerca titolo..."
                                onChange={(ev) => { setTitolo(ev.target.value) }} />
                        </InputGroup>
                    </Col>
                    <Col className="text-end">  
                        <Button onClick={handleDownloadReports} variant="primary" >
                            <PiFileCsv className="me-1" />
                            Esporta tabella in formato csv
                        </Button>
                    </Col>

                </Row>

                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Titolo</th>
                            <th>Autore</th>
                            <th>Categorie</th>
                            <th className="text-center">Area</th>
                            <th className="text-center">Stato</th>
                            <th className="text-center">Voti</th>
                            <th className="text-center nowrap">Pubblicata il</th>
                            <th className="text-center">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getProposte().length > 0 ? (
                                getProposte()
                                    .sort((a, b) => { return getStatusOrderProposta(a.proStato) - getStatusOrderProposta(b.proStato) })
                                    .map(proposta => (
                                        <tr key={proposta.proId}>
                                            <td>{proposta.proTitolo}</td>
                                            <td>{proposta.partecipante.datiPartecipante.dparNome} {proposta.partecipante.datiPartecipante.dparCognome}</td>
                                            <td>{proposta.proCategorie.categorie.join(", ")}</td>
                                            <td className="text-center">{proposta.proArea}</td>
                                            <td className="text-center">
                                                <Badge pill bg={getPropostaColor(proposta)} className="pillText" style={{ minWidth: "90%" }}>
                                                    {proposta.proStato}
                                                </Badge>
                                            </td>
                                            <td className="text-center">{proposta.proVotiOffline + proposta.votiProposta.length}</td>
                                            <td className="text-center">
                                                {proposta.proDataPubblicazione ? dayjs(proposta.proDataPubblicazione).format('DD/MM/YYYY') : "---"}
                                            </td>
                                            <td>
                                                <AzioniProposte
                                                    stato={proposta.proStato}
                                                    setGestisci={props.setGestisci}
                                                    setGestisciProposta={props.setGestisciProposta}
                                                    proposta={proposta}
                                                    setProposta={props.setProposta}
                                                />
                                            </td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan={8}>
                                        Nessuna proposta trovata
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <Row>
                    <Container className="d-flex justify-content-between p-0 m-0 mt-4 mb-5">
                        <Button variant="outline-dark" onClick={redirectToList}>
                            <FiChevronLeft />
                            Indietro
                        </Button>

                    </Container>
                </Row>
            </Container>
        </>
    )

}