import React from "react"
import { useEffect } from "react"
import { Row, Col, Form, Button, Container, Alert } from "react-bootstrap"
import NumberFormat from "react-number-format"

const dayjs = require('dayjs')
const customParseFormat = require('dayjs/plugin/customParseFormat')

export default function ApprovazioniReferendum(props) {
    let referendum = props.referendum
    let soglie = props.comune.impostazioniComune.soglie
    let soglieReferendum = referendum.soglieReferendum
    let firmeReferendum = referendum.firmeReferendum.length + referendum.refFirmeOffline

    dayjs.extend(customParseFormat)

    useEffect(() => {
        setTimeout(() => {
            props.setShowAlert({})
        }, 3000)
    }, [props])

    const mostraSoglia = (soglia) => {
        let sogliaPrec = soglie.filter((value) => value.sogActive === true && value.sogOrdine === (soglia.sogOrdine - 1))[0]

        if (sogliaPrec !== undefined) {
            if (
                firmeReferendum < sogliaPrec.sogValore ||
                soglieReferendum.filter((value) => value.soglia.sogId === sogliaPrec.sogId)[0] === undefined
            )
                return false
        }

        return true
    }

    const checkDataApprovazione = () => {
        if (
            dayjs(props.dataApprovazione, 'YYYY-MM-DD', true).isValid() === false ||
            dayjs(props.dataApprovazione).diff(dayjs(), "day") < 0
        )
            return false

        return true
    }

    const checkSogliaNonApprovata = (soglia) => {
        if (soglieReferendum.filter(el => el.soglia.sogId === soglia.sogId)[0] !== undefined)
            return false

        return true
    }

    const preparaApprovaSoglia = (soglia) => {
        if (props.enteApprovazione.length < 1)
            props.setShowAlert({ message: "Specificare l'ente che intende ammettere il referendum", variant: "danger" })
        else if (!checkDataApprovazione())
            props.setShowAlert({ message: "Specificare una data valida a partire da oggi", variant: "danger" })
        else if (!checkSogliaNonApprovata(soglia))
            props.setShowAlert({ message: "La soglia è già stata approvata", variant: "danger" })
        else {
            props.setSogliaToApprove(soglia)
            props.setMostraModaleOperazione(true)
            props.setParolaChiave("Ammetti soglia")
            props.setTitolo("Ammetti il referendum")
            props.setMessaggio("Sei sicuro di voler ammettere il referendum per questa soglia? L'operazione è irreversibile!")
        }
    }

    const preparaRifiutaSoglia = () => {
        props.setMostraModaleOperazione(true)
        props.setParolaChiave("Rifiuta ammissione")
        props.setTitolo("Rifiuta l'ammissione del referendum ")
        props.setMessaggio("Sei sicuro di voler rifiutare l'ammissione del referendum per questa soglia? L'operazione è irreversibile!")
    }
    
    return (
        <>
            <Row>
                <h5 className="mt-4 underline"><strong>Ammissioni</strong></h5>
            </Row>

            {
                soglie
                    .filter((value) => value.sogActive === true)
                    .sort((a, b) => { return a.sogOrdine - b.sogOrdine })
                    .map((soglia, index) => (
                        <Form.Group key={index} as={Row} className="mt-3 mb-2">
                            {
                                soglieReferendum.filter(el => el.soglia.sogId === soglia.sogId)[0] !== undefined ? (
                                    <Col>
                                        {
                                            soglieReferendum
                                                .filter(el => el.soglia.sogId === soglia.sogId)
                                                .map((sogliaReferendum, index) => (
                                                    <Row className="justify-content-md-center" key={index}>
                                                        <Col md={6}>
                                                            <Form.Label>
                                                                <strong>
                                                                    Ammesso da {sogliaReferendum.sorEnteApprovante} alle <NumberFormat
                                                                        displayType="text"
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        value={soglia.sogValore}
                                                                    /> firme:
                                                                </strong>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col md={2} className="right">
                                                            {dayjs(sogliaReferendum.sorData).format('DD/MM/YYYY')}
                                                        </Col>
                                                    </Row>
                                                ))
                                        }
                                    </Col>
                                ) : (
                                    mostraSoglia(soglia) === true &&
                                    <Col>
                                        <Row className="justify-content-md-center">
                                            <Col xs={12} md={6}>
                                                <Form.Label>
                                                    <strong>
                                                        Firme attuali:
                                                    </strong>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} md={6} className="text-md-right">
                                                <NumberFormat
                                                    displayType="text"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    value={firmeReferendum}
                                                />
                                                &nbsp;
                                                /
                                                &nbsp;
                                                <NumberFormat
                                                    displayType="text"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    value={soglia.sogValore}
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            firmeReferendum > (soglia.sogValore - 1) &&
                                            <Row>
                                                <Col>
                                                    <Row className="justify-content-md-center">
                                                        <Col md={5}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <Form.Label>
                                                                        <strong>Ammesso da:</strong>
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col md={8} className="right">
                                                                    <Form.Control
                                                                        type="text"
                                                                        size="sm"
                                                                        value={props.enteApprovazione}
                                                                        onChange={(ev) => props.setEnteApprovazione(ev.target.value)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <Form.Label>
                                                                        <strong>In data:</strong>
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col md={8} className="right">
                                                                    <Form.Control
                                                                        type="date"
                                                                        size="sm"
                                                                        value={props.dataApprovazione}
                                                                        onChange={(ev) => props.setDataApprovazione(ev.target.value)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-md-center mt-3">
                                                        <Col md={4}>
                                                            <Button
                                                                variant="outline-secondary"
                                                                onClick={preparaRifiutaSoglia}
                                                                size="sm"
                                                            >
                                                                Rifiuta
                                                            </Button>
                                                        </Col>
                                                        <Col md={4} className="right">
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => {
                                                                    preparaApprovaSoglia(soglia)
                                                                }}
                                                                size="sm"
                                                            >
                                                                Ammetti
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    {props.showAlert.message &&
                                                        <Container className="fixed-bottom w-50">
                                                            <Alert variant={props.showAlert.variant}>{props.showAlert.message}</Alert>
                                                        </Container>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                )
                            }
                        </Form.Group>
                    ))
            }
        </>
    )

}