import axios from 'axios'

const BILANCIO_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'iniziativa'

const headers = { 'Content-Type': 'application/json' }

class IniziativaService {
    getAll() {
        return axios.get(BILANCIO_REST_API_URL + "/all");
    }

    getAllByComune(comId) {
        return axios.get(BILANCIO_REST_API_URL + "/comune/" + comId);
    }

    getIniziativa(iniId) {
        return axios.get(BILANCIO_REST_API_URL + `/${iniId}`)
    }

    saveIniziativa(iniziativa) {
        return axios.post(BILANCIO_REST_API_URL + "/save", iniziativa, headers)
    }
}

const iniziativaServiceInstance = new IniziativaService();
export default iniziativaServiceInstance;