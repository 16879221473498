import React from "react"
import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, } from "react-bootstrap"
import Multiselect from 'multiselect-react-dropdown';
import { validationIconValid, validationIconNotvalid, iniziative, } from '../../../../utilities/icone'
import ModalConferma from "../../../Utilities/ModalConferma";
import { statiProgetto } from "../../../Utilities/statiElementEnum";
import allegatoProgettoServiceInstance from "../../../../services/AllegatoProgettoService";
import progettoServiceInstance from "../../../../services/ProgettoService"
import AzioniProgetto from "./AzioniProgetto";
import MappaEIndirizzi from "../../../Utilities/MappaEIndirizzi";
import { max_char_descrizione } from "../../../Utilities/variables";
import AllegatiProgetti from "./AllegatiProgetti";

export default function ProgettiGestisci(props) {
    const ref = useRef([]);

    const [statiForm, setStatiForm] = useState({
        autore: props.progetto.partecipante.datiPartecipante.dparNome + " " + props.progetto.partecipante.datiPartecipante.dparCognome,
        progAssociazione: props.progetto.progAssociazione,
        progCopresentatori: props.progetto.progCopresentatori,
        progDataInvio: props.progetto.progDataInvio,
        progArea: props.progetto.progArea,
        progCategorie: props.progetto.progCategorie,
        progTitolo: props.progetto.progTitolo,
        posizione: props.progetto.posizione ,
        progDescrizione1: props.progetto.progDescrizione1,
        progDescrizione2: props.progetto.progDescrizione2,
        progDescrizione3: props.progetto.progDescrizione3,
        progDescrizione4: props.progetto.progDescrizione4,
        allegatiProgetto: props.progetto.allegatiProgetto,
        progRisposta: props.progetto.progRisposta,
        partecipante: props.progetto.partecipante,
        progEmail: props.progetto.progEmail,
        iniziativa: { "iniId": props.iniId },
        progId: props.progetto.progId,
        progDataRisposta: props.progetto.progDataRisposta
    });

    const aggiornaCampoStatiForm = (campo, valore) => {
        setStatiForm(prev => ({
            ...prev,
            [campo]: valore
        }));
    };

    // ALLEGATI
    const [allegatiToDelete, setAllegatiToDelete] = useState([])


    useEffect(() => {
        setCategorieMS(props.comune.impostazioniComune.imcCategorieProgetti.categorie_progetti.map((el, i) => {
            return { "name": el, "id": i }
        }))
        let pos = {
            "posNome": props.comune.comNome ,
            "comId": props.comune.comId, 
            "posLongitudine":props.comune.impostazioniComune.imcCentroMappa.centro_mappa[1],
            "posLatitudine":props.comune.impostazioniComune.imcCentroMappa.centro_mappa[0],
        }
        if(!props.progetto.posizione){
            setStatiForm(old=>(
                {...old, "posizione":pos}
            ))
        }

        setIsReadOnly([statiProgetto.Rifiutato, statiProgetto.Pubblicato].includes(props.progetto.progStato))
        // eslint-disable-next-line
    }, [props.comune])


    function setPosizione(pos) {
        setStatiForm(old => (
            { ...old, "posizione": { ...old.posizione, "posLatitudine": pos[0], "posLongitudine": pos[1] } }
        ))
    }
    function setIndirizzo(ind) {
        setStatiForm(old => (
            { ...old, "posizione": { ...old.posizione, "posNome": ind } }
        ))
    }

    // CATEGORIE
    const [categorieMS, setCategorieMS] = useState([])
    function impostaValoreCategorie() {
        const selectedValues = categorieMS.filter(el => statiForm.progCategorie.categorie.includes(el.name));
        return selectedValues.length > 0 ? selectedValues : [{ name: "---", id: "---" }];
    }
    const selectCategoria = (_, selectedItem) => {
        setStatiForm(old => (
            { ...old, categorie: { "categorie": [...old.categorie.categorie, selectedItem['name']] } }));
        if (_.length > 0 && !!formErrors["categorie"]) {
            setFormErrors({ ...formErrors, categorie: null })
        }
    }

    const removeCategoria = (_, removedItem) => {
        setStatiForm(old => (
            { ...old, categorie: { "categorie": [...old.categorie.categorie.filter(el => el !== removedItem['name'])] } }
        ))
        if (validated && _.length === 0) {
            setFormErrors({
                ...formErrors,
                categorie: errors.categorie
            })
        }
    }

    //********************************************************************* */

    // VALIDAZIONE FORM
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({})

    const [isReadOnly, setIsReadOnly]= useState(false)

    const errors = {
        area: "Seleziona l'area",
        categorie: "Seleziona almeno una categoria",
        titolo: "Inserisci il titolo",
        descrizione: "Inserisci la descrizione",
        risposta: "Non puoi pubblicare una risposta vuota",
    }
    const mandatoryField = () => {
        if (["controlla", "rispondi"].includes(props.gestisciProgetto))
            return <span>*</span>

        return ""
    }
    const getValidationStyle = (field) => {
        return (validated ? (formErrors[field] ? "is-invalid" : "is-valid") : "")
    }


    // FINESTRE MODALI
    const [mostraModaleOperazione, setMostraModaleOperazione] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)
    const [parolaChiave, setParolaChiave] = useState("")
    const [modaleTitolo, setModaleTitolo] = useState("")
    const [modaleMessaggio, setModaleMessaggio] = useState("")

    // SALVATAGGIO PROPOSTA
    const handleSubmit = (saveType, e) => {
        e.preventDefault()

        const form = ref.current[0]

        setValidated(true);

        // Ottieni i nuovi errori
        let formErrors = checkProgetto()

        if (form.checkValidity() !== true || Object.keys(formErrors).length > 0) {
            // Presenza di errori: inserimento nell'oggetto Errors e submit bloccato
            setFormErrors(formErrors)
        } else {
            setMostraModaleOperazione(true)
            if (saveType === "pubblica") {
                setParolaChiave("Pubblica")
                setModaleTitolo("Pubblica progetto")
                setModaleMessaggio("Confermi la pubblicazione del progetto?")
            }
            if (saveType === "rispondi") {
                setParolaChiave("Rispondi")
                setModaleTitolo("Rispondi al progetto")
                setModaleMessaggio("Confermi la risposta al progetto?")
            }
        }
    }

    const checkProgetto = () => {
        let formErrors = {}

        if (statiForm.progArea === "")
            formErrors.area = errors.area

        if (statiForm.progCategorie.categorie.length === 0)
            formErrors.categorie = errors.categorie

        if (props.gestisciProgetto === "rispondi" && props.progetto.progStato !== statiProgetto.Rifiutato)
            if (statiForm.progRisposta === "")
                formErrors.risposta = errors.risposta

        return formErrors
    }

    const salvaProgetto = (saveType) => {
        let progetto = statiForm
        progetto.progStato = statiProgetto.Pubblicato
        saveType = saveType.toLowerCase()
        progettoServiceInstance.saveProgetto(progetto)
            .then((res) => {
                if (allegatiToDelete.length > 0)
                    allegatoProgettoServiceInstance.eliminaAllegati(allegatiToDelete)
            })
            .then(() => {
                props.setDirty(true)
                if (saveType === "pubblica") {
                    setModaleTitolo("Pubblica progetto")
                    setModaleMessaggio("Progetto pubblicato con successo")
                }
                else if (saveType === "rispondi") {
                    setModaleTitolo("Rispondi al progetto")
                    setModaleMessaggio("Progetto pubblicato con successo")
                }
                setMostraModaleOperazione(false)
                setMostraModaleSuccesso(true)
            })
            .catch(() => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                if (saveType === "pubblica") {
                    setModaleTitolo("Errore nella pubblicazione del progetto")
                    setModaleMessaggio("Si è verificato un errore durante la pubblicazione del progetto.")
                }
                if (saveType === "rispondi") {
                    setModaleTitolo("Errore nella risposta al progetto")
                    setModaleMessaggio("Si è verificato un errore durante la pubblicazione della risposta al progetto.")
                }
            })
    }

    const preparaRifiuta = () => {
        setMostraModaleOperazione(true)
        setParolaChiave("Rifiuta")
        setModaleTitolo("Rifiuta progetto")
        setModaleMessaggio("Confermi il rifiuto del progetto?")
    }

    const rifiutaProgetto = () => {
        let progetto = statiForm
        progetto.progStato = statiProgetto.Rifiutato
        progettoServiceInstance.saveProgetto(progetto)
            .then(() => {
                props.setDirty(true)
                setMostraModaleOperazione(false)
                setMostraModaleSuccesso(true)
                setModaleTitolo("Rifiuta progetto")
                setModaleMessaggio("Progetto rifiutato con successo")
            })
            .catch(() => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                setModaleTitolo("Errore nel rifiuto del progetto")
                setModaleMessaggio("Si è verificato un errore durante il rifiuto del progetto.")
            })
    }

    return (
        <>
            <Container>
                <h2 className="title2">
                    {props.gestisciProgetto === "controlla" &&
                        <span>Controlla il progetto</span>}
                    {props.gestisciProgetto === "rispondi" &&
                        <span>Rispondi al progetto</span>}
                </h2>
                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={el => ref.current[0] = el}>
                    <Row> {/* CHI */}
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label><strong>Chi propone: </strong></Form.Label>
                                <Form.Control readOnly value={statiForm.autore} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label><strong>Email di contatto</strong></Form.Label>
                                <Form.Control value={statiForm.progEmail} readOnly />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label><strong>Inviata il</strong></Form.Label>
                                <Form.Control type="date" value={statiForm.progDataInvio} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label><strong>Ente o Organizzazione rappresentata </strong></Form.Label>
                                <Form.Control value={statiForm.progAssociazione} onChange={(ev) => { aggiornaCampoStatiForm("progAssociazione", ev.target.value) }} readOnly={isReadOnly}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label><strong>Ci sono altri enti, soggetti o persone che presentano la proposta? </strong></Form.Label>
                                <Form.Control value={statiForm.progCopresentatori} onChange={(ev) => { aggiornaCampoStatiForm("progCopresentatori", ev.target.value) }} readOnly={isReadOnly}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row> {/* DOVE */}
                        <Form.Group className="mb-3">
                            <Form.Label><strong>Dove si trova la proposta: </strong>{mandatoryField()}</Form.Label>

                            <Form.Control required as="select" type="select" readOnly={isReadOnly}
                                value={statiForm.progArea}
                                onChange={(ev) => { aggiornaCampoStatiForm("progArea", ev.target.value) }}>
                                <option value="" disabled hidden>Selezionare il quartiere-rione tra quelli nella lista</option>
                                {props.comune && props.comune.impostazioniComune &&
                                    props.comune.impostazioniComune.imcAreeUrbane.aree_urbane.map((el, index) => (
                                        <option value={el} key={index}>{el}</option>
                                    ))}
                            </Form.Control>
                            <div className="val-cnt-1">
                                {formErrors.area && formErrors.area.length > 0 ? (
                                    <>
                                        <Form.Control.Feedback type='invalid' className="block">
                                            <strong>{formErrors.area}</strong>
                                        </Form.Control.Feedback>
                                        <div className="icon">{validationIconNotvalid}</div>
                                    </>
                                ) : (
                                    validated &&
                                    <div className="icon">{validationIconValid}</div>
                                )}

                            </div>
                        </Form.Group>
                        <Col xs={12} className="mb-3">
                            <Form.Group >
                                <Form.Label><strong>Indirizzo</strong></Form.Label>
                            </Form.Group>
                            {statiForm.posizione &&
                                <MappaEIndirizzi
                                    posizione={[statiForm.posizione.posLatitudine, statiForm.posizione.posLongitudine]}
                                    setPosizione={setPosizione}
                                    indirizzo={statiForm.posizione.posNome}
                                    setIndirizzo={setIndirizzo}
                                    isRequired={false}
                                />
                            }
                        </Col>

                    </Row>
                    <Row> {/* COSA */}
                        <Form.Group className="mb-3">
                            <Form.Label><strong>Quali di questi aspetti interessano la proposta?: </strong>{mandatoryField()}</Form.Label>
                            <div className="val-cnt-2">
                                <Multiselect
                                    displayValue="name"
                                    className={getValidationStyle('categorie')}
                                    onSelect={selectCategoria}
                                    onRemove={removeCategoria}
                                    selectedValues={impostaValoreCategorie()}
                                    options={categorieMS}
                                    placeholder="Seleziona le categorie"
                                />
                                {formErrors.categorie && formErrors.categorie.length > 0 ? (
                                    <>
                                        <Form.Control.Feedback type='invalid' className="block">
                                            <strong>{formErrors.categorie}</strong>
                                        </Form.Control.Feedback>
                                        <div className="icon">{validationIconNotvalid}</div>
                                    </>
                                ) : (
                                    validated &&
                                    <div className="icon">{validationIconValid}</div>
                                )}
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label><strong>Titolo: </strong>{mandatoryField()}</Form.Label>
                            <Form.Control  readOnly={isReadOnly} required value={statiForm.progTitolo} onChange={(ev) => { aggiornaCampoStatiForm("progTitolo", ev.target.value) }} />
                            <div className="invalid-feedback">
                                <strong>{errors.titolo}</strong>
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label><strong>Quali sono i problemi e i bisogni che la proposta vuole affrontare e che cosa vuole migliorare? * </strong></Form.Label>
                            <Form.Control required as="textarea" rows={5} readOnly={isReadOnly}
                                value={statiForm.progDescrizione1}
                                maxLength={max_char_descrizione}
                                onChange={(ev) => { aggiornaCampoStatiForm("progDescrizione1", ev.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>
                                <strong>Quali sono i luoghi, gli spazi e i servizi sui quali si concentra la proposta? * </strong>
                            </Form.Label>
                            <Form.Control required as="textarea" rows={5} readOnly={isReadOnly}
                                value={statiForm.progDescrizione2}
                                maxLength={max_char_descrizione}
                                onChange={(ev) => { aggiornaCampoStatiForm("progDescrizione2", ev.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>
                                <strong>A chi si rivolge la proposta? * </strong>
                            </Form.Label>
                            <Form.Control required as="textarea" rows={5} readOnly={isReadOnly}
                                value={statiForm.progDescrizione3}
                                maxLength={max_char_descrizione}
                                onChange={(ev) => { aggiornaCampoStatiForm("progDescrizione3", ev.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>
                                <strong>In breve, quali sono i punti di forza della proposta? * </strong>
                            </Form.Label>
                            <Form.Control required as="textarea" rows={5} readOnly={isReadOnly}
                                value={statiForm.progDescrizione4}
                                maxLength={max_char_descrizione}
                                onChange={(ev) => { aggiornaCampoStatiForm("progDescrizione4", ev.target.value) }} />
                        </Form.Group>

                    </Row>

                    <AllegatiProgetti
                        allegatiProgetto={statiForm.allegatiProgetto}
                        allegatiToDelete={allegatiToDelete}
                        setAllegatiToDelete={setAllegatiToDelete}
                        readOnly={false}
                    />

                    {(props.gestisciProgetto === "rispondi" && props.progetto.progStato !== statiProgetto.Rifiutato) &&
                        <>
                            <Row>
                                <Col>
                                    <h4 className="mt-4"><strong>Risposta: </strong></h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            as="textarea"
                                            rows={10} placeholder="Scrivi la risposta al progetto..."
                                            value={statiForm.progRisposta}
                                            onChange={(ev) => { aggiornaCampoStatiForm("progRisposta", ev.target.value) }}
                                            className="no-resize"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {formErrors.risposta && formErrors.risposta.length > 0 &&
                                <Form.Control.Feedback type='invalid' className="block">
                                    <strong>{formErrors.risposta}</strong>
                                </Form.Control.Feedback>
                            }
                        </>
                    }

                    <AzioniProgetto
                        gestisciProgetto={props.gestisciProgetto}
                        handleSubmit={handleSubmit}
                        preparaRifiuta={preparaRifiuta}
                        setGestisci={props.setGestisci}
                        stato={props.progetto.progStato}
                    />
                </Form>
            </Container >

            {/* modale di conferma operazione */}
            < ModalConferma
                mostra={mostraModaleOperazione}
                setMostra={setMostraModaleOperazione}
                mode={parolaChiave.toLowerCase() === "rifiuta" ? "danger" : "continua"}
                titolo={modaleTitolo}
                messaggio={modaleMessaggio}
                bodyAlign="text-center"
                btnConferma={`Si, ${parolaChiave.toLowerCase()}`
                }
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleOperazione(false) }}
                azioneConferma={() => {
                    if (parolaChiave.toLowerCase() === "rifiuta")
                        rifiutaProgetto()
                    else if (["pubblica", "rispondi"].includes(parolaChiave.toLowerCase()))
                        salvaProgetto(parolaChiave)
                }}
            />

            {/* modali di esito operazione */}
            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo={modaleTitolo}
                messaggio={modaleMessaggio}
                bodyAlign="text-center"
                btnConferma="Torna a tutti i progetti"
                azioneConferma={() => { props.setGestisci("progetti-elenco") }}
            />

            {/* modali di errore */}
            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo={modaleTitolo}
                messaggio={modaleMessaggio}
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => {
                    setMostraModaleErrore(false)
                }}
            />
        </>
    )
}