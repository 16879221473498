import axios from 'axios'

const BILANCIO_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'bilancio-partecipativo'

const headers = { 'Content-Type': 'application/json' }

class BilancioPartecipativoService {
    getAll() {
        return axios.get(BILANCIO_REST_API_URL + "/all");
    }

    getAllByComune(comId) {
        return axios.get(BILANCIO_REST_API_URL + "/comune/" + comId);
    }

    getBilancio(bilId) {
        return axios.get(BILANCIO_REST_API_URL + `/${bilId}`)
    }

    saveBilancio(bilancio) {
        return axios.post(BILANCIO_REST_API_URL + "/save", bilancio, headers)
    }
}

const bilancioPartecipativoServiceInstance = new BilancioPartecipativoService();
export default bilancioPartecipativoServiceInstance;