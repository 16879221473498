import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { controlla, rispondi, registra_voti, visualizza } from '../../../utilities/icone'
import { statiPetizione } from '../../Utilities/statiElementEnum.js'

export default function AzioniTabella(props) {

    const handleClick = (gestisci) => {
        props.setMostraTabella(false)
        props.setPetId(props.petId)
        props.setGestisci(gestisci)
    }

    return (
        <Container className="d-flex justify-content-around p-0 m-0">
            {props.stato === statiPetizione.InValidazione &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Controlla</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("controlla") }}
                    >
                        {controlla}
                    </Button>
                </OverlayTrigger>}
            {[statiPetizione.InCorso, statiPetizione.InAttesa].includes(props.stato) &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Rispondi</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("rispondi") }}
                    >
                        {rispondi}
                    </Button>
                </OverlayTrigger>
            }
            {[statiPetizione.InCorso, statiPetizione.InAttesa].includes(props.stato) &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Registra firme</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("firme") }}
                    >
                        {registra_voti}
                    </Button>
                </OverlayTrigger>
            }

            {[statiPetizione.FirmeNonRaggiunte, statiPetizione.Rifiutata, statiPetizione.Conclusa].includes(props.stato) &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Visualizza</Tooltip>}
                >
                    <Button
                        variant="noBackground"
                        onClick={() => { handleClick("visualizza") }}
                    >
                        {visualizza}
                    </Button>
                </OverlayTrigger>
            }
        </Container >
    )
}