import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import { FiChevronRight as Scopri } from 'react-icons/fi'
import dayjs from "dayjs";
import { BsPersonCircle } from 'react-icons/bs'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getProgettoColor } from "../../Utilities/getBorderColor";
import ModalPermessi from "../../Utilities/ModalPermessi";
import { statiProgetto } from "../../Utilities/statiElementEnum";

export default function CartaProgetto(props) {

    const navigate = useNavigate()

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    const IsLogged = () => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            navigate('/progetto', { state: { progId: props.progetto.progId, iniId: props.iniziativa.iniId} })
        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare il progetto. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    function getStringaPartecipanteAssociazioneCopresentatore(){
        let s = ""
        if(props.progetto.partecipante && props.progetto.partecipante.datiPartecipante)
            s =  props.progetto.partecipante.datiPartecipante.dparNome + " " + props.progetto.partecipante.datiPartecipante.dparCognome
        
        if(props.progetto.progAssociazione)
            s += " per "+props.progetto.progAssociazione
        if(props.progetto.progCopresentatori)
            s+= " con "+ props.progetto.progCopresentatori

        return s.length>100? s.slice(0,100)+"...": s

    }

    return (
        <Card className="cartaSommario w-100 mt-4 mb-2"
            border={getProgettoColor(props.progetto)}>

            <Card.Body>
                <h5><strong>{props.progetto.progTitolo}</strong></h5>
                <p>
                    <BsPersonCircle className="me-2 m-auto" />
                   {getStringaPartecipanteAssociazioneCopresentatore()}
                </p>
                <Row className="w-100 m-0 mt-3 p-0">
                    <Col sm={6} md={3}>
                        <strong>Pubblicata il:</strong>
                        <p>{dayjs(props.progetto.progDataPubblicazione).format("DD/MM/YYYY")}</p>
                    </Col>
                    <Col sm={6} md={3}>
                        <strong>Area urbana:</strong>
                        <p>{props.progetto.progArea}</p>
                    </Col>
                    <Col sm={6} md={3}>
                        <strong>Categorie:</strong>
                        <p>
                            {props.progetto.progCategorie.categorie.map((el, i) => (
                                <span key={i}>
                                    <span key={i}>{el}</span>
                                    {i !== props.progetto.progCategorie.categorie.length - 1 &&
                                        <span>, </span>}
                                </span>

                            ))}
                        </p>
                    </Col>

                    <Col sm={6} md={3} className="d-flex align-self-center">
                        <Badge pill
                            bg={getProgettoColor(props.progetto)}
                            className="mt-auto mx-auto py-2 "
                            style={{ fontWeight: '100', fontSize: '0.80em' }}>
                            {props.progetto.progStato}
                        </Badge>
                    </Col>
                </Row>
            </Card.Body>
            {props.progetto.progStato !== statiProgetto.Rifiutato &&
                <Card.Footer className='text-center px-0 pb-3'>
                    <Row className='w-100 p-0 m-0 d-flex'>
                        <Col xs={12} md={{span:3, offset:9}} className="p-0 d-flex align-items-baseline ">
                            <Button variant="outline-primary" size="sm" className="mt-auto mx-auto"
                                onClick={IsLogged}>
                                Leggi
                                < Scopri />
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            }
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </Card>
    )
}