import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import { FiChevronRight as Scopri } from 'react-icons/fi'
import dayjs from "dayjs";
import { BsPersonCircle } from 'react-icons/bs'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getPropostaColor } from "../../Utilities/getBorderColor";
import ModalPermessi from "../../Utilities/ModalPermessi";
import NumberFormat from "react-number-format"

import { RiVipCrownFill } from "react-icons/ri";
import { statiProposta } from "../../Utilities/statiElementEnum";

export default function CartaPropostaVincitrice(props) {

    const navigate = useNavigate()

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    const IsLogged = () => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            navigate('/proposta', { state: { proId: props.proposta.proId, bilId: props.bilancio } })
        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare la proposta. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    return (
        <Card className="cartaSommario w-100 mt-2 mb-2"
            border={getPropostaColor(props.proposta)}>

            <Card.Body>
                <Row>
                    <Col xs={9}>
                        <h5><strong>{props.proposta.proTitolo.length > 20 ? `${props.proposta.proTitolo.slice(0, 20)}...` : props.proposta.proTitolo}</strong></h5>
                    </Col>
                    <Col className="text-center">
                        <Badge pill
                            bg={getPropostaColor(props.proposta)}
                            className="mt-auto mx-auto py-2 "
                            style={{ fontWeight: '100', fontSize: '1.2em' }}>
                            <RiVipCrownFill />
                        </Badge>

                    </Col>

                </Row>

                <Row className="w-100 m-0 mt-3 p-0">
                    <Col xs={6}>
                            <BsPersonCircle className="me-2 m-auto" />
                                {props.proposta.partecipante && props.proposta.partecipante.datiPartecipante &&
                                    props.proposta.partecipante.datiPartecipante.dparNome} {' '}
                                {props.proposta.partecipante && props.proposta.partecipante.datiPartecipante &&
                                    props.proposta.partecipante.datiPartecipante.dparCognome} {' '}
                    </Col>

                    <Col xs={6}>
                        <strong>Pubblicata il:</strong>
                        <p>{dayjs(props.proposta.proDataPubblicazione).format("DD/MM/YYYY")}</p>
                    </Col>
                </Row>
                <Row className="w-100 m-0 mt-3 p-0">
                    <Col xs={6}>
                        <strong>Area urbana:</strong>
                        <p>{props.proposta.proArea}</p>
                    </Col>
                    <Col xs={6}>
                        <strong>Categorie:</strong>
                        <p>
                            {props.proposta.proCategorie.categorie.map((el, i) => (
                                <span key={i}>
                                    <span key={i}>{el}</span>
                                    {i !== props.proposta.proCategorie.categorie.length - 1 &&
                                        <span>, </span>}
                                </span>

                            ))}
                        </p>
                    </Col>
                </Row>
            </Card.Body>
            {props.proposta.proStato !== statiProposta.Rifiutata &&
                <Card.Footer className='text-center px-0 pb-3'>
                    <Row className='w-100 p-0 m-0 d-flex'>
                        <Col xs={12} md={9} className='pr-0'>
                            <span >
                                <strong className="bigText">
                                    <NumberFormat
                                        displayType="text"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        value={props.proposta.votiProposta && props.proposta.proVotiOffline + props.proposta.votiProposta.length}
                                    />
                                </strong>
                                {' '} voti
                            </span>
                        </Col>

                        <Col xs={12} md={3} className="p-0 d-flex align-items-baseline ">
                            <Button variant="outline-primary" size="sm" className="mt-auto mx-auto"
                                onClick={IsLogged}>
                                Leggi
                                {props.vota && ` e vota`}
                                < Scopri />
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            }
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </Card>
    )
}