import { Button, Card, Col, Row, Form, Collapse, Container, Spinner } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs"
import { useState, useEffect } from "react";
import ReferendaService from "../../../services/ReferendaService"
import CartaReferendum from "./CartaReferendum";
import { useNavigate, Navigate } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiFilter3Line } from "react-icons/ri"
import ModalPermessi from "../../Utilities/ModalPermessi";
import { getStatusOrderReferendum } from "../../Utilities/getStatusOrder";
import { getStatusReferendum } from '../../Utilities/getStatus';
import { statiReferendum, tipiReferendum } from "../../Utilities/statiElementEnum";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

export default function EsploraReferendum(props) {
    const navigate = useNavigate();

    const dayjs = require('dayjs');
    const [dateRange, setDateRange] = useState([null, null]);

    const [selectValue, setSelectValue] = useState(-1)
    const [checkboxes, setCheckboxes] = useState([false, false, false])
    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);

    //stati
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    //filtri
    let [statoFilter, setStatoFilter] = useState([])
    let [tipoFilter, setTipoFilter] = useState("")

    const [open, setOpen] = useState(false);

    let [listaReferendum, setListaReferendum] = useState([])
    let [loadingRef, setLoadingRef] = useState(true)

    // const [paginaNumero, setPaginaNumero] = useState(0)
    // const petPagina = 5
    // const paginaVisitata = paginaNumero * petPagina

    const pulisciFiltri = () => {
        setDateRange([null, null])
        setSelectValue(-1)
        setCheckboxes([false, false, false])
        setStatoFilter([])
        setTipoFilter("")
    }

    const getReferenda = () => {
        let ret = listaReferendum
            .map(ref => { return { ...ref, refStato: getStatusReferendum(ref, props.comune) } })
            .filter(referendum => referendum.refStato !== statiReferendum.InValidazione && referendum.refStato !== statiReferendum.Rifiutato)

        if (statoFilter.length > 0) {
            ret = ret.filter(ref => statoFilter.includes(ref.refStato))
        }

        if (tipoFilter.length > 0) {
            ret = ret.filter((ref) => ref.refTipo.toLowerCase() === tipoFilter.toLowerCase())
        }
        if (dateRange[0]) {
            let startDay = dayjs(dateRange[0])
            ret = ret.filter((ref) => (dayjs(ref.refDataPubblicazione).isAfter(startDay)) || dayjs(ref.refDataPubblicazione).isSame(startDay))
        }

        if (dateRange[1]) {
            let endDay = dayjs(dateRange[1])
            ret = ret.filter((ref) => (dayjs(ref.refDataPubblicazione).isBefore(endDay)) || dayjs(ref.refDataPubblicazione).isSame(endDay))
        }
        return ret
    }

    useEffect(() => {
        ReferendaService.findByComune(props.comune.comId)
            .then(res => {
                setListaReferendum(res.data)
            })
            .catch(() => { setListaReferendum([]) })
            .finally(() => { setLoadingRef(false) })
    }, [props.comune.comId]);

    // filtri
    let filterByStato = (stato, checked) => {
        if (checked === true) {
            if (statoFilter.filter(statoItem => statoItem === stato).length === 0)
                setStatoFilter(old => [...old, stato])
        } else {
            setStatoFilter(statoFilter.filter(statoItem => statoItem !== stato))
        }
    }

    let filterByTipo = (tipo) => {
        setTipoFilter(tipo)
    }


    const handleDateRangeChange = (update) => {
        if (update === null) {
            setDateRange([null, null])
        } else {
            setDateRange(update);
        }
    }

    const IsLogged = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlVisualizzatore && props.partecipante.ruolo.ruoFlPubblicatoreReferendum) {
                //caso 3
                navigate("/crea-referendum")
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per creare un referendum. L'operazione è bloccata!")
                setMode("danger")
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la creazione di un nuovo referendum devi prima effettuare il login!")
            setMode("warning")
        }
        setMostraModalPermessi(true)
    }

    const handleCheckbox = (index) => {
        let tmp = checkboxes
        tmp[index] = !tmp[index]
        setCheckboxes(tmp)
    }


    if (props.comune && props.comune.impostazioniComune) {
        if (props.comune.impostazioniComune.imcModuloReferendumAttivo) {
            return (
                <>
                    <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                    <h1 className="title mt-4 text-center dark">Referendum</h1>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "100vh" }}>
                        <Card.Body className="p-0 m-0">
                            {/* FILTRI A SCHERMO PICCOLO */}
                            <Row className="d-md-none w-100 p-0 m-0">
                                <Col xs={12} sm={6} className="p-1">
                                    <Button className="w-100 mb-2" onClick={IsLogged}>
                                        Crea il tuo referendum
                                        <BsPlusLg className="ms-2" />
                                    </Button>
                                </Col>
                                <Col xs={12} sm={6} className="p-1">
                                    <Button className="w-100" onClick={() => setOpen(!open)} >
                                        <RiFilter3Line className="me-2" />
                                        Mostra filtri
                                    </Button>
                                </Col>
                                <Collapse in={open} className="border-bottom pb-3">
                                    <div id="example-collapse-text">
                                        <h4 className="mt-4">Filtra per:</h4>
                                        <div className="divider my-4" />
                                        <Row>
                                            <Col>
                                                <p >Tipo</p>
                                                <Form.Select size="sm" className="mb-4"
                                                    value={selectValue}
                                                    onChange={(ev) => { filterByTipo(ev.target.value); setSelectValue(ev.target.value) }}>
                                                    <option value="-1" disabled hidden>Seleziona il tipo</option>
                                                    {Object.values(tipiReferendum).map((el, index) => (
                                                        <option value={el} key={index}>{el}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <p >Stato</p>
                                                <Form.Group>


                                                    {Object.values(statiReferendum)
                                                        .filter(tipo => ![statiReferendum.InValidazione, statiReferendum.Rifiutato, statiReferendum.InAttesa].includes(tipo))
                                                        .map((stato, index) => (
                                                            <Form.Check
                                                            key={index}
                                                                type="checkbox"
                                                                label={stato}
                                                                name={stato}
                                                                checked={checkboxes[index]}
                                                                onChange={(e) => {
                                                                    filterByStato(e.target.name, e.target.checked)
                                                                    handleCheckbox(index)
                                                                }}
                                                            />
                                                        )
                                                        )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <p >Data pubblicazione &nbsp;
                                        <span className="small text-muted ">(Da ... a)</span>
                                    </p>
                                    <div>
                                        <DateRangePicker className="w-100"
                                            id="datePickerPetizioni"
                                            onChange={(update) => { handleDateRangeChange(update) }}
                                            value={dateRange}
                                            returnValue="range"
                                            calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                            dayPlaceholder="gg"
                                            monthPlaceholder='mm'
                                            yearPlaceholder='aaaa'
                                            yearAriaLabel="year"
                                            format='dd/MM/y'
                                        />
                                    </div>
                                    </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center mt-4">
                                            <Button size="sm" variant="outline-primary"
                                                onClick={() => { pulisciFiltri() }}>
                                                Pulisci filtri di ricerca
                                            </Button>
                                        </div>

                                    </div>
                                </Collapse>
                            </Row>

                            <Row className="w-100 m-0 p-0" style={{ minHeight: "100vh" }}>
                                {/* FILTRI A SCHERMO GRANDE*/}
                                <Col md={3} className="bg-light d-none d-md-block">
                                    <div className="d-flex justify-content-center">
                                        <Button className="my-3"
                                            onClick={IsLogged}>
                                            Crea il tuo referendum
                                            <BsPlusLg className="ms-2" />
                                        </Button>
                                    </div>
                                    <h4 className="mt-4">Filtra per:</h4>
                                    <div className="divider my-4" />
                                    <p >Tipo</p>
                                    <Form.Select size="sm" className="mb-4"
                                        value={selectValue}
                                        onChange={(ev) => { filterByTipo(ev.target.value); setSelectValue(ev.target.value) }}>
                                        <option value="-1" disabled hidden>Seleziona il tipo</option>
                                        {Object.values(tipiReferendum).map((el, index) => (
                                            <option value={el} key={index}>{el}</option>
                                        ))}
                                    </Form.Select>

                                    <div className="divider my-4" />
                                    <p >Stato</p>
                                    <Form.Group>
                                        {Object.values(statiReferendum)
                                            .filter(tipo => ![statiReferendum.InValidazione, statiReferendum.Rifiutato, statiReferendum.InAttesa].includes(tipo))
                                            .map((stato, index) => (
                                                <Form.Check
                                                key={index}
                                                    type="checkbox"
                                                    label={stato}
                                                    name={stato}
                                                    checked={checkboxes[index]}
                                                    onChange={(e) => {
                                                        filterByStato(e.target.name, e.target.checked)
                                                        handleCheckbox(index)
                                                    }}
                                                />
                                            )
                                            )}
                                    </Form.Group>
                                    <div className="divider my-4" />
                                    <p >Data pubblicazione &nbsp;
                                        <span className="small text-muted ">(Da ... a)</span>
                                    </p>
                                    <div>
                                        <DateRangePicker className="w-100"
                                            id="datePickerPetizioni"
                                            onChange={(update) => { handleDateRangeChange(update) }}
                                            value={dateRange}
                                            returnValue="range"
                                            calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                            dayPlaceholder="gg"
                                            monthPlaceholder='mm'
                                            yearPlaceholder='aaaa'
                                            yearAriaLabel="year"
                                            format='dd/MM/y'
                                        />
                                    </div>
                                    <div className="divider my-4" />

                                    <div className="d-flex justify-content-center">
                                        <Button size="sm" variant="outline-primary"
                                            onClick={() => { pulisciFiltri() }}>
                                            Pulisci filtri di ricerca
                                        </Button>
                                    </div>
                                </Col>

                                {/* TUTTI I REFERENDUM */}
                                <Col md={9} xs>
                                    {loadingRef ?
                                        <Container className="text-center mt-5">
                                            <Spinner animation="border" role="status" />
                                        </Container>
                                        :
                                        <>
                                            {listaReferendum.length > 0 &&
                                                getReferenda()
                                                    .sort(function (a, b) {
                                                        return getStatusOrderReferendum(a.refStato) - getStatusOrderReferendum(b.refStato)
                                                    })
                                                    .map((referendum, index) => (
                                                        <CartaReferendum
                                                            key={index}
                                                            referendum={referendum}
                                                            comune={props.comune}
                                                            partecipante={props.partecipante}
                                                        />
                                                    ))
                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>

                    </Card>
                    <ModalPermessi
                        mostra={mostraModalPermessi}
                        setMostra={setMostraModalPermessi}
                        titolo={titolo}
                        messaggio={messaggio}
                        mode={mode}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}