import { Card, Row, Col, Button, Badge, ButtonGroup } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import PropostaService from "../../../services/PropostaService";
import VotiPropostaService from "../../../services/VotiPropostaService";
import { firmaPetizione } from "../../../utilities/icone";
import { getPropostaColor } from "../../Utilities/getBorderColor";
import { BsPersonCircle, BsGeoAlt } from 'react-icons/bs'
import { MdOutlineCategory } from 'react-icons/md'
import { FiChevronLeft } from 'react-icons/fi'
import { BiEraser } from "react-icons/bi"
import ModalConferma from "../../Utilities/ModalConferma";
import dayjs from "dayjs";
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService";
import { BsFileEarmarkRichtext } from "react-icons/bs"
import ModalPermessi from "../../Utilities/ModalPermessi";
import NumberFormat from "react-number-format"
import ListGroup from 'react-bootstrap/ListGroup';
import { Navigate } from 'react-router-dom';
var fileDownload = require('js-file-download');

export default function PaginaProposta(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [proposta, setProposta] = useState({})
    const [ricaricaProposta, setRicaricaProposta] = useState(true)
    const [bilancio, setBilancio] = useState({})

    // stati per aprire i modali
    const [mostraModaleVota, setMostraModaleVota] = useState(false)
    const [mostraModaleRevoca, setMostraModaleRevoca] = useState(false)
    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false)

    const [votoEspresso, setVotoEspresso] = useState(false)

    const [faseCorrente, setFaseCorrente] = useState({})


    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();


    const handleVoto = () => {
        const voto = {
            vproData: dayjs().format('YYYY-MM-DD'),
            proposta: proposta,
            partecipante: props.partecipante
        }
        VotiPropostaService.registraVoto(voto)
            .then(() => {
                setRicaricaProposta(true)
                setMostraModaleConferma(true)
            })
            .catch((err) => {
                //TODO: gestione dell'errore
            })
            .finally(() => {
                setMostraModaleVota(false)
            })
    }

    const handleRevoca = () => {
        const voto = {
            vproId: proposta.votiProposta.filter(voto => voto.partecipante.parId === props.partecipante.parId)[0].vproId,
            vproData: dayjs(proposta.votiProposta.filter(voto => voto.partecipante.parId === props.partecipante.parId)[0].data).format('YYYY-MM-DD'),
            partecipante: props.partecipante,
            proposta: proposta
        }
        VotiPropostaService.revocaVoto(voto)
            .then(() => {
                setRicaricaProposta(true)
                setVotoEspresso(false)
            })
            .catch((err) => {
                console.log(err)
                //TODO: gestione dell'errore
            })
            .finally(() => {
                setMostraModaleRevoca(false)
            })
    }

    async function download(arrayBuffer, name) {
        //FIXME: controllare il tipo del file, "data:file" deve essere parametrico rispetto al vero formato del file
        const base64Response = await fetch(`data:file;base64,${arrayBuffer}`);
        const blob = await base64Response.blob();
        fileDownload(blob, name);
    }

    //fetch proposta
    useEffect(() => {
        if (location.state && location.state.proId) {
            PropostaService.getProposta(location.state.proId)
                .then((res) => {
                    setProposta(res.data)
                    if (res.data.votiProposta &&
                        res.data.votiProposta.filter(voto => voto.partecipante.parId === props.partecipante.parId).length > 0) {
                        setVotoEspresso(true)
                    } else {
                        setVotoEspresso(false)
                    }
                })
                .catch((err) => {
                    setProposta({})
                    //TODO gestione dell'errore
                })
            setRicaricaProposta(false)

            BilancioPartecipativoService.getBilancio(location.state.bilId)
                .then((res) => {
                    setBilancio(res.data)
                    res.data.fasi && res.data.fasi.forEach((fase) => {
                        let inizioFase = dayjs(fase.fasDataInizio)
                        let fineFase = dayjs(fase.fasDataFine)
                        if (dayjs().isBetween(inizioFase, fineFase, null, [])) {
                            setFaseCorrente(fase)
                        }
                    })
                })
                .catch((err) => {
                    setProposta({})
                    //TODO gestione dell'errore
                })
        }
    }, [location.state, ricaricaProposta, props])

    const IsLogged = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlVotanteProposte && props.partecipante.ruolo.ruoFlVisualizzatore) {
                //caso 3
                setMostraModaleVota(true)
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per firmare una proposta. L'operazione è bloccata!")
                setMode("danger")
                setMostraModalPermessi(true)
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la firma di una proposta devi prima effettuare il login!")
            setMode("warning")
            setMostraModalPermessi(true)
        }
    }

    if (props.comune && props.comune.impostazioniComune) {
        if (
            props.comune.impostazioniComune.imcModuloBilanciAttivo &&
            location.state &&
            location.state.bilId
        ) {
            return (
                <>
                    <div className="pt-4 px-md-5 pb-2 px-2 bg-secondary">
                        <Row className="w-100 p-0 m-0">
                            <Col xs={7} className="p-0 align-self-center">
                                <span className="smallcaps dark">Proposta</span>
                                <h1 className="title dark">{proposta.proTitolo}</h1>
                                <h5 className="d-inline me-2"><Badge bg="primary">{props.comune.impostazioniComune.imcLabelModuloBilanci}: {bilancio.bilEdizione}</Badge></h5>
                                <h5 className="d-inline me-2"><Badge bg="primary">
                                    {
                                        proposta.proCategorie && proposta.proCategorie.categorie.map((el, i) => (
                                            <span key={i}>
                                                <span key={i}>{el}</span>
                                                {i !== proposta.proCategorie.categorie.length - 1 &&
                                                    <span>, </span>}
                                            </span>

                                        ))
                                    }
                                </Badge></h5>
                            </Col>
                            <Col xs={12} md={5} className="p-0">
                                <Card className="text-center cartaSommario"
                                    border={getPropostaColor(proposta)}
                                >
                                    <Card.Body>
                                        {faseCorrente &&
                                            <>
                                                <span className="smallcaps smallText text-start">Fase {faseCorrente.fasOrdine} di {bilancio.fasi && bilancio.fasi.length}</span>
                                                <h3 className="smallcaps title m-0">{faseCorrente.fasTitolo}</h3>
                                                <Row>
                                                    <span>{dayjs(faseCorrente.fasDataInizio).format('DD/MM/YYYY')} - {dayjs(faseCorrente.fasDataFine).format('DD/MM/YYYY')}</span>
                                                    <span className="mt-2 mb-2">
                                                        Votata <strong className="bigText">
                                                            <NumberFormat
                                                                displayType="text"
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                value={proposta.votiProposta && proposta.votiProposta.length + proposta.proVotiOffline}
                                                            />
                                                        </strong>
                                                        {' '} volte
                                                    </span>
                                                </Row>
                                            </>
                                        }

                                        {!votoEspresso && faseCorrente.fasFlVotazioni &&
                                            //se non ho espresso il voto oppure se la fase corrente permette votazioni
                                            <Row>
                                                <div>
                                                    <Button className="w-20"
                                                        onClick={IsLogged}>
                                                        Vota la proposta{firmaPetizione}</Button>
                                                </div>
                                            </Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "50vh" }}>
                        <Card.Header />
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={7}>
                                    <p className="text-center">
                                        <BsPersonCircle className="me-2 m-auto" />
                                        {proposta.partecipante && proposta.partecipante.datiPartecipante &&
                                            proposta.partecipante.datiPartecipante.dparNome} {' '}
                                        {proposta.partecipante && proposta.partecipante.datiPartecipante &&
                                            proposta.partecipante.datiPartecipante.dparCognome} {' '}
                                        {proposta.proAssociazione ? `per ${proposta.proAssociazione}` : ""}
                                    </p>

                                    <Card>
                                        <Card.Header>{proposta.proTitolo}</Card.Header>
                                        <Card.Body>
                                            {proposta.proDescrizione && proposta.proDescrizione.split("\n").map((val, index) => (
                                                <span key={index}>{val} <br /></span>
                                            ))}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={{ span: 9, offset: 1 }} md={{ span: 4, offset: 1 }} className="text-center">
                                    <Row>
                                        <Col xs={{ span: 9, offset: 2 }} md={{ span: 7, offset: 1 }} className="text-center">
                                            <ListGroup variant="flush" >
                                                &nbsp;
                                                <ListGroup.Item>
                                                    {proposta.proCategorie && proposta.proCategorie.categorie.map((el, i) => (
                                                        <span key={i}>
                                                            <span>{el}</span>
                                                            {i !== proposta.proCategorie.categorie.length - 1 &&
                                                                <span>, </span>
                                                            }
                                                        </span>
                                                    ))
                                                    }
                                                    &nbsp;
                                                    <MdOutlineCategory />
                                                </ListGroup.Item>
                                                <ListGroup.Item> <p>{proposta.proArea && proposta.proArea} <BsGeoAlt /></p>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className=" mt-3 divider" />
                            <h2 className="title2 text-start">Allegati</h2>
                            {proposta.allegati && proposta.allegati.map((file, i) => (
                                <ButtonGroup key={i} className="me-3 mb-3 ms-3" onClick={() => { download(file.allFile, file.allNome) }}>
                                    <Button variant="outline-primary" >
                                        <BsFileEarmarkRichtext size="1.5em" />
                                        {file.allNome}</Button>
                                </ButtonGroup>
                            ))}

                            {proposta.proRisposta &&
                                <>
                                    <div className="divider" />
                                    <h2 className="title2 text-start mb-3">Risposta dal comune</h2>
                                    <p className="text-start">Pubblicata in data {dayjs(proposta.proDataRisposta).format('DD/MM/YYYY')}</p>
                                    <Card>
                                        <Card.Body className="text-justify">
                                            {proposta.proRisposta && proposta.proRisposta.split("\n").map((val, index) => (
                                                <span key={index}>{val} <br /></span>
                                            ))}
                                        </Card.Body>
                                    </Card>
                                </>
                            }
                            <Col className=" mt-5 text-center">
                                {faseCorrente.fasFlVotazioni &&
                                    //se si può votare mostro il pulsante
                                    !votoEspresso &&
                                    <>
                                        <p>Questa proposta ti ha convinto?</p>
                                        <Button
                                            onClick={IsLogged}>
                                            Vota adesso!
                                            <span className="m-2">{firmaPetizione}</span>
                                        </Button>
                                    </>
                                }{
                                    votoEspresso &&
                                    <>
                                        <p>Hai <strong>votato</strong> questa proposta in data <strong>{dayjs(proposta.votiProposta.filter(voto => voto.partecipante.parId === props.partecipante.parId)[0].vproData).format('DD/MM/YYYY')}</strong></p>
                                        {props.comune && props.comune.impostazioniComune &&
                                            props.comune.impostazioniComune.imcFlRitiraFirme &&
                                            <Button variant="outline-danger"
                                                onClick={() => { setMostraModaleRevoca(true) }}>
                                                Revoca il voto
                                                <BiEraser className="ms-2" size={25} />
                                            </Button>
                                        }
                                    </>
                                }

                            </Col>
                        </Card.Body>
                        <Card.Footer />
                    </Card>
                    <Button variant="outline-dark" className="ms-5 mb-3"
                        onClick={() => {
                            navigate(-1)
                        }}>
                        <FiChevronLeft />
                        Torna a tutte le proposte</Button>

                    <ModalConferma
                        mostra={mostraModaleVota}
                        setMostra={setMostraModaleVota}
                        titolo="Vota la proposta"
                        messaggio={`Stai per votare la proposta "${proposta.proTitolo}". Sei sicuro?`}
                        btnAnnulla="No, annulla"
                        btnConferma="Si, vota"
                        mode="continua"
                        azioneAnnulla={() => { setMostraModaleVota(false) }}
                        azioneConferma={() => { handleVoto() }}
                    />

                    <ModalConferma
                        mostra={mostraModaleConferma}
                        setMostra={setMostraModaleConferma}
                        titolo="Firmata con successo!"
                        messaggio={`La proposta è stata votata con successo!`}
                        btnConferma="Chiudi"
                        mode="success"
                        azioneConferma={() => { setMostraModaleConferma(false) }}
                    />

                    <ModalConferma
                        mostra={mostraModaleRevoca}
                        setMostra={setMostraModaleRevoca}
                        titolo="Revoca il voto espresso"
                        messaggio={`Stai per revocare il voto espresso per la proposta "${proposta.proTitolo}". Sei sicuro?`}
                        btnAnnulla="No, non revocare"
                        btnConferma="Si, revoca voto"
                        mode="danger"
                        azioneAnnulla={() => { setMostraModaleRevoca(false) }}
                        azioneConferma={() => { handleRevoca() }}
                    />
                    <ModalPermessi
                        mostra={mostraModalPermessi}
                        setMostra={setMostraModalPermessi}
                        titolo={titolo}
                        messaggio={messaggio}
                        mode={mode}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
} 