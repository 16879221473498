import { Col, Button, Container, Offcanvas, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi"
import { BiTachometer } from "react-icons/bi"
import { FaRegFlag } from "react-icons/fa"
import cruscotto from '../../immagini/icone_sidebar/cruscotto.png'
import cruscotto_dark from '../../immagini/icone_sidebar/cruscotto_dark.png'
import petizioni from '../../immagini/icone_sidebar/petizioni.png'
import petizioni_dark from '../../immagini/icone_sidebar/petizioni_dark.png'
import referendum from '../../immagini/icone_sidebar/referendum.png'
import referendum_dark from '../../immagini/icone_sidebar/referendum_dark.png'
import bp from '../../immagini/icone_sidebar/bilancio_partecipativo.png'
import bp_dark from '../../immagini/icone_sidebar/bilancio_partecipativo_dark.png'
import partecipanti from '../../immagini/icone_sidebar/partecipanti.png'
import partecipanti_dark from '../../immagini/icone_sidebar/partecipanti_dark.png'
import ig from '../../immagini/icone_sidebar/impostazioni_generali.png'
import ig_dark from '../../immagini/icone_sidebar/impostazioni_generali_dark.png'
import {iniziative_white as iniziative_icon_white}  from './../../utilities/icone'
import {iniziative as iniziative_icon}  from './../../utilities/icone'


import { useNavigate, useLocation } from 'react-router-dom';

export default function Sidebar(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const location = useLocation();
  const goToPath = (path) => {
    //eseguo confronto location.pathname con path desiderato
    const url = location.pathname
    if(path.includes(url)){
      if(path.includes("petizion")){
        props.setMostraTabellaPetizioni(true)
      }else if(path.includes("referendum")){
        props.setMostraTabellaReferendum(true)
      }else if(path.includes("segnalazion")){
        props.setMostraTabellaSegnalazioni(true)
      }else if(path.includes("bilanc")){
        props.setMostraTabellaBilanci(true)
      }else if(path.includes("iniziativ")){
        props.setMostraTabellaIniziative(true)
      }
    }
    else{
      //resetto la visualizzazione della pagina con la tabella per tutte le sezioni
      props.setMostraTabellaPetizioni(true)      
      props.setMostraTabellaReferendum(true)
      props.setMostraTabellaSegnalazioni(true)
      props.setMostraTabellaBilanci(true)
      props.setMostraTabellaIniziative(true)
      navigate(path)
    }
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {
  return (
    <>
      <Col className="bg-primary w-auto d-none d-md-block sidebar" md={1} style={{ height: "100vh", padding: "0px", paddingLeft: "8px", position: "sticky", top: 0 }} >
        <Container className="d-flex justify-content-center p-1 mt-4">
          <OverlayTrigger placement="right" overlay={
            <Tooltip>Vai alla homepage </Tooltip>
          }>
            <Button variant="secondary" className="mt-4 p-0 py-1 d-flex justify-content-start"
              onClick={() => { goToPath("/") }} >
              <img src={cruscotto} alt="Homepage" />
            </Button>
          </OverlayTrigger>
        </Container>

        <Container className="d-flex justify-content-center p-0">
          <OverlayTrigger placement="right" overlay={
            <Tooltip>Cruscotto </Tooltip>
          }>
            <Button className="mt-4 p-0 py-1 d-flex justify-content-start"
              onClick={() => { goToPath("/admin/cruscotto") }} active={location.pathname === "/admin/cruscotto"}>
              <BiTachometer size="2.3em" className='mx-1' style={{ opacity: "0.7" }} />
            </Button>
          </OverlayTrigger>
        </Container>

        {
          props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
          <Container className="d-flex justify-content-center p-0">
            <OverlayTrigger placement="right" overlay={
              <Tooltip>Petizioni </Tooltip>
            }>
              <Button className="mt-4 p-0 py-1 d-flex justify-content-start"
                onClick={() => {
                  goToPath("/admin/petizioni#tutte-le-petizioni")
                  props.setTabAttivaPetizioni("petizioni")
                }} active={location.pathname === "/admin/petizioni"}>
                <img src={petizioni} alt="Petizioni" />
              </Button>
            </OverlayTrigger>
          </Container>
        }

        {
          props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo &&
          <Container className="d-flex justify-content-center p-0">
            <OverlayTrigger placement="right" overlay={
              <Tooltip>Referendum </Tooltip>
            }>
              <Button className="mt-4 d-block p-0 py-1 d-flex justify-content-start"
                onClick={() => {
                  goToPath("/admin/referendum#tutti-i-referendum")
                  props.setTabAttivaReferendum("referenda")
                }} active={location.pathname === "/admin/referendum"}>
                <img src={referendum} alt="Referendum" />
              </Button>
            </OverlayTrigger>
          </Container>
        }

        {
          props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
          <Container className="d-flex justify-content-center p-0">
            <OverlayTrigger placement="right" overlay={
              <Tooltip>Segnalazioni </Tooltip>
            }>
              <Button className="mt-4 d-block p-1 d-flex justify-content-start"
                onClick={() => {
                  goToPath("/admin/segnalazioni#tutte-le-segnalazioni")
                  props.setTabAttivaSegnalazioni("segnalazioni")
                }} active={location.pathname === "/admin/segnalazioni"}>
                <FaRegFlag size="1.7em" className='mx-1' style={{ opacity: "0.7" }} />
              </Button>
            </OverlayTrigger>
          </Container>
        }

        {
          props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo &&
          <Container className="d-flex justify-content-center p-0">
            <OverlayTrigger placement="right" overlay={
              <Tooltip>{props.comune.impostazioniComune.imcLabelModuloBilanci}</Tooltip>
            }>
              <Button className="mt-4 d-block p-0 py-1 d-flex justify-content-start"
                onClick={() => {
                  goToPath("/admin/bilanci-partecipativi#tutti-i-bilanci")
                  props.setTabAttivaBilanci("bilanciPartecipativi")
                }} active={location.pathname === "/admin/bilanci-partecipativi"}>
                <img src={bp} alt="Bilancio Partecipativo" />
              </Button>
            </OverlayTrigger>
          </Container>
        }
{
          props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloIniziativeAttivo &&
          <Container className="d-flex justify-content-center p-0">
            <OverlayTrigger placement="right" overlay={
              <Tooltip>{props.comune.impostazioniComune.imcLabelModuloIniziative}</Tooltip>
            }>
              <Button className="mt-4 d-block px-2 py-1 d-flex justify-content-start"
                onClick={() => {
                  goToPath("/admin/iniziative#tutte-le-iniziative")
                  props.setTabAttivaIniziative("iniziative")
                }} active={location.pathname === "/admin/iniziative"}>
               {iniziative_icon_white}
              </Button>
            </OverlayTrigger>
          </Container>
        }

        <Container className="d-flex justify-content-center p-0">
          <OverlayTrigger placement="right" overlay={
            <Tooltip>Partecipanti </Tooltip>
          }>
            <Button className="mt-4 d-block p-0 py-1   d-flex justify-content-start"
              onClick={() => { goToPath("/admin/partecipanti") }} active={location.pathname === "/admin/partecipanti"}>
              <img src={partecipanti} alt="Partecipanti" />
            </Button>
          </OverlayTrigger>
        </Container>

        <Container className="d-flex justify-content-center p-0">
          <OverlayTrigger placement="right" overlay={
            <Tooltip>Impostazioni Generali </Tooltip>
          }>
            <Button className="mt-4 d-block p-0 py-1 d-flex justify-content-start"
              onClick={() => {
                goToPath("/admin/impostazioni-generali#impostazioni-comune")
                props.setTabAttivaImpostazioni("impostazioni-comune")
              }} active={location.pathname === "/admin/impostazioni-generali"}>
              <img src={ig} alt="Impostazioni Generali" />
            </Button>
          </OverlayTrigger>
        </Container>
      </Col>

      {/* VERSIONE A SCHERMO PICCOLO */}
      <Container className="mt-2 ms-2 d-block d-md-none">
        <Button variant="outline-primary" onClick={handleShow} className="me-5" >
          <GiHamburgerMenu />
        </Button>
      </Container>
      <Offcanvas show={show} onHide={handleClose} placement="top" className="h-100">
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <ListGroup variant="flush sidebar-listGroup">

            <ListGroup.Item action variant="secondary"
              onClick={() => { goToPath("/"); handleClose() }} >
              <img src={cruscotto_dark} alt="Cruscotto" />
              <span className='mt-auto' style={{ marginRight: "10px" }}>Vai alla homepage </span>
            </ListGroup.Item>

            <ListGroup.Item action
              onClick={() => { goToPath("/admin/cruscotto"); handleClose() }} active={location.pathname === "/admin/cruscotto"}>
              <BiTachometer size="2.4em" style={{ marginLeft: "10px", marginRight: "10px", width: "35", height: "35" }} />
              {/* <img src={url === "/admin/cruscotto" ? cruscotto : cruscotto_dark} width="30" height="30" style={{ marginLeft: "10px", marginRight: "10px" }}
                alt="Cruscotto" /> */}
              <span className='mt-auto' style={{ marginRight: "10px" }}>Cruscotto </span>
            </ListGroup.Item>

            {
              props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
              <ListGroup.Item action
                onClick={() => {
                  goToPath("/admin/petizioni#tutte-le-petizioni")
                  props.setTabAttivaPetizioni("petizioni")
                  handleClose()
                }} active={location.pathname === "/admin/petizioni"}>
                <img src={location.pathname === "/admin/petizioni" ? petizioni : petizioni_dark} alt="Petizioni" />
                <span className='mt-auto' style={{ marginRight: "10px" }}>Petizioni </span>
              </ListGroup.Item>
            }

            {
              props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo &&
              <ListGroup.Item action
                onClick={() => {
                  goToPath("/admin/referendum#tutti-i-referendum")
                  props.setTabAttivaReferendum("referenda")
                  handleClose()
                }} active={location.pathname === "/admin/referendum"}>
                <img src={location.pathname === "/admin/referendum" ? referendum : referendum_dark} alt="Referendum" />
                <span className='mt-auto' style={{ marginRight: "10px" }}>Referendum </span>
              </ListGroup.Item>
            }

            {
              props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
              <ListGroup.Item action
                onClick={() => {
                  goToPath("/admin/segnalazioni#tutte-le-segnalazioni")
                  props.setTabAttivaSegnalazioni("segnalazioni")
                  handleClose()
                }} active={location.pathname === "/admin/segnalazioni"}>
                <FaRegFlag size="2em" style={{ marginLeft: "10px", marginRight: "10px", opacity: "0.8" }} />
                <span className='mt-auto' style={{ marginRight: "10px" }}>Segnalazioni </span>
              </ListGroup.Item>
            }
            {
              props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo &&
              <ListGroup.Item action
                onClick={() => {
                  goToPath("/admin/bilanci-partecipativi#tutti-i-bilanci")
                  props.setTabAttivaBilanci("bilanciPartecipativi")
                  handleClose()
                }} active={location.pathname === "/admin/bilanci-partecipativi"}
              >
                <img src={location.pathname === "/admin/bilanci-partecipativi" ? bp : bp_dark} alt="Bilancio Partecipativo" />
                <span className='mt-auto' style={{ marginRight: "10px" }}>{props.comune.impostazioniComune.imcLabelModuloBilanci}</span>
              </ListGroup.Item>
            }

{
              props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloIniziativeAttivo &&
              <ListGroup.Item action
                onClick={() => {
                  goToPath("/admin/iniziative#tutte-le-iniziative")
                  props.setTabAttivaIniziative("iniziative")
                  handleClose()
                }} active={location.pathname === "/admin/iniziative"}
              >
                <span className='mx-2'>
                  {location.pathname === "/admin/bilanci-partecipativi" ? iniziative_icon_white : iniziative_icon}
                  </span>
                <span className='mt-auto' style={{ marginRight: "10px" }}>{props.comune.impostazioniComune.imcLabelModuloIniziative}</span>
              </ListGroup.Item>
            }

            <ListGroup.Item action
              onClick={() => { goToPath("/admin/partecipanti"); handleClose() }} active={location.pathname === "/admin/partecipanti"}>
              <img src={location.pathname === "/admin/partecipanti" ? partecipanti : partecipanti_dark} alt="Partecipanti" />
              <span className='mt-auto' style={{ marginRight: "10px" }}>Partecipanti </span>
            </ListGroup.Item>

            <ListGroup.Item action
              onClick={() => {
                goToPath("/admin/impostazioni-generali#impostazioni-comune")
                props.setTabAttivaImpostazioni("impostazioni-comune")
                handleClose()
              }} active={location.pathname === "/admin/impostazioni-generali"}
            >
              <img src={location.pathname === "/admin/impostazioni-generali" ? ig : ig_dark} alt="Impostazioni Generali" />
              <span className='mt-auto' style={{ marginRight: "10px" }}>Impostazioni Generali</span>
            </ListGroup.Item>

          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
}