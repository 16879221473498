import { useState, useEffect } from "react"
import { Row, Col, Dropdown, NavDropdown, Button, Table, Form, FormControl, Badge, CloseButton, InputGroup } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { IoMdAdd } from "react-icons/io"
import {  getIniziativaColor } from '../../Utilities/getBorderColor'
import {  getStatusIniziativa } from "../../Utilities/getStatus"
import {  getStatusOrderIniziativa } from "../../Utilities/getStatusOrder";
import dayjs from "dayjs";
import {  statiIniziativa } from "../../Utilities/statiElementEnum";
import { convertIniziativeToCSV } from "../../Utilities/covertToCSV";
import { PiFileCsv } from "react-icons/pi";
import AzioniTabella from "./AzioniTabella"

export default function TabellaIniziative(props) {

    const[iniziative, setIniziative]=useState([])
    const [titolo, setTitolo] = useState("")

    const getIniziative = () => {
        let ret = iniziative.map(ini => {
            if (ini.iniStato === statiIniziativa.InCorso) {
                let date1 = dayjs()
                let date2 = dayjs(ini.iniDataChiusura)
                if (date1.diff(date2) > 0)
                    return { ...ini, iniStato: statiIniziativa.Conclusa }
                else
                    return ini
            }
            return ini
        })

        if (props.statoFilter.length > 0)
            ret = ret.filter((ini) => ini.iniStato === props.statoFilter)

        if (titolo)
            ret = ret.filter((ini) => ini.iniTitolo.toLowerCase().includes(titolo.toLowerCase()))
        return ret
    }

    const addIniziativa = () => {
        props.setMostraTabella(false)
        props.setGestisci("addNew")
    }

    let filterByStato = (stato) => {
        props.setStatoFilter(stato)
    }

    let resetStato = () => {
        props.setStatoFilter("")
    }

 // Funzione per scaricare il CSV
 //TODO
 const handleDownloadReports = () => {
    const csvContent = convertIniziativeToCSV(props.iniziative); // Converti i dati in CSV
    const url = window.URL.createObjectURL(new Blob([csvContent])); // Crea l'URL
    const link = document.createElement('a');
    const fileName = `Iniziative_${dayjs(new Date()).format("DDMMYYHHmm")}.csv`;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};


    useEffect(() => {
        let iniziative = []
        props.iniziative.forEach(ini => {
            let iniziativa = {
                iniId: ini.iniId,
                iniEdizione: ini.iniEdizione,
                iniTitolo: ini.iniTitolo,
                iniStato: getStatusIniziativa(ini),
                iniDataInizio: ini.iniDataInizio,
                iniDataChiusura: ini.iniDataChiusura
            }
            iniziative.push(iniziativa)
        })

        setIniziative(iniziative)
    }, [props])

    return (
        <>
            <Row className="d-flex justify-content-between my-4">
                <Col xs={{span:12, order:2}} md={{span:3, order:1}}>
                    <Dropdown className="d-inline" autoClose="inside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside">
                            <span className="px-5">Filtra</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStato}>
                                {Object.values(statiIniziativa)
                                    .map((stato, index) => (
                                        <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                    )
                                    )}
                            </NavDropdown>
                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        props.statoFilter.length > 0 &&
                        <Form.Label className="mx-4 mt-2 mt-md-0 d-block">
                            Stato: <strong> {props.statoFilter}</strong>
                            <Badge bg="outline-danger">
                                <CloseButton onClick={resetStato} />
                            </Badge>
                        </Form.Label>
                    }
                </Col>

                <Col xs={{span:12, order:1}} md={{span:2,  order:3}} className="text-end">
                    <Button onClick={addIniziativa} className="mx-md-2 mb-2 mb-md-0">
                        Nuovo  <IoMdAdd style={{ fontSize: "1.3em" }} /></Button>
                </Col>
                <Col xs={{span:12, order:3}} md={{span:3, order:2}}>

                    <InputGroup className="mt-2 mt-md-0" style={{maxHeight:"1rem"}}>
                        <InputGroup.Text id="basic-addon1">
                            <GoSearch />
                        </InputGroup.Text>
                        <FormControl placeholder="Cerca titolo..."
                            onChange={(ev) => { setTitolo(ev.target.value) }}
                        />
                    </InputGroup>
                </Col>
                <Col className="text-end" xs={{span:12, order:4}} md={{span:3, order:4}}>  
                        <Button onClick={handleDownloadReports} variant="primary" >
                            <PiFileCsv className="me-1" />
                            Esporta tabella in formato csv
                        </Button>
                    </Col>
            </Row>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Edizione</th>
                        <th>Titolo</th>
                        <th className="text-center">Stato</th>
                        <th className="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getIniziative().length > 0 ? (
                            getIniziative()
                                .sort((a, b) => { return getStatusOrderIniziativa(b.iniEdizione) - getStatusOrderIniziativa(a.iniEdizione) })
                                .map(iniziativa => (
                                    <tr key={iniziativa.iniId}>
                                        <td>{iniziativa.iniEdizione}</td>
                                        <td>{iniziativa.iniTitolo}</td>
                                        <td className="text-center">
                                            <Badge pill bg={getIniziativaColor(iniziativa)} className="pillText" style={{ minWidth: "90%" }}>
                                                {iniziativa.iniStato}
                                            </Badge>
                                        </td>
                                        <td>
                                             <AzioniTabella
                                                iniId={iniziativa.iniId}
                                                setIniId={props.setIniId}
                                                stato={iniziativa.iniStato}
                                                setMostraTabella={props.setMostraTabella}
                                                setGestisci={props.setGestisci}
                                            /> 
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan={4}>
                                    Nessun elemento trovato
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    )

}