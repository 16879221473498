import { Badge, Card, Row, Col, Button, Spinner } from 'react-bootstrap'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import dayjs from "dayjs";
import { firmaPetizione } from "../../../utilities/icone";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import ModalPermessi from '../../Utilities/ModalPermessi';
import { statiIniziativa } from '../../Utilities/statiElementEnum';
import CartaFaseIniziativa from './CartaFaseIniziativa';

export default function InformazioniIniziativa(props) {
    const navigate = useNavigate();

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    const [disableButton, setDisableButton] = useState(false)

    useEffect(()=>{
        if (props.iniziativa.fasiIniziativa) {
            let faseCorrente = props.iniziativa.fasiIniziativa.filter((fase) =>
                dayjs().isBetween(fase.finiDataInizio, fase.finiDataFine, null, [])
            )[0];
            setDisableButton(faseCorrente? !faseCorrente.finiFlInvioProgetti : true );
        }
    }, [props])

    const canCreate = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlPubblicatoreProgetti) {
                //caso 3
                navigate('/crea-progetto', { state: { iniziativa: props.iniziativa.iniId } })
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per creare una proposta. L'operazione è bloccata!")
                setMode("danger")
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la creazione di una nuova proposta devi prima effettuare il login!")
            setMode("warning")
        }
        setMostraModalPermessi(true)
    }

    return (
        <>
            {props.loadingIni ?
                <div className="bg-light pt-3 align-items-center d-flex justify-content-center" style={{ minHeight: "45vh" }}>
                    <Spinner animation="border" role="status" />
                </div>
                :
                <div className="bg-light text-center pt-3"
                    style={{
                        backgroundImage: `url(data:image/png;base64,${props.iniziativa && props.iniziativa.iniBanner})`,
                        minHeight: "45vh",
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}
                />
            }

            <div className='mt-4'>
                <h5 className='d-inline me-2'><Badge bg="primary">{props.iniziativa.iniEdizione}</Badge></h5>
                <h5 className='d-inline me-2'><Badge bg="primary">{props.iniziativa.iniStato}</Badge></h5>
                <h5 className='d-inline'><Badge bg="primary">{props.iniziativa.progetti && props.iniziativa.progetti.length} progetti</Badge></h5>
            </div>

            <Row className='mb-5'>
                <Col className='mt-4 mb-4' xs={12} md={8}>
                    <Card>
                        <Card.Header className="mediumText"><h2 className="title2 text-start mb-3">{props.iniziativa.iniTitolo}</h2></Card.Header>
                        <Card.Body>
                            {props.iniziativa.iniDescrizione && props.iniziativa.iniDescrizione.split("\n").map((val, index) => (
                                <span key={index}>{val} <br /></span>
                            ))}
                        </Card.Body>
                    </Card>

                    {props.iniziativa.iniStato === statiIniziativa.InCorso &&
                        <Card border="primary" className='text-center mt-4 p-3 '>
                            <h4 className='title2 mt-0'>Cosa aspetti?</h4>
                            <Button className="w-50 mx-auto py-2"
                                disabled={disableButton}
                                onClick={canCreate}>
                                Invia il tuo progetto!
                                <span className="m-2">{firmaPetizione}</span>
                            </Button>
                        </Card>
                    }


                </Col>
                <Col>
                    {props.iniziativa.fasiIniziativa &&
                        props.iniziativa.fasiIniziativa
                            .sort((a, b) => a.finiOrdine - b.finiOrdine)
                            .map((fase, index) => (
                                <div key={index}>
                                    <CartaFaseIniziativa fase={fase} key={index} />
                                    {index !== props.iniziativa.fasiIniziativa.length - 1 &&
                                        <HiOutlineDotsVertical key={fase.iniOrdine} className='text-center w-100 text-muted mt-3' size="2em" />
                                    }
                                </div>
                            ))}
                </Col>
            </Row >
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </>
    )
}