import axios from 'axios'

const FIRME_REFERENDUM_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'firme-referendum'

const headers = { 'Content-Type': 'application/json' }

class FirmeReferendumService {
    registraFirma(firma) {
        return axios.post(FIRME_REFERENDUM_REST_API_URL + "/save", firma, headers);
    }

    revocaFirma(firma) {
        return axios.post(FIRME_REFERENDUM_REST_API_URL + "/delete", firma, headers)
    }
}

const firmeReferendumServiceInstance =new FirmeReferendumService()
export default firmeReferendumServiceInstance;