import axios from 'axios'

const IMPOSTAZIONI_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'impostazioni'

const headers = { 'Content-Type': 'application/json' }

class ImpostazioniComuneService {
    aggiornaImpostazioni(imp) {
        return axios.post(IMPOSTAZIONI_REST_API_URL + "/save", imp, headers);
    }
}

const impostazioniComuneServiceInstance = new ImpostazioniComuneService()
export default impostazioniComuneServiceInstance;